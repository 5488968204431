import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Select, Tooltip } from 'antd';
import React, { Component } from 'react';
import { TwitterPicker } from 'react-color';
import reactCSS from 'reactcss';
import Agent from '../../../agent/Agent';
import Helper from '../../../Helper';


class TooltipModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: [],
            fontSize: 8,
            displayColorPicker1: false,
            color1: {
                r: 241,
                g: 112,
                b: 19,
                a: 1,
            },
            color2: {
                r: 255,
                g: 255,
                b: 255,
                a: 1,
            },
            chapterBackground: {
                r: 255,
                g: 255,
                b: 255,
                a: 1,
            },
            font: "mangal",
            displayColorPicker2: false,
            fontSize: 40,
            chapterId: this.props.chapterId,
            chapterList: [],
            bold: false,
            italic: false,
            underline: false,

            strikeThrough: false,
            visible: true,
            loading: true,
            formExists: !!this.props.formRef,
            chapterName: ''
        }
        this.formRef = this.props.formRef || React.createRef()
    }
    handleClick1 = () => {
        this.setState({ displayColorPicker1: !this.state.displayColorPicker1 })
    };

    handleClose1 = () => {
        this.setState({ displayColorPicker1: false })
    };

    handleChange1 = (color) => {
        this.formRef.current.setFieldsValue({
            bgColor: color.rgb,
        });
        this.setState({ color1: color.rgb })
    };
    handleClick2 = () => {
        this.setState({ displayColorPicker2: !this.state.displayColorPicker2 })
    };

    handleClose2 = () => {
        this.setState({ displayColorPicker2: false })
    };

    handleChange2 = (color) => {
        this.formRef.current.setFieldsValue({
            color: color.rgb,
        });
        this.setState({ color2: color.rgb })
    };
    handleChange = (value) => {
        this.formRef.current.setFieldsValue({

            fontSize: value,

        })
        this.setState({ fontSize: value })

    }
    handleFontChange = (value) => {
        this.setState({ font: value })
    };

    componentDidUpdate(nextProps, prevProps) {
        // Rendering the component only if 
        // passed props value is changed
        if (nextProps.data.fontSize != prevProps.fontSize) {
            this.setState({
                color1: nextProps.data.backgroundColor,
                color2: nextProps.data.color,
                fontSize: nextProps.data.fontSize,
                id: nextProps.data.id,
                strikeThrough: (parseInt(nextProps.data.strikeThrough)),
                bold: parseInt(nextProps.data.bold),
                italic: parseInt(nextProps.data.italic),
                underline: parseInt(nextProps.data.underline),
                font: (nextProps.data.font) ? nextProps.data.font : 'mangal',
                loading: false
            })
        }
    }
    componentDidMount() {
        let { data } = this.props
        if (data && this.formRef.current) {
            console.log(data)
            this.formRef.current.setFieldsValue({
                font: (data.font) ? data.font : 'mangal',
                fontSize: data.fontSize,

            })
            this.setState({
                color1: data.backgroundColor,
                color2: data.color,
                fontSize: data.fontSize,
                id: data.id,
                strikeThrough: (parseInt(data.strikeThrough)),
                bold: parseInt(data.bold),
                italic: parseInt(data.italic),
                underline: parseInt(data.underline),
                font: (data.font) ? data.font : 'mangal',
                loading: false
            })
        }
        // Agent.ChapterRoutes.getChapter(this.state.chapterId).then((response) => {
        //     if (response.data.status == true)
        //         this.setState({ chapterList: response.data.data })
        //     else
        //         alert("something went wrong")
        // }).catch((err) => {
        // })
        // this.getToolTip();
    }
    // getToolTip = () => {
    //     this.setState({ loading: true })
    //     Agent.StyleRoute.getTooltipStyle({ chapter_id: this.props.chapterId }).then((res) => {
    //         console.log(res)
    //         if (res.data.status) {
    //             this.formRef.current.setFieldsValue({
    //                 font: (res.data.data.font) ? res.data.data.font : 'mangal',
    //                 fontSize: res.data.data.fontSize,

    //             })
    //             this.setState({
    //                 color1: res.data.data.backgroundColor,
    //                 color2: res.data.data.color,
    //                 fontSize: res.data.data.fontSize,
    //                 id: res.data.data.id,
    //                 strikeThrough: (res.data.data.strikeThrough),
    //                 bold: (res.data.data.bold),
    //                 italic: (res.data.data.italic),
    //                 underline: (res.data.data.underline),
    //                 font: (res.data.data.font) ? res.data.data.font : 'mangal',
    //                 loading: false
    //             })
    //         }
    //     }).catch((err) => {
    //         console.log(err, 'errrrrr')
    //         this.setState({ loading: false })
    //         console.log(err)
    //     })
    // }
    handleSubmit = (data) => {
        var submitValue = {
            id: this.state.id,
            background_color: this.state.color1,
            font_color: this.state.color2,
            font_size: this.state.fontSize,
            font: this.state.font,
            chapter_id: this.props.chapterId,
            bold: (this.state.bold) ? 1 : 0,
            underline: (this.state.underline) ? 1 : 0,
            italic: (this.state.italic) ? 1 : 0,
            strikeThrough: (this.state.strikeThrough) ? 1 : 0,
        }
        Agent.StyleRoute.updateTooltipStyle(submitValue).then((res) => {
            if (res.data.status == true) {
                Helper.toastAlert("Update Successfull", "success")
                this.props.showHideToolTip(false, null)
            }
        }).catch((err) => {
            Helper.toastAlert("Something went wrong", "error")
            console.log(err)
        })
    }

    setStyle = (type) => {

        this.setState({
            [type]: !this.state[type]
        })
    }
    hide = () => this.setState({ visible: false })
    componentWillUnmount() {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }
    getStyleObject = () => {
        var submitValue = {
            background_color: JSON.stringify(this.state.color1),
            font_color: JSON.stringify(this.state.color2),
            font_size: this.state.fontSize,
            font: this.state.font,
            bold: (this.state.bold) ? 1 : 0,
            underline: (this.state.underline) ? 1 : 0,
            italic: (this.state.italic) ? 1 : 0,
            strikeThrough: (this.state.strikeThrough) ? 1 : 0,
        }
        return submitValue
    }

    renderFormElements() {
        const styles = reactCSS({
            'default': {
                color1: {
                    width: '100%',
                    height: '20px',
                    borderRadius: '2px',
                    background: (this.state.color1) ? `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})` : "#FFF",
                },
                color2: {
                    width: '100%',
                    height: '20px',
                    borderRadius: '2px',
                    background: (this.state.color2) ? `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a})` : "#000",
                },
                swatch: {
                    padding: '5px',
                    width: '100%',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '9999',
                },
                cover: {

                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        var background = {
            'backgroundColor': `rgba(${this.state.chapterBackground.r}, ${this.state.chapterBackground.g}, ${this.state.chapterBackground.b}, ${this.state.chapterBackground.a})`,
        }

        return <>
            {/* <Row  gutter={16}>
                 <Col flex="end" span={4}><label>Background Color</label></Col>
                <Col span={2}>
                    <div style={styles.swatch} onClick={this.handleClick1}>
                        <div style={styles.color1} />
                    </div>
                    {this.state.displayColorPicker1 ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose1} />
                        <TwitterPicker
                            color={this.state.color1}
                            onChange={this.handleChange1} />
                    </div> : null}
                </Col>
            </Row> */}

            <Form.Item wrapperCol={{ sm: { span: 2 }, md: { span: 2 } }} label="Background Color" >
                <div style={styles.swatch} onClick={this.handleClick1}>
                    <div style={styles.color1} />
                </div>
                {this.state.displayColorPicker1 ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose1} />
                    <TwitterPicker
                        color={this.state.color1}
                        onChange={this.handleChange1} />
                </div> : null}
            </Form.Item>
            <Form.Item wrapperCol={{ sm: { span: 2 }, md: { span: 2 } }} label="Text Color" >
                <div style={styles.swatch} onClick={this.handleClick2}>
                    <div style={styles.color2} />
                </div>
                {this.state.displayColorPicker2 ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose2} />
                    <TwitterPicker
                        color={this.state.color2}
                        onChange={this.handleChange2} />
                </div> : null}
            </Form.Item>

            <Form.Item name={'fontSize'} label="Font size" >
                <InputNumber defaultValue={this.state.fontSize} onChange={this.handleChange} min={10} max={80} />
            </Form.Item>
            <Form.Item name={this.state.font} label="Font" >
                <Select value={this.state.font} defaultValue={this.state.font} onChange={this.handleFontChange} placeholder="Select font">
                    <Select.Option value="guru">Guru</Select.Option>
                    <Select.Option value="mangal">Mangal</Select.Option>
                    <Select.Option value="sakal_bharti">Sakal Bharti</Select.Option>

                </Select>
            </Form.Item>
            <Form.Item name={['fontStyle']} label="Font Style" >
                <Button type={(this.state.bold) ? 'primary' : ''} onClick={() => this.setStyle('bold')} icon={<BoldOutlined />} />
                <Button type={(this.state.italic) ? 'primary' : ''} onClick={() => this.setStyle('italic')} icon={<ItalicOutlined />} />
                <Button type={(this.state.underline) ? 'primary' : ''} onClick={() => this.setStyle('underline')} icon={<UnderlineOutlined />} />
                <Button type={(this.state.strikeThrough) ? 'primary' : ''} onClick={() => this.setStyle('strikeThrough')} icon={<StrikethroughOutlined />} />
            </Form.Item>
        </>
    }

    render() {
        const fontSize = this.formRef?.current?.getFieldValue('fontSize') || 40

        let textDecoration = 'none'
        if (this.state.underline && this.state.strikeThrough) {
            textDecoration = `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a}) underline line-through`
        } else if (this.state.underline && !this.state.strikeThrough) {
            textDecoration = `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a}) underline`
        } else if (!this.state.underline && this.state.strikeThrough) {
            textDecoration = `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a}) line-through`
        }

        var previewStyle = {
            'color': `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a})`,
            'backgroundColor': `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})`,
            'borderColor': `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a}) transparent transparent transparent`,
            'fontSize': `${fontSize}px`,
            fontFamily: this.state.font,
            'fontWeight': (this.state.bold) ? 'bold' : 'normal',
            'fontStyle': (this.state.italic) ? 'italic' : 'normal',
            'textDecoration': textDecoration,
        }

        return (
            <>
                {
                    this.state.formExists === true ?
                        <>{this.renderFormElements()}</>
                        :
                        <Form
                            ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal" name="tooltip" onFinish={this.handleSubmit} >

                            {this.renderFormElements()}
                        </Form>
                }

                <Row justify="center">
                    <Col >
                        <Tooltip visible={true} overlayInnerStyle={previewStyle} color={previewStyle.backgroundColor} title="prompt text" >
                            <Button>Test</Button>
                        </Tooltip>
                    </Col>
                </Row>
            </>

        )

    }
}
export default TooltipModule;