import { Modal } from 'antd';
import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
export default function PreviewModel({ showModel, onClose, type, path }) {
  let renderItem = null;
  if (type === 'video') {
    renderItem = (
      <Player>
        <source src={path} />
      </Player>
    );
  }
  if (type === 'audio') {
    renderItem = (
      <audio controls>
        <source src={path} type="audio/mp3" />
      </audio>
    );
  }
  return (
    <Modal
      title="Preview"
      destroyOnClose
      onCancel={onClose}
      visible={showModel}
      footer={null}
    >
      {renderItem}
    </Modal>
  );
}
