import { SET_IMPORT_LOADING } from './import.action';

const initialState = {
  importLoading: false,
};
function importReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IMPORT_LOADING:
      state = {
        ...state,
        importLoading: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
}
export default importReducer;
