import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Input, Select, Spin } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CATEGORY } from '../../../constants';
import BreadcrumbBar from '../../../includes/BreadCrumb/BreadcrumbBar';
import SubjectInput from './SubjectInput';
const DATE_FORMAT = 'DD/MM/YYYY';
const editableField = [
  'first_name',
  'last_name',
  'subject_ids',
  'subject',
  'session_start',
  'session_end',
  'email',
  'dob',
  'middle_name',
  'category',
  'parents_name',
  'mobile',
  'address',
  'city',
  'state',
  'zip_code',
  'class_id',
  'board_id',
  'course_id',

  'subjects',
];
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 14,
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
export default function AddStudent({
  subjectDropDownLoading,
  handleFetchSubject,
  addStudent,
  editStudent,
  dropDownData,
  dropDownLoading,
  fetchDropDownData,
  history,
  fetchStudentData,
}) {
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [form] = Form.useForm();

  const param = useParams();

  const fetchEditData = async () => {
    setLoading(true);
    const studentData = await fetchStudentData(param?.id);
    if (studentData) {
      setStudentData(studentData);
      handleFetchSubject({
        class_id: studentData.class_id,
        language_id: studentData.language_id,
      });
      const data = [];
      Object.keys(studentData).forEach((key) => {
        if (editableField.includes(key)) {
          if (key === 'dob') {
            data.push({ name: key, value: moment(studentData[key]) });
          } else if (key === 'subjects') {
            const updatedData = studentData.subjects?.map((o) => ({
              ...o,
              IVL: Number(o.IVL),
            }));
            data.push({
              name: 'subjects',
              touched: true,
              value: updatedData,
            });
          } else if (key.includes('session')) {
            data.push({
              name: key,
              touched: true,
              value: moment(studentData[key]),
            });
          } else {
            data.push({
              name: key,
              touched: true,
              value: [
                'state',
                'class_id',
                'board_id',
                'course_id',
                'language_id',
              ].includes(key)
                ? Number(studentData[key])
                : studentData[key],
            });
          }
        }
      });

      form.setFields(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchDropDownData();
    if (param?.id) {
      fetchEditData();
    }
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        values['dob'] = dayjs(values.dob).format('YYYY-MM-DD');
        values['session_start'] = dayjs(values.session_start).format(
          'YYYY-MM-DD'
        );
        values['session_end'] = dayjs(values.session_end).format('YYYY-MM-DD');
        values['subject_ids'] = values?.subject_ids?.join(',') || '';
        values['IVL'] = values?.IVL?.join(',') || '';
        delete values.session;
        setLoading(true);
        let res;
        if (studentData) {
          res = await editStudent(studentData.id, values);
          if (res?.data?.status) {
            form.resetFields();
          }

          history.push('/student');
        } else {
          res = await addStudent(values);
        }
        setLoading(false);
        if (res?.data?.status) {
          history.push('/student');
          form.resetFields();
        } else if (res?.data?.errors) {
          const errors = res?.data?.errors;
          let validationError = [];
          Object.keys(errors).map((key) => {
            validationError.push({
              name: key,
              value: values[key],
              errors: errors[key],
            });
          });

          form.setFields(validationError);
          form.scrollToField(validationError[0]?.name || '');
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClassSelect = (val) => {
    if ((val && form.getFieldValue('subjects')?.length) || 0 > 0) {
      confirm({
        title: `If change class it will remove all your subjects?`,
        icon: <ExclamationCircleFilled />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          form.setFieldValue('class_id', val);
          form.setFieldValue('subjects', []);
          handleFetchSubject({ class_id: val });
        },
      });
    } else {
      form.setFieldValue('class_id', val);
      handleFetchSubject({ class_id: val });
    }
  };
  const prefixSelector = (
    <Form.Item name="prefix" initialValue={'91'} noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue="91"
        disabled
        value={'91'}
      >
        <Select.Option value="91">+91</Select.Option>
      </Select>
    </Form.Item>
  );
  const classData = Form.useWatch('class_id', form);
  return (
    <div>
      <BreadcrumbBar
        pageTitle={[
          { name: 'Students', link: '/student' },
          { name: param?.id ? 'Edit Student' : 'Add Student' },
        ]}
      />
      <Spin spinning={dropDownLoading || loading}>
        <div className="from-wrapper">
          <Form
            form={form}
            {...formItemLayout}
            layout="horizontal"
            size="large"
            name="createStudent"
            initialValues={{
              modifier: 'public',
            }}
          >
            <Divider orientation="left">Personal Info</Divider>
            <Form.Item
              name="first_name"
              label="First Name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please enter first name',
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item name="middle_name" label="Middle Name">
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              name="last_name"
              hasFeedback
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter last name',
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              name="parents_name"
              hasFeedback
              label="Parent's Name"
              rules={[
                {
                  required: true,
                  message: `Please enter Parent's name`,
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              name="dob"
              hasFeedback
              //
              label="DOB"
              help="Choose Date of Birth"
              rules={[
                {
                  required: true,
                  message: `Please choose DOB`,
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} disabled={loading} />
            </Form.Item>
            <Form.Item
              name="mobile"
              label="Phone Number"
              help=""
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number!',
                },
                {
                  pattern: /^\d{10}$/,
                  message: 'Please enter valid number!',
                },
              ]}
            >
              <Input
                type="numeric"
                addonBefore={prefixSelector}
                maxLength={10}
                disabled={loading || !!studentData}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please enter email',
                },
                {
                  type: 'email',
                  message: 'enter valid email address',
                },
              ]}
            >
              <Input disabled={loading || !!studentData} />
            </Form.Item>
            {!studentData && (
              <>
                <Form.Item
                  name="password"
                  hasFeedback
                  label="Password"
                  help="Password must be greater than 8 char."
                  rules={[
                    {
                      required: true,
                      message: 'Please enter confirm password',
                    },
                    {
                      min: 8,
                      message: 'Minimum 8 char required',
                    },
                    {
                      max: 80,
                      message: 'Minimum 80 char required',
                    },
                  ]}
                >
                  <Input disabled={loading} maxLength={80} type="password" />
                </Form.Item>
                <Form.Item
                  dependencies={['password']}
                  hasFeedback
                  name="confirm_password"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter confirm password',
                    },
                    {
                      min: 8,
                      message: 'Minimum 8 char required',
                    },
                    {
                      max: 80,
                      message: 'Minimum 80 char required',
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The two passwords that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input maxLength={80} disabled={loading} type="password" />
                </Form.Item>
              </>
            )}
            <Divider orientation="left">Academic Info</Divider>

            <Form.Item
              name="category"
              hasFeedback
              label="Select Category"
              rules={[
                {
                  required: true,
                  message: 'Please select Category',
                },
              ]}
            >
              <Select
                disabled={loading}
                showSearch
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  (optionA?.children ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.children ?? '').toLowerCase())
                }
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                placeholder="Select"
                loading={dropDownLoading}
              >
                {CATEGORY.map((o) => (
                  <Select.Option key={o.value} value={o.value}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="board_id"
              hasFeedback
              label="Select Board"
              rules={[
                {
                  required: true,
                  message: 'Please select board',
                },
              ]}
            >
              <Select
                disabled={loading}
                showSearch
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  (optionA?.children ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.children ?? '').toLowerCase())
                }
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                placeholder="Select"
                loading={dropDownLoading}
              >
                {(dropDownData?.board || []).map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="course_id"
              hasFeedback
              label="Select Course"
              rules={[
                {
                  required: true,
                  message: 'Please select course',
                },
              ]}
            >
              <Select
                disabled={loading}
                placeholder="Select"
                showSearch
                loading={dropDownLoading}
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  (optionA?.children ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.children ?? '').toLowerCase())
                }
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {(dropDownData?.course || []).map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="class_id"
              hasFeedback
              label="Select Class"
              rules={[
                {
                  required: true,
                  message: 'Please select class',
                },
              ]}
            >
              <Select
                disabled={loading}
                onChange={handleClassSelect}
                optionFilterProp="children"
                showSearch
                filterSort={(optionA, optionB) =>
                  (optionA?.children ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.children ?? '').toLowerCase())
                }
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                placeholder="Select"
                loading={dropDownLoading}
              >
                {(dropDownData?.class || []).map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.List
              name="subjects"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('Please add subject'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? 'Subjects' : ''}
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      key={index + field.key}
                    >
                      <SubjectInput
                        dropDownData={dropDownData}
                        dropDownLoading={dropDownLoading}
                        field={field}
                        form={form}
                        fields={fields}
                        loading={loading}
                        remove={remove}
                        subjectDropDownLoading={subjectDropDownLoading}
                      />
                    </Form.Item>
                  ))}

                  <Form.Item {...formItemLayoutWithOutLabel}>
                    {fields.length < 10 && (
                      <Button
                        disabled={!classData}
                        type="dashed"
                        onClick={() => add()}
                        block
                        style={{ width: '50%' }}
                        icon={<PlusOutlined />}
                      >
                        Add Subjects
                      </Button>
                    )}
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              name="session_start"
              hasFeedback
              label="Session Start"
              rules={[
                {
                  required: true,
                  message: 'Please select Dates!',
                },
              ]}
            >
              <DatePicker
                disabled={loading}
                format={DATE_FORMAT}
                size="middle"
              />
            </Form.Item>
            <Form.Item
              name="session_end"
              hasFeedback
              label="Session End"
              rules={[
                {
                  required: true,
                  message: 'Please select Dates!',
                },
              ]}
            >
              <DatePicker
                disabled={loading}
                format={DATE_FORMAT}
                size="middle"
              />
            </Form.Item>
            <Divider orientation="left">Address Info</Divider>
            <Form.Item
              name="address"
              hasFeedback
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Please enter address',
                },
              ]}
            >
              <Input disabled={loading} type="textarea" />
            </Form.Item>

            <Form.Item
              name="city"
              hasFeedback
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please enter address',
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              name="zip_code"
              label="Zip Code"
              hasFeedback
              help="Enter 6 digit number"
              rules={[
                {
                  required: true,
                  message: 'Please enter zip code',
                },
                {
                  pattern: /^\d{6}$/,
                  message: 'Please enter valid zip code!',
                },
              ]}
            >
              <Input maxLength={6} disabled={loading} />
            </Form.Item>
            <Form.Item
              name="state"
              label="Select State"
              hasFeedback
              loading={dropDownLoading}
              rules={[
                {
                  required: true,
                  message: 'Please enter state',
                },
              ]}
            >
              <Select
                disabled={loading}
                placeholder="Select"
                optionFilterProp="children"
                showSearch
                filterSort={(optionA, optionB) =>
                  (optionA?.children ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.children ?? '').toLowerCase())
                }
                filterOption={(input, option) => {
                  return (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {(dropDownData?.board || []).map((o) => (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="country"
              initialValue={'India'}
              label="Select Country"
              loading={dropDownLoading}
              rules={[
                {
                  required: true,
                  message: 'Please enter country',
                },
              ]}
            >
              <Select disabled placeholder="Select" defaultValue={'India'}>
                <Select.Option value={'India'}>India</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4 }}>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleOk}
              >
                {param?.id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
