import { BuildOutlined, DeleteOutlined, EditOutlined, EyeOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Form, Modal, PageHeader, Popconfirm, Select, Switch, Table } from 'antd';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';
import Actions from '../../../redux/actions';
import BackgroundStyle from './BackgroundStyle';
import TooltipModule from './TooltipModule';


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  fetchData: (data) => dispatch => dispatch(Actions.chapterAction.list(data)),
  fetchClass: (data) => dispatch => dispatch(Actions.chapterAction.activeClass(data)),
  fetchSubject: (data) => dispatch => dispatch(Actions.chapterAction.activeSubject(data)),
  fetchActiveCourse: (data) => dispatch => dispatch(Actions.courseAction.activeCourse(data)),
  fetchActiveBoard: (data) => dispatch => dispatch(Actions.boardAction.activeBoard(data)),
  //    fetchActivePrintLanguage: (data) => dispatch => dispatch(Actions.chapterAction.activePrintLanguage()),
  //    fetchActiveVersions: (data) => dispatch => dispatch(Actions.chapterLoadAction.activeLanguage()),    
  //    fetchActiveBoard: (data) => dispatch => dispatch(Actions.chapterAction.activeBoard(data)),
  fetchActivePrintLanguage: (data) => dispatch => dispatch(Actions.chapterAction.activePrintLanguage(data)),
  fetchActiveVersions: (data) => dispatch => dispatch(Actions.chapterAction.activeVersions(data)),
}


class Chapter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chapter: [],
      showTooltip: false,
      showBackground: false,
      currentChapterId: null,

      courseList: [],
      course_id: '',

      boardList: [],
      board_id: '',

      printLanguageList: [],
      print_language_id: '',

      versionCodesList: [],
      version_codes: '',

      classList: [],
      class_id: '',

      subjectList: [],
      subject_id: '',
      filterFetch: false,
    }

    this.formRef = React.createRef()

  }

  componentDidMount() {

    const params = {
      course_id: this.state.course_id,
      board_id: this.state.board_id,
      print_language_id: this.state.print_language_id,
      version_codes: this.state.version_codes,
      class_id: this.state.class_id,
      subject_id: this.state.subject_id,
      board_id: this.state.board_id,

    };


    this.setState({ filterFetch: true })
    this.props.fetchActiveCourse(params).then((res) => {
      if (res.data.status) {
        const sortedCourseList = (res.data.data || []).map(item => { return { value: item.course.id, label: item.course.name } }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        })
        params.course_id = sortedCourseList[0]?.value || ''
        this.setState({
          courseList: sortedCourseList,
          course_id: sortedCourseList[0]?.value || ''
        })
      } else {
        Helper.toastAlert("Something went wrong while fetching courses", 'error')
      }
    }).catch((error) => {
      console.log(error)
      Helper.toastAlert("Something went wrong while fetching courses", 'error')
    })

    this.props.fetchActiveBoard(params).then((res) => {
      if (res.data.status) {
        const sortedBoardList = (res.data.data || []).map(item => { return { value: item.board.id, label: item.board.name } }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        })
        params.board_id = sortedBoardList[0]?.value || ''
        this.setState({
          boardList: sortedBoardList,
          board_id: sortedBoardList[0]?.value || ''
        })
      } else {
        Helper.toastAlert("Something went wrong while fetching Board", 'error')
      }
    }).catch((error) => {
      console.log(error)
      Helper.toastAlert("Something went wrong while fetching Board", 'error')
    })

    this.props.fetchActivePrintLanguage(params).then((res) => {
      if (res.data.status) {
        const sortedPrintLanguage = (res.data.data || []).map(item => { return { value: item.id, label: item.name } }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        })
        params.print_language_id = sortedPrintLanguage[0]?.value || ''
        this.setState({
          printLanguageList: sortedPrintLanguage,
          print_language_id: sortedPrintLanguage[0]?.value || ''
        })
      } else {
        Helper.toastAlert("Something went wrong while fetching print language", 'error')
      }
    }).catch((error) => {
      console.log(error)
      Helper.toastAlert("Something went wrong while fetching print language", 'error')
    })

    this.props.fetchActiveVersions(params).then((res) => {
      if (res.data.status) {
        const sortedVersionList = (res.data.data || []).map(item => { return { value: item.id, label: item.name } }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        })
        params.version_codes = sortedVersionList[0]?.value || ''
        this.setState({
          versionCodesList: sortedVersionList,
          version_codes: sortedVersionList[0]?.value
        })
      } else {
        Helper.toastAlert("Something went wrong while fetching version", 'error')
      }
    }).catch((error) => {
      console.log(error)
      Helper.toastAlert("Something went wrong while fetching version", 'error')
    })

    this.props.fetchClass(params).then((res) => {
      if (res.data.status) {
        const sortedClassList = (res.data.data || []).map(item => { return { value: item.id, label: item.name } }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        })
        params.class_id = sortedClassList[0]?.value || ''
        this.setState({
          classList: sortedClassList,
          class_id: sortedClassList[0]?.value || ''
        })

      } else {

        Helper.toastAlert("Something went wrong while fetching classes", 'error')
      }
      this.setState({ filterFetch: false })
    }).catch((error) => {
      Helper.toastAlert("Something went wrong while fetching classes", 'error')
    })
    if (this.state.class_id) {
      this.props.fetchSubject({ class_id: this.state.class_id }).then((res) => {
        const sortedSubjectList = (res.data.data || []).map(item => { return { value: item.id, label: item.name } })
        params.subject_id = sortedSubjectList[0]?.value || ''
        this.setState({
          subjectList: sortedSubjectList,
          subject_id: sortedSubjectList[0]?.value || ''
        })
      }).catch((err) => {
        Helper.toastAlert("Something went wrong while fetching subjects~", "error")
      })
    }
    this.getChapter(params);
    console.log(params)
    this.formRef.current.setFieldsValue({
      print_language_id: params.print_language_id + '',
      version_codes: params.version_codes + '',
      class_id: params.class_id + '',
      subject_id: params.subject_id + '',
      course_id: params.course_id + '',
      board_id: params.board_id + '',

    });
  }


  showHideToolTip = (status, chapterId) => this.setState({ showTooltip: status, currentChapterId: chapterId })

  showHideBackground = (status, chapterId) => this.setState({ showBackground: status, currentChapterId: chapterId })

  getChapter = (state) => {
    this.setState({ isLoading: true })
    Agent.ChapterRoutes.getChapter(
      //this.props.fetchData(
      {
        course_id: state.course_id, board_id: state.board_id, print_language_id: state.print_language_id,
        version_codes: state.version_codes, class_id: state.class_id, subject_id: state.subject_id
      })
      .then((response) => {

        if (response.data.status === true) {
          let chapterData = _.map(response.data.data, (o, i) => {
            return {
              name: o.name,
              class: o.chapter_class.name,
              subject: o.chapter_subject.name,
              print_language: o.chapter_print_language ? o.chapter_print_language.name : '',
              key: i, ...o
            }
          })
          console.log(chapterData);
          this.setState({ chapter: chapterData, isLoading: false })
        } else {
          Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        }
      }).catch((err) => {
        Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        this.setState({ isLoading: false })
      })
  }

  handleCourseChange = (value, name) => {
    this.setState({
      course_id: value
    }, () => {
      this.props.fetchActiveBoard({ course_id: this.state.course_id }).then((res) => {
        if (res.data.status) {
          this.setState({
            boardList: (res.data.data || []).map(item => { return { value: item.board.id, label: item.board.name } })
          })
        } else {
          Helper.toastAlert("Something went wrong while fetching courses", 'error')
        }
      }).catch((error) => {
        Helper.toastAlert("Something went wrong while fetching courses", 'error')
      })
    })
  }

  handleBoardChange = (value, name) => {
    this.setState({
      board_id: value
    }, () => {
      this.props.fetchClass({ board_id: this.state.board_id }).then((res) => {
        if (res.data.status) {
          this.setState({
            classList: (res.data.data || []).map(item => { return { value: item.id, label: item.name } })
          })
        } else {
          Helper.toastAlert("Something went wrong while fetching classes", 'error')
        }
      }).catch((error) => {
        Helper.toastAlert("Something went wrong while fetching classes", 'error')
      })
    })
  }

  handleClassChange = (value, name) => {
    this.formRef.current.setFieldsValue({ subject_id: null })
    this.setState({
      class_id: value,
      subject_id: null,
      subjectList: []
    }, () => {

      this.props.fetchSubject({ class_id: this.state.class_id, language_id: this.state.language_id }).then((res) => {
        this.setState({
          subjectList: (res.data.data || []).map(item => { return { value: item.id, label: (item.code) ? item.code + '-' + item.name : item.name } })
        })
        this.handleChange(value, name)
      }).catch((err) => {
        Helper.toastAlert("Something went wrong while fetching subjects~", "error")
      })
    })
  }

  handleLanguageChange = (val, name) => {
    this.setState({ language_id: val })
    this.handleChange(val, name);
    if (this.state.class_id) {
      this.handleClassChange(this.state.class_id, 'class_id')
    }
  }

  handleDelete = (id, name) => {
    const listener = event => {
      if (event.code === "KeyY") {
        this.onDelete(id, name, listener)
        confirmAlert({ customUI: ({ onClose }) => onClose() });
      } else if (event.code === "KeyN") {
        confirmAlert({ customUI: ({ onClose }) => onClose() });
        document.removeEventListener("keydown", listener);
      }
    };

    document.addEventListener("keydown", listener);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onDelete(id, name, listener)
        },
        {
          label: 'No',
          onClick: () => { }
        },
      ]
    });

  }


  onDelete = (id, name) => {
    Agent.ChapterRoutes.deleteChapter({ id, name }).then((res) => {
      if (res.data) {
        this.getChapter(Object.assign({}, this.state));
        Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, 'success', () => {

        });

      } else {
        Helper.toastAlert("Error while deleting", 'error');
      }
    }).catch((err) => {
      Helper.toastAlert(Helper.handleError(err), 'error');

    })
  }
  handlePublish = (row) => {
    Agent.ChapterRoutes.updatePublishStatus({ id: row.id, is_published: row.is_published ? false : true }).then((res) => {
      if (res.data.status) {
        this.getChapter(Object.assign({}, this.state));
        Helper.toastAlert(res.data.message, 'success');
      } else {
        Helper.toastAlert(res.data.message, 'error');
      }
    }).catch((err) => {
      Helper.toastAlert(Helper.handleError(err), 'error');

    })
  }
  renderTable = () => {
    const columns = [
      { title: 'Sr.No.', width: 50, dataIndex: '', key: 'count', render: (text, row, index) => ++index, },
      { title: 'UUID', width: 50, dataIndex: '', key: 'uuid',dataIndex: 'uuid',  },
      { title: 'Class', width: 100, dataIndex: 'class', key: 'class', },
      { title: 'Subject', width: 100, dataIndex: 'subject', key: 'subject', },
      { title: 'Chapter Name', dataIndex: 'name', key: 'name' },
      { title: 'Chapter No', dataIndex: 'chapter_no', key: 'chapter_no' },
      { title: 'Unit No', dataIndex: 'unit_no', key: 'unit_no' },
      { title: 'Unit Name', dataIndex: 'unit_name', key: 'unit_name' },
      //{ title: 'Print Language', dataIndex: 'print_language', key: 'print_language' },
     
      {
        title: 'Preview', width: 100, dataIndex: '', key: 'preview', render: (text, row, index) => this.state.version_codes ?

          <NavLink to={"/chapter/" + row.id + '/' + this.state.version_codes + "/preview"}>
            <EyeOutlined />
          </NavLink>


          :
          <EyeOutlined className="text-light" />
      },
      {
        title: 'Lecture', width: 100, dataIndex: '', key: 'build', render: (text, row, index) => this.state.version_codes ?

          <NavLink to={"/chapter/" + row.id + '/' + this.state.version_codes + "/build"}>
            <BuildOutlined />
          </NavLink>


          :
          <EyeOutlined className="text-light" />
      },
      {
        title: 'Edit', width: 100, dataIndex: '', key: 'edit', render: (text, row, index) => this.state.version_codes ?
          <NavLink className="text-info" to={"/chapter/" + row.id + "/" + this.state.version_codes + "/load"} >
            <TableOutlined />
          </NavLink >
          :
          <TableOutlined className="text-light" />
      },
      {
        title: 'Build', width: 100, dataIndex: '', key: 'edit', render: (text, row, index) =>
          <NavLink className="text-info" to={"/chapter/" + row.id + "/edit"} >
            <EditOutlined />
          </NavLink >
      },
      {
        title: 'Trash',
        dataIndex: '',
        width: 100,
        key: 'x',
        render: (text, row, index) => {
          return (
            <Popconfirm
              title="Are you sure to delete this Chapter?"
              onConfirm={() => this.onDelete(row.id, row.name)}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
              placement="leftBottom"
            >
              <Button
                size="small"
                shape="circle"
                tabIndex="-1"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )

        },
      },
      {
        title: 'Publish',
        dataIndex: '',
        width: 100,
        key: 'x',
        render: (text, row, index) => <Switch size='small' checked={!!row.is_published} onChange={() => this.handlePublish(row)} />,
      },
    ];
    return <Table scroll={{ x: "100vh", }}
    className="build-table"
      loading={this.state.isLoading} pagination={false} columns={columns} dataSource={this.state.chapter} size="large" />
  }

  moveToChapterAdd = () => this.props.history.push('/chapter/add')


  handleChange = (val, name) => {
    let obj = {};
    obj[name] = val;
    obj = Object.assign({}, this.state, obj);
    this.setState(obj);
    this.getChapter(obj);
  }

  render() {
    return (
      <>
        <div className="row">
          <h1>Chapters</h1>
        </div>
        <PageHeader
          className="site-page-header"
          title=""
          subTitle={<Form ref={this.formRef} layout="inline">

            <Form.Item name={'print_language_id'} label="Language" rules={[{ required: true }]}>
              <Select placeholder="Select Language" onChange={(e) => this.handleLanguageChange(e, 'print_language_id')}>
                <Select.Option value={""}>Select Language</Select.Option>
                {this.state.printLanguageList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>
            <Form.Item name={'version_codes'} label="Version" rules={[{ required: true }]}>
              <Select placeholder="Select Version" onChange={(e) => this.handleLanguageChange(e, 'version_codes')}>
                <Select.Option value={""}>Select Version</Select.Option>
                {this.state.versionCodesList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>
            <Form.Item name={'course_id'} label="Course" rules={[{ required: false }]}>
              <Select placeholder="Select Course" onChange={(e) => this.handleLanguageChange(e, 'course_id')}>
                <Select.Option value={""}>Select Course</Select.Option>
                {this.state.courseList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>

            <Form.Item name={'board_id'} label="Board" rules={[{ required: false }]}>
              <Select placeholder="Select Board" onChange={(e) => this.handleChange(e, 'board_id')}>
                <Select.Option value={""}>Select Board</Select.Option>
                {this.state.boardList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>
            <Form.Item name={'class_id'} label="Class" rules={[{ required: false }]}>
              <Select placeholder="Select Class" onChange={(e) => this.handleClassChange(e, 'class_id')}>
                <Select.Option value={""}>Select Class</Select.Option>
                {this.state.classList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>
            <Form.Item name={'subject_id'} label="Subject" rules={[{ required: false }]}>
              <Select placeholder="Select Subject" onChange={(e) => this.handleLanguageChange(e, 'subject_id')}>
                <Select.Option value={""}>Select Subject</Select.Option>
                {this.state.subjectList.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>{val.label}</Select.Option>
                  )
                })
                }
              </Select>
            </Form.Item>
          </Form>}
          ghost={false}
          // onBack={() => window.history.back()}
          // subTitle="This is a subtitle"
          extra={[

            <Button onClick={this.moveToChapterAdd} key="1" type="primary">
              Create new Chapter
            </Button>

          ]}
        />


        {this.renderTable()}
        <Modal
          title={"Edit your Tooltip style"}
          footer={null}

          onCancel={() => this.showHideToolTip(false, null)} visible={this.state.showTooltip} >
          {this.state.showTooltip &&
            <TooltipModule
              showHideToolTip={this.showHideToolTip}
              chapterId={this.state.currentChapterId}
            />
          }
        </Modal>
        <Modal
          title={"Edit your Background"}
          footer={null}

          onCancel={() => this.showHideBackground(false, null)} visible={this.state.showBackground} >
          {this.state.showBackground &&
            <BackgroundStyle
              showHideBackground={this.showHideBackground}
              chapterId={this.state.currentChapterId}
            />
          }
        </Modal>
      </>


    )
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(Chapter)
