import { connect } from 'react-redux';

import Dictionary from './Dictionary';
import {
  fetchDropDownData,
  handleFetchDictionary,
  handleFormSave,
} from './dictionary.action';

const mapStateToProps = (state) => {
  return {
    data: state.dictionaryReducer.data,
    loading: state.dictionaryReducer.loading,
    dropDownLoading: state.dictionaryReducer.dropDownLoading,
    dropdownData: state.dictionaryReducer.dropdownData,
    saveLoading: state.dictionaryReducer.saveLoading,
  };
};
const mapDispatchToProps = {
  handleFetchDictionary: (param) => (dispatch) =>
    dispatch(handleFetchDictionary(param)),
  fetchDropDownData: () => (dispatch) => dispatch(fetchDropDownData()),
  handleFormSave: (data) => (dispatch) => dispatch(handleFormSave(data)),
};
export default connect(mapStateToProps, mapDispatchToProps)(Dictionary);
