import Agent from "../../agent/Agent";
const fetch = () => dispatch => {
  return Agent.StyleRoute.getStyle()
}
const getToolTip = (data) => dispatch => {
  return Agent.StyleRoute.getTooltipStyle(data)
}
const getTooltipStyle = (data) => dispatch => {
  return Agent.StyleRoute.getTooltipStyle(data)
}

const styleAction = {
  fetch,
  getToolTip, getTooltipStyle
}
export default styleAction