import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Space } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';

const SubjectInput = ({
  field,
  form,
  dropDownData,
  loading,
  dropDownLoading,
  fields,
  remove,
  handleFetchSubject,
  subjectDropDownLoading,
}) => {
  const [subjectData, setSubjectData] = useState([]);
  const getFilterSubject = (val) => {
    const data = dropDownData?.subjects?.filter((o) => {
      const languages = map(o.languages, 'id');
      return languages.includes(val);
    });
    return data;
  };
  useEffect(() => {
    const subjects = form.getFieldValue('subjects');
    const data = subjects[field.key];
    if (data) {
      const subjects = getFilterSubject(data.language_id);
      setSubjectData(subjects || []);
    }
  }, [subjectDropDownLoading, fields?.length]);
  const handleLanguageSelect = async (val) => {
    if (form.getFieldValue('class_id')) {
      const data = getFilterSubject(val);
      setSubjectData(data);
      const subjects = form.getFieldValue('subjects');
      const updatedSubject = subjects.map((o, k) => {
        if (k == field.key) {
          return { ...o, subject_id: undefined };
        }
        return o;
      });
      form.setFieldValue('subjects', updatedSubject);
    }
  };
  return (
    <Space align="baseline" size={'small'}>
      <Form.Item
        noStyle
        name={[field.name, 'language_id']}
        rules={[
          {
            required: true,
            message: 'Missing Language',
          },
        ]}
      >
        <Select
          style={{ width: 200 }}
          onChange={handleLanguageSelect}
          showSearch
          optionFilterProp="children"
          filterSort={(optionA, optionB) =>
            (optionA?.children ?? '')
              .toLowerCase()
              .localeCompare((optionB?.children ?? '').toLowerCase())
          }
          filterOption={(input, option) => {
            return (option?.children ?? '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          disabled={
            !form.getFieldValue('class_id') || loading || dropDownLoading
          }
          placeholder="Print Language"
        >
          {(dropDownData?.language || []).map((o) => (
            <Select.Option key={o.id} value={o.id}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        name={[field.name, 'subject_id']}
        rules={[
          {
            required: true,
            message: 'Missing Subject',
          },
        ]}
      >
        <Select
          style={{ width: 200 }}
          loading={subjectDropDownLoading}
          disabled={
            !form.getFieldValue('class_id') || subjectDropDownLoading || loading
          }
          placeholder="Subject"
          options={subjectData}
        />
      </Form.Item>

      <Form.Item
        noStyle
        name={[field.name, 'IVL']}
        rules={[
          {
            required: true,
            message: 'Missing IVL',
          },
        ]}
      >
        <Select
          style={{ width: 200 }}
          disabled={!form.getFieldValue('class_id')}
          placeholder="IVL"
        >
          {(dropDownData?.language || []).map((o) => (
            <Select.Option key={o.id} value={o.id}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {fields.length > 1 ? (
        <MinusCircleOutlined onClick={() => remove(field.name)} />
      ) : null}
    </Space>
  );
};

export default SubjectInput;
