import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';
const _ = require('lodash');
const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta,
  };
};
const mapDispatchToProps = {
  deleteWord: (data) => (dispatch) =>
    dispatch(Actions.chapterLoadAction.deleteWord(data)),
  addChapterLoadData: (data) => (dispatch) =>
    dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data }),
};
function TableAction(props) {
  let { deleteWord, metaId, row, chapterMeta, addChapterLoadData } = props;

  const confirmDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const handleDelete = () => {
    let path = '';
    if (row.media_id) {
      path = row.media.path;
    }
    deleteWord({ id: metaId, path })
      .then((res) => {
        if (res.data.status) {
          addChapterLoadData(_.filter(chapterMeta, (o) => o.id != metaId));

          //update Store
          // _.map(chapterMeta, (o, k) => {
          //   chapterMeta[k]['word_order'] = (o.id == metaId) ? res.data.data.word_order : selectValue
          // })
          // addChapterLoadData(chapterMeta)
        } else {
          // Set Default value when api fail

          Helper.toastAlert('Error while deleting', 'error');
        }
      })
      .catch((err) => {
        // handle Error
        Helper.toastAlert(Helper.handleError(err), 'error');
      });
  };

  return (
    <>
      <Popconfirm
        title="Are you sure to delete this word or media?"
        onConfirm={handleDelete}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
        placement="leftBottom"
      >
        <Button
          size="small"
          shape="circle"
          tabIndex="-1"
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(TableAction);
