import { Col } from 'antd';
import React from 'react';
import Agent from '../../../agent/Agent';

const { forwardRef, useRef, useState, useImperativeHandle } = React;



const RenderAudio = forwardRef(({ style, id, audioPath, onAudioEnded, hideAllToolTip, isHideToolTip, play, tooltipStyle, hindiWord, voiceClip }, ref) => {
  let time = null
  let audioRef = useRef(null);
  const [showToolTip, setState] = useState(false)
  const [showUnderLine, setUnderLine] = useState(false)
  useImperativeHandle(ref, () => ({
    showToolTip() {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        setState(true)
        setUnderLine(true)
      } else {
        setUnderLine(true)
      }
    },
    hideOnlyToolTip() {
      setState(false);
    },
    hideToolTip() {
      setState(false)
      setUnderLine(false)
    },
    hideUnderline() {

      setUnderLine(false);

    },
    play() {


      audioRef.play()
    }
  }));
  const toggleToolTipWord = () => {

    if (!play) {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        hideAllToolTip(id)
        setState(!showToolTip)
      } else {
        hideAllToolTip(id)
        setUnderLine(!showUnderLine)
      }
    }
  }
  let path = Agent.STORAGE_ROOT + audioPath

  return (
    <>
      <Col span={8}></Col>
      <Col span={8} >
        <div className="popup ">
          <audio onEnded={onAudioEnded} ref={(player => { audioRef = player })} controls controlsList="nodownload">

            <source src={path} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
          {/* <ReactAudioPlayer
            ref={(player => { audioRef = player })}
            src={path}
            controlsList="nodownload"
            props={{ onEnded: onAudioEnded }}
            controls
          /> */}
          {(showToolTip == false) ?
            <span style={tooltipStyle} className="popup-text hindi-text">{hindiWord}</span>
            :
            <span style={tooltipStyle} className="popup-text hindi-text show-tooltip ">{hindiWord}</span>

          }
        </div>
      </Col>
      <Col span={8}></Col>
    </>





  )
})
export default RenderAudio