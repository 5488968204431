import Agent from "../../agent/Agent";
const fetchByClass = (data) => dispatch => {
  return Agent.Subject.getClassSubject(data)
}
const fetch = (data) => dispatch => {
  return Agent.Subject.getSubject(data)
}
const add = (data) => dispatch => {
  return Agent.Subject.addSubject(data)
}
const update = (data) => dispatch => {
  return Agent.Subject.editSubject(data)
}
const deleteSubject = (data) => dispatch => {
  return Agent.Subject.deleteSubject(data)
}


const subjectAction = {
  fetchByClass, fetch, deleteSubject, add, update
}
export default subjectAction