import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Actions from '../../../redux/actions';
import Helper from '../../../Helper';
import { ActionConstant } from '../../../redux/actions/type';
import { InputNumber } from 'antd';
const _ = require('lodash')
const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  addWordOrder: (data) => dispatch => dispatch(Actions.chapterLoadAction.addWordOrder(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}
function WordOrderSave(props) {

  let { value, addWordOrder, metaId, chapterMeta, chapterId,disableWordOrder, addChapterLoadData, index } = props
  const [selectValue, setValue] = useState(value);
  const [isOnChange, setOnchangeIndicator] = useState(false);

  const onChangeHandler = (val) => {

    setOnchangeIndicator(true)
    setValue(val)
  }
  useEffect(() => {
    if (selectValue !== value) {
      setValue(value)
    }
  }, [value])
  const handleBlur = (e) => {


    if (isOnChange) {
      let data = {
        old_order: value,
        word_order: selectValue,
        chapter_id: chapterId,
        chapter_meta_id: metaId
      }
      disableWordOrder(true)
      addWordOrder(data).then((res) => {
        setOnchangeIndicator(false)

        if (res.data.status) {

          const metaValue = _.cloneDeep(chapterMeta)

          const newMeta = _.map(chapterMeta, (o, k) => {

            if (o.wordOrder == selectValue) {
              metaValue[k].wordOrder = value
              o.wordOrder == value
            }
            if (o.id === metaId) {
              metaValue[k].wordOrder = selectValue
              o.wordOrder = selectValue
            }
            return o
          })
          console.log(metaValue)
          addChapterLoadData([...metaValue])
        } else {
          // Set Default value when api fail
          Helper.toastAlert(res.data.msg, 'error');
          setValue(value)
        }
        disableWordOrder(false)
      }).catch((err) => {
        // handle Error
        setOnchangeIndicator(false)
        setValue(value)
        Helper.toastAlert(Helper.handleError(err), 'error');
        disableWordOrder(false)
      })
    }
  }
  const navigateBottom = () => {
    const nextId = `word-order-${index + 1}`
    document.getElementById(nextId) && document.getElementById(nextId).focus() &&
      document.getElementById(nextId).scrollIntoView({ behavior: "smooth", block: "center" });
  }
  return (
    <InputNumber min={1} id={`word-order-${index}`} onPressEnter={navigateBottom} onBlur={handleBlur} disabled={props.disabled} onChange={onChangeHandler} value={selectValue} />

  )


}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WordOrderSave);
