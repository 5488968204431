import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';

export default function SubjectFilter({
  form,
  handleBlur,
  classListOption,
  languageListOption,
}) {
  return (
    <Form
      form={form}
      layout="inline"
      style={{ display: 'block' }}
      size="large"
      name="createSubject"
      initialValues={{
        modifier: 'public',
      }}
    >
      <Row>
        <Col span={6}>
          <Form.Item name="name" label="Name">
            <Input
              name="name"
              onBlur={handleBlur}
              size="middle"
              placeholder="Name"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="class_id" label="Select Class">
            <Select
              showSearch
              onChange={handleBlur}
              optionFilterProp="children"
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select"
            >
              <Select.Option value={''}>Select</Select.Option>
              {(classListOption || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="language_id" label="Select Language">
            <Select
              showSearch
              onChange={handleBlur}
              optionFilterProp="children"
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select"
            >
              <Select.Option value={''}>Select</Select.Option>
              {(languageListOption || []).map((o) => (
                <Select.Option key={o.id} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
