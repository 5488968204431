import React, { Component } from 'react'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';
import Helper from '../../../Helper';
import { customValidator } from '../../../function/customValidation';
import { Select, Form, Input, Row, Col, Upload, Button } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
class AddNewFrom extends Component {
  constructor (props) {
    super(props)
    this.state = {
      type: 'word',
      word: '',
      file: {},
      link: '',
      linkName: '',
      buttonLoading: false
    }
    this.validator = customValidator()
  }


  handleFormSubmit = (e) => {

    e.preventDefault();
    if (this.validator.allValid()) {

      this.setState({ buttonLoading: true })
      var form_data = new FormData();
      form_data.append('chapter_meta_id', this.props.metaId);
      form_data.append('word_text', this.state.word);
      form_data.append('type', this.state.type);
      form_data.append('link', this.state.link);
      form_data.append('link_name', this.state.linkName);
      if (this.state.type !== 'link') {
        form_data.append(this.state.type, this.state.file);
      }

      this.props.addNewMedia(form_data).then((res) => {
        if (res.data.status) {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, 'success', () => {
            window.location.reload()
          });
        } else {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_0, 'error');
        }
        this.setState({ buttonLoading: false })
      }).catch((err) => {
        this.setState({ buttonLoading: false })
        Helper.toastAlert(Helper.handleError(err), 'error');
      })
    } else {
      this.setState({ buttonLoading: false })
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleChange = (value, name) => {
    this.setState({ [name]: value })

  }
  handleFileChange = (fileObj) => {
    console.log(fileObj.file.originFileObj, fileObj)
    this.setState({ file: fileObj.file.originFileObj })
  }
  handleLinkChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("Ok")

    }, 0)
  }
  render() {
    this.validator.purgeFields();

    return (
      <form onSubmit={this.handleFormSubmit}>
        <Row gutter={16}>
          <Col span={4}><label>Select Type</label></Col>
          <Col span={6}> <Select style={{ width: "100%" }} value={this.state.type} onChange={(value) => this.handleChange(value, 'type')} placeholder="select type">
            <Select.Option value="word" >Word</Select.Option>
            <Select.Option value="image" >Image</Select.Option>
            <Select.Option value="audio" >Audio</Select.Option>
            <Select.Option value="video" >Video</Select.Option>
            <Select.Option value="link" >HyperLink</Select.Option>
          </Select></Col>
        </Row>

        {(this.state.type == 'word') &&
          <Row gutter={16}>
            <Col span={4}><label>Word/Paragraph</label></Col>
            <Col span={6}><Input value={this.state.word} onChange={(e) => this.handleChange(e.target.value, 'word')} placeholder="Insert new word or paragraph" />
              <span className="text-danger">
                {this.validator.message('word', this.state.word, 'required')}</span>
            </Col>
          </Row>
        }
        {(this.state.type == 'image') &&
          <Row gutter={16}>
            <Col span={4}><label>Image</label></Col>
            <Col span={6}>
              <Upload maxCount={1} onChange={(file) => this.handleFileChange(file, 'imagefile')} openFileDialogOnClick={true} customRequest={this.customRequest} progress={false} name="imagefile">
                <Button icon={<UploadOutlined />}>Click to upload Image file</Button>
              </Upload>
              <span className="text-danger">
                {this.validator.message('Image', this.state.file, 'imageValidation')}</span>
            </Col>
          </Row>

        }
        {(this.state.type == 'video') &&
          <Row gutter={16}>
            <Col span={4}><label>Upload Video</label></Col>
            <Col span={6}>
              <Upload onChange={(file) => this.handleFileChange(file, 'videofile')} maxCount={1} openFileDialogOnClick={true} customRequest={this.customRequest} progress={false} name="videofile">
                <Button icon={<UploadOutlined />}>Click to upload Video file</Button>
              </Upload>
              <span className="text-danger">
                {this.validator.message('Video', this.state.file, 'videoValidation')}</span>
            </Col>
          </Row>

        }
        {
          (this.state.type == 'audio') &&
          <Row gutter={16}>
            <Col span={4}><label>Audio file</label></Col>
            <Col span={6}>
              <Upload onChange={(file) => this.handleFileChange(file, 'audiofile')} maxCount={1} openFileDialogOnClick={true} customRequest={this.customRequest} progress={false} name="audiofile">
                <Button icon={<UploadOutlined />}>Click to upload Audio file</Button>
              </Upload>
              <span className="text-danger">
                {this.validator.message('Audio', this.state.file, 'audioValidation')}</span>
            </Col>
          </Row>
        }
        {
          (this.state.type == 'link') &&
          <>
            <Row>
              <Col span={4}><label>Link name</label></Col>
              <Col span={12}>
                <Input value={this.state.linkName} onChange={(e) => this.handleChange(e.target.value, 'linkName')} placeholder="Link name" />
                <span className="text-danger">
                  {this.validator.message('Link name', this.state.link, 'required')}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}><label>Insert Link</label></Col>
              <Col span={12}>
                <Input value={this.state.link} onChange={(e) => this.handleChange(e.target.value, 'link')} placeholder="Insert link here" />
                <span className="text-danger">
                  {this.validator.message('Link', this.state.link, 'required|linkValidation')}</span>
              </Col>
            </Row>
          </>
        }
        <Row>
          <Col offset={4}>  <Button loading={this.state.buttonLoading} type="primary" htmlType="submit">
            Add
          </Button></Col>
        </Row>


      </form >
    )


  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  addNewMedia: (data) => dispatch => dispatch(Actions.chapterLoadAction.addNewMedia(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewFrom)
