import Agent from "../../agent/Agent";
const fetchByChapter = (data) => dispatch => {
  return Agent.Language.getChapterLanguage(data)
}
const fetch = (data) => dispatch => {
  return Agent.Language.getLanguage(data)
}
const add = (data) => dispatch => {
  return Agent.Language.addLanguage(data)
}
const update = (data) => dispatch => {
  return Agent.Language.editLanguage(data)
} 
const deleteLanguage = (data) => dispatch => {
  return Agent.Language.deleteLanguage(data)
}


const languageAction = {
  fetchByChapter, fetch, deleteLanguage, add, update
}
export default languageAction