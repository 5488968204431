import { connect } from 'react-redux';

import studentAction from './student.action';
import Students from './Students';

const mapStateToProps = (state, ownProps) => {
  return {
    studentList: state.studentReducer.studentList,
    loading: state.studentReducer.loading,
    dropDownLoading: state.studentReducer.dropDownLoading,
    dropDownData: state.studentReducer.dropDownData,
    subjectDropDownLoading: state.studentReducer.subjectDropDownLoading,
  };
};
const mapDispatchToProps = {
  updateStudent: (data) => (dispatch) => dispatch(studentAction.update(data)),
  addStudent: (data) => (dispatch) => dispatch(studentAction.add(data)),
  deleteStudent: (data) => (dispatch) =>
    dispatch(studentAction.deleteStudent(data)),
  editStudent: (id, data) => (dispatch) =>
    dispatch(studentAction.update(id, data)),
  updateStatus: (data) => (dispatch) =>
    dispatch(studentAction.updateStatus(data)),
  // deleteCourse: (data) => dispatch => dispatch(studentAction.deleteCourse(data)),
  fetchStudents: (data) => (dispatch) => dispatch(studentAction.fetch(data)),
  fetchDropDownData: () => (dispatch) =>
    dispatch(studentAction.fetchDropDownData()),
  handleFetchSubject: (param) => (dispatch) =>
    dispatch(studentAction.handleFetchSubject(param)),
};
export default connect(mapStateToProps, mapDispatchToProps)(Students);
