import { CheckOutlined, CloseOutlined, PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Button, PageHeader, Row, Space, Spin, Switch } from "antd";
import $ from "jquery";
import { delay } from 'lodash';
import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../Helper";
import Agent from "../../../agent/Agent";
import Actions from "../../../redux/actions";
import RenderAudio from "./RenderAudio";
import RenderImage from "./RenderImage";
import RenderLink from "./RenderLink";
import RenderVideo from "./RenderVideo";
import RenderWord from "./RenderWord";

var _ = require("lodash");
var interval = null;
const mapDispatchToProps = {
  fetch: () => (dispatch) => dispatch(Actions.backgroundAction.fetch()),
  add: (data) => (dispatch) => dispatch(Actions.backgroundAction.add(data)),
  getTooltipStyle: (data) => (dispatch) =>
    dispatch(Actions.styleAction.getTooltipStyle(data)),
  fetchData: (data, config) => (dispatch) =>
    dispatch(Actions.chapterLoadAction.fetchPreview(data, config)),
};
const mapStateToProps = (state) => ({});
// var audio = document.getElementById('audio')
class ChapterPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter_id: this.props.match.params.id,
      version_code: this.props.match.params.version,
      previewMode:this?.props?.previewMode || false,
      chapter_meta: [],
      name: "",
      // sort by word order
      sortedData: [],
      word_order: 1,
      play: false,
      tooltipStyle: {},
      chapter_background: {

        r: "255",
        g: "255",
        b: "255",
        a: "1",

      },
      selection: [],
      selectAll: false,
      voiceClips: [],
      currentData: "",
      loading: true,

      isHideToolTip: false,
      isMuteSound: false,
      clickedItem: {}
    };
    this.audio = null;
    this.video = null;
  }
  componentDidMount() {
    this.loadChapter();

  }
  loadChapter = () => {
    this.setState({ loading: true });
    this.props
      .fetchData({ chapter_id: this.props.match.params.id, version: this.props.match.params.version })
      .then((response) => {
        if (response.data.status) {
          let data = response.data.data.chapter_info.slice();
          const updatedData = data.map((o, k) => {
            let previousWord = ''
            if (k > 0, o.word) {

              previousWord = data[k - 1]?.word?.word || ''
            }
            return { ...o, previousWord }
          })
          data.sort((a, b) => {
            return parseInt(a.word_order) - parseInt(b.word_order);
          });

          let clip = _.first(data);
          document.getElementById(
            "dynamic-css"
          ).innerHTML = Helper.tootlTipStyleFormat(
            response.data.data.chapter.tooltip_style
          );
          this.setState({
            chapter_background: response.data.data.chapter.background,
            tooltipStyle: response.data.data.chapter.tooltip_style,
            name: response.data.data.chapter.name,
            loading: false,
            sortedData: data,
            chapter_meta: updatedData,
            currentData: clip,
          });
        } else {
          Helper.toastAlert("Error while fetching data", "error");
          this.setState({ loading: false });
        }
      })
      .catch((err) => {

        this.setState({ loading: false });

        Helper.toastAlert("Error while fetching data", "error");
      });
  };
  componentWillUnmount() {
    if (this.audio) {
      this.audio.removeEventListener("ended", () => {
        this.onAudioEnd();
      });
      this.audio = null;
    }
    if (document.getElementById("dynamic-css"))
      document.getElementById("dynamic-css").innerHTML = "";
  }

  onAudioEnd = (e) => {
    if (!this.state.play) {
      return
    }
    let nextData = _.filter(
      this.state.sortedData,
      (o) => o.word_order > this.state.currentData.word_order
    );


    if (nextData.length) {
      let first = _.first(nextData);
      this.setState(
        {
          currentData: first,
        },
        () => {
          this.loadData(first);
          this.playByType();
          // this.audio && this.audio.play()
        }
      );
    } else {
      let first = _.first(this.state.sortedData);
      let currentData = this.state.currentData;
      this.refs[`word-${currentData.id}`] &&
        this.refs[`word-${currentData.id}`].hideToolTip();
      this.setState({ play: false, currentData: first });
    }
  };
  // main function for play audio loop
  loadData = (data) => {
    if (data) {
      this.hideAllToolTip();
      this.audio = null;

      this.refs[`word-${data.id}`] &&
        this.refs[`word-${data.id}`].showToolTip();
      if (data.voice_clip) {
        this.audio = new Audio(Agent.STORAGE_ROOT + data.voice_clip);
        this.audio.addEventListener("ended", (e) => this.onAudioEnd(e));
        this.audio.pause();
        this.audio.load();
      }
    } else {
      console.log("ELSE:", data);
      Helper.toastAlert("Required data not found");
    }
  };
  playByType = () => {
    let data = this.state.currentData;
    if (this.state.currentData.media_id) {
      //audio
      if (this.state.currentData.media.type === "audio") {

        if (data.voice_clip) {
          this.refs[`word-${data.id}`] && this.refs[`word-${data.id}`].play();
        } else {
          delay(this.onAudioEnd, 2000)
        }
      }
      //video
      if (this.state.currentData.media.type === "video") {

        let data = this.state.currentData;
        this.refs[`word-${data.id}`] && this.refs[`word-${data.id}`].play();
      }
      //image
      if (this.state.currentData.media.type === "image") {
        if (data.voice_clip) {
          this.audio && this.audio.play();
        } else {
          delay(this.onAudioEnd, 2000)
        }
      }
      //link
      if (this.state.currentData.media.type === "link") {
        this.audio && this.audio.play();
        if (window.confirm("Want to open Link")) {
          let data = this.state.currentData;
          this.refs[`word-${data.id}`] && this.refs[`word-${data.id}`].play();
          window.open(this.state.currentData.media.path, "_blank");
        } else {
          this.onAudioEnd();
        }
      }
    } else {
      if (data.voice_clip) {
        this.audio && this.audio.play();
      } else {
        delay(this.onAudioEnd, 2000)
      }
    }
  };
  play = () => {
    this.hideAllToolTip();
    this.setState(
      {
        play: true,
      },
      () => {
        this.loadData(this.state.currentData);
        this.playByType();
      }
    );
  };
  // onStop = (id) => {
  //     this.setState({
  //         word_order: this.state.word_order + 1
  //     }, () => {
  //         this.showPopUp(id)
  //         this.play();
  //     })
  // }
  showPopUp = (id) => {
    $("#tooltip_" + id).toggleClass("show-tooltip");
    $("#tooltip_" + id)
      .siblings("span")
      .toggleClass("underline");
  };
  hideAllToolTip = (id = null) => {
    this.state.sortedData.map((o) => {
      if (id) {
        if (o.id != id) {
          this.refs[`word-${o.id}`] && this.refs[`word-${o.id}`].hideToolTip();
        }
      } else {
        this.refs[`word-${o.id}`] && this.refs[`word-${o.id}`].hideToolTip();
      }
    });
  };
  pause = () => {

    this.setState({ play: false }, () => {
      this.audio?.pause();
      this.audio?.removeEventListener("ended", (e) => this.onAudioEnd(e));
      // this.audio = null
      this.hideAllToolTip();
    });


    // this.audio = new Audio(Agent.STORAGE_ROOT + first.voice_clip)
  };

  playByWord = (wordData) => {
    this.setState({ currentData: wordData },
      () => {
        this.loadData(this.state.currentData);
        this.playByType();
      })
  }
  addClickItem = (item) => {
    this.setState({ clickedItem: { ...this.state.clickedItem, ...item } })
  }
  renderColumn = (data) => {
    let renderItems = data.map((val, key) => {
      let renderItem = null;

      if (val.media == null) {
        let style = val.style != null ? JSON.parse(val.style.style) : {};
        let word = val.word ? val.word.word : "";
        let previousWord = val.previousWord
        renderItem = (
          <RenderWord
            style={style}
            ref={`word-${val.id}`}
            elementId={`element-${val.id}`}
            hideAllToolTip={this.hideAllToolTip}
            isHideToolTip={this.state.isHideToolTip}
            play={this.state.play}
            hindiWord={val.hindi_word ? val.hindi_word.word : ""}
            definitionWord={val.definition_word ? val.definition_word.word : ""}
            key={key}
            word={word}
            id={val.id}
            isCapital={val.is_capital}
            tooltipStyle={this.state.tooltipStyle}
            WordData={val}
            playByWord={this.playByWord}
            previousWord={previousWord}
          />
        );
      } else if (val.media.type === "image") {
        let imagePath = val.media ? val.media.path : "";

        renderItem = (
          <RenderImage
            ref={`word-${val.id}`}
            elementId={`element-${val.id}`}
            hideAllToolTip={this.hideAllToolTip}
            isHideToolTip={this.state.isHideToolTip}
            play={this.state.play}
            hindiWord={val.hindi_word ? val.hindi_word.hindi_word : ""}
            definitionWord={val.definition_word ? val.definition_word.word : ""}

            key={key}
            imagePath={imagePath}
            id={val.id}
            tooltipStyle={this.state.tooltipStyle}
          />
        );
      } else if (val.media.type === "audio") {
        let audioPath = val.media ? val.media.path : "";
        renderItem = (
          <RenderAudio
            ref={`word-${val.id}`}
            elementId={`element-${val.id}`}
            hideAllToolTip={this.hideAllToolTip}
            isHideToolTip={this.state.isHideToolTip}
            play={this.state.play}
            hindiWord={val.hindi_word ? val.hindi_word.hindi_word : ""}
            key={key}
            audioPath={audioPath}
            id={val.id}
            onAudioEnded={this.onAudioEnd}
            tooltipStyle={this.state.tooltipStyle}
          />
        );
      } else if (val.media.type === "link") {
        let style = val.style != null ? JSON.parse(val.style.style) : {};
        renderItem = (
          <RenderLink
            style={style}
            ref={`word-${val.id}`}
            elementId={`element-${val.id}`}
            hideAllToolTip={this.hideAllToolTip}
            isHideToolTip={this.state.isHideToolTip}
            play={this.state.play}
            hindiWord={val.hindi_word ? val.hindi_word.hindi_word : ""}
            definitionWord={val.definition_word ? val.definition_word.word : ""}

            key={key}
            name={val.media.link_name}
            link={val.media.path}
            id={val.id}
            onAudioEnded={this.onAudioEnd}
            tooltipStyle={this.state.tooltipStyle}
          />
        );
      } else if (val.media && val.media.path) {
        let videoPath = val.media.path || "";
        renderItem = (
          <RenderVideo
            ref={`word-${val.id}`}
            hideAllToolTip={this.hideAllToolTip}
            elementId={`element-${val.id}`}
            isHideToolTip={this.state.isHideToolTip}
            play={this.state.play}
            hindiWord={val.hindi_word ? val.hindi_word.hindi_word : ""}
            key={key}
            path={videoPath}
            id={val.id}
            onVideoEnded={this.onAudioEnd}
            tooltipStyle={this.state.tooltipStyle}
          />
        );
      }
      return renderItem;
    });
    return <Row gutter={16}>{renderItems}</Row>;
  };
  renderData = () => {
    let count = 0;
    let chunk = [];
    let rowCol = [];
    let renderData = this.state.chapter_meta.map((o, k) => {

      if (!o.media_id && (o.from_new_line)) {

        chunk.push(o);
        rowCol[count] = chunk;
        count++;
        chunk = [];
      } else if (o?.word && o.word.word.includes('\\n')) {

        rowCol[count] = chunk;
        count++;
        chunk = [];
        chunk.push(o);
        rowCol[count] = chunk;
        count++;
        chunk = [];
      } else if (o.media_id) {
        rowCol[count] = chunk;
        count++;
        chunk = [];
        chunk.push(o);
        rowCol[count] = chunk;
        count++;
        chunk = [];
      } else {
        chunk.push(o);
        if (k === this.state.chapter_meta.length - 1) {
          rowCol[count] = chunk;
          chunk = [];
          count++
        }
      }
    });

    return rowCol.map((o) => {
      return this.renderColumn(o);
    });
    // return renderData.map(Boolean);
  };
  handleOption = (check, name) => {

    this.setState({ [name]: check });
  };
  render() {
    var previewStyle = {
      backgroundColor: Helper.rgbaConvertor(
        this.state.chapter_background
      ),
      padding: "0px 30px",
      height: "100%",
      paddingBottom: "7%",
    };
    return (
      <>
      {!this.state.previewMode && 
        <PageHeader
          className="site-page-header"
          title={`Preview of ${this.state.name}`}
          ghost={false}
          onBack={() => this.props.history.push('/chapter')}
          // subTitle="This is a subtitle"
          extra={[

            <Space>
              <Button size="large" shape="circle" key="1" onClick={() => {
                this.state.play ? this.pause() : this.play();
              }} icon={(!this.state.play) ? <PlayCircleTwoTone /> : <PauseCircleTwoTone />} ghost />
              <Button size="large" className="preview-button" shape="circle" key="1" onClick={() => {
                this.state.play ? this.pause() : this.play();
              }} icon={(!this.state.play) ? <PlayCircleTwoTone /> : <PauseCircleTwoTone />} ghost />
              {this.state.isHideToolTip ? "Show Tooltip" : "Hide Tooltip"}
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(checked) => this.handleOption(checked, 'isHideToolTip')}
                checked={this.state.isHideToolTip}
              />
            </Space>
          ]}
        />
  }

        <Spin spinning={this.state.loading}>
          <div style={previewStyle}>
            {this.renderData()}

          </div>
        </Spin>

      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterPreview);
