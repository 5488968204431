import {
  ADD_CHAPTER_LOADING_DROPDOWN_LOADING,
  ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING,
  SET_ADD_CHAPTER_DROPDOWN_LIST,
} from './addChapter.action';

const initialState = {
  dropDownLoading: false,
  dropDownData: {},

  subjectDropDownLoading: false,
};
function addChapterReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CHAPTER_LOADING_DROPDOWN_LOADING:
      state = {
        ...state,
        dropDownLoading: action.payload,
      };
      break;
    case ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING:
      state = {
        ...state,
        subjectDropDownLoading: action.payload,
      };
      break;
    case SET_ADD_CHAPTER_DROPDOWN_LIST:
      state = {
        ...state,
        dropDownData: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
}
export default addChapterReducer;
