import { Col } from 'antd';
import React from 'react';
import Agent from '../../../agent/Agent';
const { forwardRef, useRef, useState, useImperativeHandle } = React;



const RenderImage = forwardRef(({ style, id, imagePath, elementId, hideAllToolTip, isHideToolTip, play, tooltipStyle, hindiWord, voiceClip }, ref) => {
  let time = null
  const [showToolTip, setState] = useState(false)
  const [showUnderLine, setUnderLine] = useState(false)
  useImperativeHandle(ref, () => ({
    showToolTip() {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        setState(true)
        document.getElementById(elementId) &&
          document.getElementById(elementId).scrollIntoView({ behavior: "smooth", inline: 'center', block: 'center' });
        setUnderLine(true)
      } else {

        setUnderLine(true)

      }
    },
    hideOnlyToolTip() {
      setState(false);
    },
    hideUnderline() {

      setUnderLine(false);

    },
    hideToolTip() {
      setState(false)
      setUnderLine(false)
    }
  }));
  const toggleToolTipWord = () => {

    if (!play) {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        hideAllToolTip(id)
        setState(!showToolTip)
      } else {
        hideAllToolTip(id)
        setUnderLine(!showUnderLine)
      }
    }
  }
  let path = Agent.STORAGE_ROOT + imagePath
  return (
    <>

      <Col style={{ display: "contents" }}>

        <img
          style={{ maxWidth: "80%", width: "80%", height: "auto", margin: "20px auto 20px auto" }}
          src={path}
        />
        {/* <img className={`img-raised ${(showUnderLine ? "img-thumbnail" : "")} rounded img-fluid mx-auto d-block`} src={path} alt="Norway" style={{ width: "100%" }} /> */}
        {/* {(showToolTip == false) ?
          <span style={tooltipStyle} className="popup-text hindi-text">{hindiWord || 'This is a picture'}</span>
          :
          <span style={tooltipStyle} className="popup-text hindi-text show-tooltip ">{hindiWord || 'This is a picture'}</span>

        } */}


      </Col>

    </>



  )
})
export default RenderImage