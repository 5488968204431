import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table } from 'antd';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import { customValidator } from '../../../function/customValidation';
import Actions from '../../../redux/actions';
const _ = require('lodash')
const mapStateToProps = (state, ownProps) => {
    return {

    }
}
const mapDispatchToProps = {
    addLanguage: (data) => dispatch => dispatch(Actions.languageAction.add(data)),
    //fetchClass: () => dispatch => dispatch(Actions.classAction.fetch()),
    updateLanguage: (data) => dispatch => dispatch(Actions.languageAction.update(data)),
    deleteLanguage: (data) => dispatch => dispatch(Actions.languageAction.deleteLanguage(data)),
    fetch: () => dispatch => dispatch(Actions.languageAction.fetch()),
}

class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            languageName: '',
            code: '',
            code3: '',
            id: 0,
            isEdit: false,
            buttonLoading: false,
            pageLoading: false
        }
        this.validator = customValidator()
        //this.classRef = React.createRef()
        this.languageRef = React.createRef()
        this.formRef = React.createRef()
    }
    componentDidMount() {
        //this.getClasses()
        this.getLanguage()
    }
    getLanguage = () => {
        this.setState({ pageLoading: true })
        this.props.fetch().then((res) => {
            this.setState({ pageLoading: false })
            if (res.data.status) {
                this.setState({
                    languageList: res.data.data
                })
            }

        }).catch((error) => {
            this.setState({ pageLoading: false })
            Helper.toastAlert("Error while fething languages", "error")
        })
    }

    handleEdit = (id, code3, languageName, code) => {
        this.formRef.current.setFieldsValue({
            name: languageName,
            code: code,
            code3: code3
        });
        this.setState({ id: id })
        this.languageRef.current.focus()


    }
    handleDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteLanguage({ id: id }).then((res) => {
                            if (res.data.status == true) {
                                this.getLanguage()
                                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, "success",)
                            } else {
                                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_0, "error")
                            }
                        }).catch((error) => {
                            Helper.toastAlert(Helper.handleError(error), "error")

                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                },
            ]
        });


    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    addLanguage = (data) => {
        this.props.addLanguage({ language_name: data.name, id: this.state.id, code: data.code, code3: data.code3 }).then((res) => {
            if (res.data.data) {
                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "success")
                this.formRef.current.resetFields();
                this.getLanguage();
            } else {
                Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, "error")
            }
            this.setState({ buttonLoading: false })
        }).catch((error) => {
            this.setState({ buttonLoading: false })
            Helper.toastAlert(Helper.handleError(error), "error")
        })
    }
    updateData = (data) => {
        this.props.updateLanguage({ language_name: data.name, id: this.state.id, code: data.code, code3: data.code3 }).then((res) => {
            if (res.data.status) {
                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "success")
                this.formRef.current.resetFields();

                this.getLanguage();
            } else {
                Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, "error")
            }
            this.setState({ buttonLoading: false })
        }).catch((error) => {
            this.setState({ buttonLoading: false })
            Helper.toastAlert(Helper.handleError(error), "error")
        })
    }
    handleSubmit = (data) => {
        this.setState({ buttonLoading: true })
        if (this.state.id) {
            this.updateData(data, this.state.id)
        } else {
            this.addLanguage(data)
        }


    }
    renderTable = () => {
        const columns = [
            { title: 'Sr.No.', width: 100, dataIndex: '', key: 'count', render: (text, row, index) => ++index, },
            { title: 'Code3', dataIndex: 'code3', key: 'code3', },
            { title: 'Language', dataIndex: 'name', key: 'language', },
            { title: 'Code', dataIndex: 'code', key: 'code', },
            {
                title: 'Edit', width: 100, dataIndex: '', key: 'edit',
                render: (text, row, index) => <a className="text-info" onClick={() => { this.handleEdit(row.id, row.code3, row.name, row.code) }}> <EditOutlined /></a>,
            },
            {
                title: 'Trash',
                dataIndex: '',
                width: 100,
                key: 'x',
                render: (text, row, index) => <a className="text-danger" onClick={() => { this.handleDelete(row.id, row.name) }}> <DeleteOutlined /></a>,
            },
        ];
        return <Table scroll={{ x: "100vh", y: "350px" }} loading={this.state.pageLoading} pagination={false} columns={columns} dataSource={this.state.languageList} size="large" />
    }
    render() {
        return (
            <>

                <div className="from-wrapper">
                    <Form
                        ref={this.formRef}
                        layout="inline" name="class" onFinish={this.handleSubmit} >
                        <Form.Item name={'name'} label="Language Name" rules={[{ required: true }]}>
                            <Input ref={this.languageRef} placeholder="Enter language name" />
                        </Form.Item>
                        <Form.Item name={'code3'} label="Code3" rules={[{ required: true }]}>
                            <Input placeholder="Enter ISO3 code" />
                        </Form.Item>
                        <Form.Item name={'code'} label="Code" rules={[
                    { required: true, max: 2 },
                    {
                      pattern: /([a-zA-Z0-9]){2}$/,
                      message: 'Please enter valid code!',
                    },
                  ]}>
                            <Input  maxLength={3} placeholder="Enter language ISO2 code" />
                        </Form.Item>
                        <Form.Item >
                            <Button loading={this.state.buttonLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="from-wrapper">
                    {this.renderTable()}
                </div>
            </>
        )

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Language);
