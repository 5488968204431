import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';
const downloadFiles = (file) => {
  window.open(Agent.API_ROOT + `download-file/${file}`);
};
const exportChapter = (data) => () => {
  return Agent.ChapterRoutes.exportData(data).then(
    (res) => {
      if (res?.data?.status) {
        const {
          media_url = '',
          excel_url = '',
          voice_url = '',
        } = res?.data?.data;
        media_url && downloadFiles(media_url);
        excel_url && downloadFiles(excel_url);
        voice_url && downloadFiles(voice_url);
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }

      return res;
    },
    () => {
      Helper.toastAlert('Error while exporting data', 'error');
    }
  );
};
const exportAction = {
  exportChapter,
};
export default exportAction;
