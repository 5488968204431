import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const alertOptions = {
  offset: 14,
  position: 'top center',
  theme: 'dark',
  time: 5000,
  transition: 'scale',
};
const alertMsg = {
  FLASH_MSG_REC_FAILED: 'Failed Process!',
  FLASH_MSG_LOGIN_1: 'Successfully loggedin !',
  FLASH_MSG_LOGIN_0: 'Invalid credentials',
  FLASH_MSG_REC_ADD_1: 'Record added successfully!',
  FLASH_MSG_FILE_ADD_1: 'Process done',
  FLASH_MSG_FILE_ADD_0: 'Process not done',
  FLASH_MSG_REC_ADD_0: 'Record not added!',
  FLASH_MSG_REC_UPDATE_1: 'Record updated successfully!',
  FLASH_MSG_REC_UPDATE_0: 'Record not updated!',
  FLASH_MSG_REC_DELETE_1: 'Record deleted successfully!',
  FLASH_MSG_REC_DELETE_0: 'Record not deleted!',
  FLASH_MSG_CHILD_EXIST_1:
    'Record cannot be deleted! Please delete child records first?',
  FLASH_MSG_RESET_PASS_0: 'Password not changed! Try again.',
  FLASH_MSG_RESET_PASS_1: 'Password update successfully!',
  FLASH_MSG_RESET_PASS_2: 'Old password invalid!',

  USER_EXIST: 'User exist.',
  SOMETHING_WORNG: 'Something Wrong Happened. server error!',
  SUCCESS_MESSAGE: 'Successfully Done',
  NETWORK_ERROR: 'Network error',
};
const defaultToolTipSetting = () => {
  return {
    tooltipBGColor: { r: 255, g: 255, b: 255, a: 1 },
    tooltipColor: { r: 0, g: 0, b: 0, a: 1 },
    font: 'mangal',
    fontSize: 40,
    bold: 0,
    italic: 0,
    underline: 0,
    strikeThrough: 0,
  };
};
function handleError(error) {
  if (error.message != undefined && error.message == 'Network error') {
    return alertMsg.NETWORK_ERROR;
  } else {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      if (error.response.status == 500) {
        return alertMsg.SOMETHING_WORNG;
      }
      if (error?.response?.data?.msg != undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: error?.response?.data?.msg?.split('.,').join('. <br/>'),
            }}
          />
        );
      } else if (error?.response?.data?.message != undefined) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: error?.response?.data?.message
                ?.split('.,')
                ?.join('. <br/>'),
            }}
          />
        );
      } else {
        return alertMsg.SOMETHING_WORNG;
      }
    } else {
      // Something happened in setting up the request that triggered an error
      return alertMsg.SOMETHING_WORNG;
    }
  }
}
const toastAlert = (msg, type = 'success', callback = null) => {
  switch (type) {
    case 'error':
      toast.error(msg, { onClose: callback });
      break;
    case 'warn':
      toast.warn(msg, { onClose: callback });
      break;
    case 'info':
      toast.info(msg, { onClose: callback });
      break;
    default:
      toast.success(msg, { onClose: callback });
  }
};
const buttonLoading = () => {
  let override = `
    display: block;
    margin: 0 auto;
    border-color: '#4285f4';
   
    `;
  return (
    <ClipLoader
      css={override}
      sizeUnit={'px'}
      size={35}
      color={'#4285f4'}
      loading={true}
    />
  );
};
const rgbaConvertor = (object) => {
  return object
    ? `rgba(${object.r}, ${object.g} ,${object.b}, ${object.a})`
    : '';
};
const previewStyle = (styleObj) => {
  let textDecoration = 'none';
  textDecoration = `${rgbaConvertor(styleObj.color)}`;

  if (styleObj.underline) {
    textDecoration += ' underline';
  }
  if (styleObj.strikeThrough) {
    textDecoration += ' line-through';
  }
  return {
    color: rgbaConvertor(styleObj.color),
    textShadow: `${styleObj.xValue}px ${styleObj.yValue}px ${
      styleObj.blur
    }px ${rgbaConvertor(styleObj.shadowColor)}`,
    WebkitTextStroke: `${styleObj.outlineWidth}px ${rgbaConvertor(
      styleObj.outlineColor
    )}`,
    fontWeight: styleObj.bold ? 'bold' : 'normal',
    fontStyle: styleObj.italic ? 'italic' : 'normal',
    fontSize: `${styleObj.fontSize}px`,
    fontFamily: `${styleObj.fontFamily}`,
    textDecoration: textDecoration,
  };
};
const tootlTipStyleFormat = (o, className) => {
  let textDecoration = 'none';
  if (parseInt(o.underline) && parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} underline line-through`;
  } else if (parseInt(o.underline) && !parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} underline`;
  } else if (!parseInt(o.underline) && parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} line-through`;
  }

  return `.${className ? ClassName : 'tooltip-style .ant-tooltip-inner'}{
            color: ${rgbaConvertor(o.color)} !important;
            background-color: ${rgbaConvertor(o.backgroundColor)};
            border-color : ${rgbaConvertor(
              o.backgroundColor
            )} transparent transparent transparent;
            font-size: ${o.fontSize}px;
            font-family: ${o.font};
            font-weight: ${parseInt(o.bold) ? 'bold' : 'normal'};
            font-style: ${parseInt(o.italic) ? 'italic' : 'normal'};
            text-decoration: ${textDecoration};
          }
          .${className ? ClassName : 'tooltip-style'} .arrow:after {
            bottom: 1px;
            border-top-color:${rgbaConvertor(o.backgroundColor)};
          }
          `;
};
const toolTipStyleObject = (o, className) => {
  let textDecoration = 'none';
  if (parseInt(o.underline) && parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} underline line-through`;
  } else if (parseInt(o.underline) && !parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} underline`;
  } else if (!parseInt(o.underline) && parseInt(o.strikeThrough)) {
    textDecoration = `${rgbaConvertor(o.color)} line-through`;
  }
  return {
    color: rgbaConvertor(o.color),
    backgroundColor: rgbaConvertor(o.backgroundColor),
    fontWeight: parseInt(o.bold) ? 'bold' : 'normal',
    fontStyle: parseInt(o.italic) ? 'italic' : 'normal',
    borderColor: `${rgbaConvertor(
      o.backgroundColor
    )} transparent transparent transparent`,
    fontSize: `${o.fontSize}px`,
    fontFamily: `${o.font}`,
    textDecoration: textDecoration,
  };
};
const chapterMetaFormat = (o) => {
  return {
    word: o.word ? o.word.word : '',
    styleId: o.style_id,
    hindiWord: o.hindi_word ? o.hindi_word.word : '',
    definitionWord: o.definition_word ? o.definition_word.word : '',
    voiceClip: o.voice_clip,
    wordOrder: o.word_order,
    id: o.id,
    word_id: o.word_id,
    media: o.media,
    chapterId: o.chapter_id,
    media_id: o.media_id,
    is_capital: o.is_capital,
    hindi_word_id: o.hindi_word_id,
    key: o.id,
    srNo: o.chapter_order++,
  };
};
export default {
  alertOptions,
  handleError,
  previewStyle,
  toastAlert,
  chapterMetaFormat,
  alertMsg,
  rgbaConvertor,
  buttonLoading,
  tootlTipStyleFormat,
  toolTipStyleObject,
  defaultToolTipSetting,
};
