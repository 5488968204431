import { Form, Menu, Input, Button, Checkbox, Row, Col, Layout, Typography, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import SigninImage from './img/signin.jpg'
import { useParams } from 'react-router';
const { Title } = Typography
require('./login.css')

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = ({
  doLogin, history
}) => {
  const [loading, setLoading] = useState(false)
  const onFinish = (values) => {
    setLoading(true)
    doLogin(values, (d) => { this.uploadDetail(d) }).then((response) => {
      setLoading(false)
      if (response.data.status) {
        Helper.toastAlert(Helper.alertMsg.FLASH_MSG_LOGIN_1, 'success', () => {
          localStorage.setItem('_token', response.data.data.api_token)
          localStorage.setItem('_role', response.data.data.role)
          history.push('/chapter')
        });
      } else {
        Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
      }
    }).catch((error) => {
      setLoading(false)

      Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
    })
    console.log('Success:', values);
  };



  return (
    <Row className="login-page-layout">
      <Col style={{
        background: `url('${SigninImage}')`,
        backgroundClip: "border-box", backgroundRepeat: "no-repeat", backgroundPosition: "center",
        backgroundSize:"cover"
      }} className="site-layout-content" flex={8}></Col>
      <Col  flex={4}>
        <Row justify="space-around" style={{ height: "100vh" }} align="middle">
          <Space size="large">
            <Col flex={12}>
              <Title lavel="h2"><LockOutlined /> Welcome back! </Title>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, type: "email", message: 'Please input your Email!' }]}
                >
                  <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input

                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>


                <Form.Item>
                  <Button loading={loading} block size="large" type="primary" htmlType="submit" className="login-form-button">
                    Log in
                </Button>
                </Form.Item>
              </Form>
            </Col>
          </Space>
        </Row>
      </Col>
    </Row>

  );
};
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = {
  doLogin: (data, config) => dispatch => dispatch(Actions.loginAction.doLogin(data, config))
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)

