import Agent from "../../agent/Agent";
const fetch = () => dispatch => {
  return Agent.Class.getClass()
}
const add = (data) => dispatch => {
  return Agent.Class.addClass(data)
}
const update = (data) => dispatch => {
  return Agent.Class.editClass(data)
}
const deleteClass = (data) => dispatch => {
  return Agent.Class.deleteClass(data)
}

const classAction = {
  fetch, add, deleteClass, update
}
export default classAction