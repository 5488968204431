import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './app.css';
import Board from './view/componant/Board';
import Chapter from './view/componant/chapter';
import AddChapter from './view/componant/chapter/Add';
import Background from './view/componant/chapter/Add/BackgroundStyle';
import ChapterLoad from './view/componant/chapterLoad';
import ChapterPreview from './view/componant/chapterPreview';
import StyleModule from './view/componant/StyleModule/index';
const { Content } = Layout;
// import TooltipModule from './view/componant/TooltipModule'
import ChapterBuilder from './view/componant/chapterPreview/ChapterBuilder';
import Class from './view/componant/Class';
import Course from './view/componant/Course';
import Language from './view/componant/Language';
import Login from './view/componant/login/index';
import MobilePreview from './view/componant/mobilePreview';
import Students from './view/componant/students';
import AddStyle from './view/componant/StyleModule/AddStyle';
import Subject from './view/componant/Subject';
import Sidebar from './view/includes/Sidebar/Sidebar';

import { useState } from 'react';
import Dictionary from './view/componant/dictionary';
import AddStudent from './view/componant/students/addStudent';

export default function RoutePath() {
  const [collapsed, setCollapsed] = useState(true);
  if (
    localStorage.getItem('_token') == undefined &&
    !localStorage.getItem('_token')
  ) {
    return (
      <Switch>
        <Route
          exact
          path="/"
          name="Login"
          component={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/mobile-view/:id/:version/preview"
          name="Chapter"
          component={MobilePreview}
        />
      </Switch>
    );
  }
  const hasPreviewRoute =
    window.location.hash.includes('preview') ||
    window.location.hash.includes('build');

  return (
    <Layout
      style={{
        minHeight: '100vh',
        flexDirection: 'row',
        width: hasPreviewRoute ? '100%' : 'calc(100% - 20px)',
      }}
    >
      {!hasPreviewRoute && (
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      )}

      <Layout
        className="site-layout"
        style={{
          minWidth: !hasPreviewRoute
            ? collapsed
              ? 'calc(100% - 20px)'
              : 'calc(100% - 180px)'
            : '100%',
        }}
      >
        {/* <Header className="site-layout-background" style={{ padding: 0 }} /> */}
        <Content
          style={{
            margin: hasPreviewRoute ? '' : '16px',
          }}
        >
          <Switch>
            <Route
              exact
              path="/style"
              name="Style Module"
              component={StyleModule}
            />
            <Route
              exact
              path="/style/add"
              name="Style Module"
              component={AddStyle}
            />
            <Route
              exact
              path="/style/edit/:id"
              name="Style Module"
              component={AddStyle}
            />
            <Route
              exact
              path="/chapter/add"
              name="Chapter list"
              component={AddChapter}
            />
            <Route
              exact
              path="/chapter/:id/edit"
              name="Chapter list"
              component={AddChapter}
            />
            <Route
              exact
              path="/chapter/:id/:version/load"
              name="Chapter Load"
              component={ChapterLoad}
            />
            <Route
              exact
              path="/chapter/:id/:version/preview"
              name="Chapter Load"
              component={ChapterPreview}
            />
            <Route
              exact
              path="/chapter/:id/:version/build"
              name="Chapter Load"
              component={ChapterBuilder}
            />
            <Route
              exact
              path="/chapter/:id/background/"
              name="Chapter Load"
              component={Background}
            />

            <Route exact path="/class/" name="Class" component={Class} />
            <Route exact path="/test-tech/" name="Class" component={Class} />
            <Route exact path="/subject/" name="Subject" component={Subject} />
            <Route exact path="/chapter" name="Chapter" component={Chapter} />
            <Route exact path="/" name="Chapter" component={Chapter} />
            <Route
              exact
              path="/language/"
              name="Language"
              component={Language}
            />
            <Route exact path="/board/" name="Board" component={Board} />
            <Route exact path="/course/" name="Course" component={Course} />
            <Route exact path="/student" name="Students" component={Students} />
            <Route
              exact
              path="/dictionary"
              name="Dictionary"
              component={Dictionary}
            />
            <Route
              exact
              path="/student/new"
              name="NewStudents"
              component={AddStudent}
            />
            <Route
              exact
              path="/student/:id/edit"
              name="NewStudents"
              component={AddStudent}
            />

            <Route
              exact
              path="/mobile-view/:id/:version/preview"
              name="Chapter"
              component={MobilePreview}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
