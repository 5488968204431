import { connect } from 'react-redux';
import AddChapter from './AddChapter';
import addChapterAction from './addChapter.action';

const mapStateToProps = (state) => {
  return {
    dropDownData: state.addChapterReducer.dropDownData,
    dropDownLoading: state.addChapterReducer.dropDownLoading,
    subjectDropDownLoading: state.addChapterReducer.subjectDropDownLoading,
  };
};
const mapDispatchToProps = {
  add: (data, config) => (dispatch) =>
    dispatch(addChapterAction.add(data, config)),
  update: (data, config) => (dispatch) =>
    dispatch(addChapterAction.update(data, config)),

  fetchDropdownData: () => (dispatch) =>
    dispatch(addChapterAction.fetchDropDownData()),

  handleFetchSubject: (data) => (dispatch) =>
    dispatch(addChapterAction.handleFetchSubject(data)),
  handleSelectSubject: (data) => (dispatch) =>
    dispatch(addChapterAction.handleSelectSubject(data)),
  fetchChapterById: (data) => (dispatch) =>
    dispatch(addChapterAction.fetchChapterById(data)),
};
export default connect(mapStateToProps, mapDispatchToProps)(AddChapter);
