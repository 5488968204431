import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutePath from './Routes';
import { store } from './redux/store/index';

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.TOP_CENTER,
  // etc you get the idea
});
ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/" name="route" component={RoutePath} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
