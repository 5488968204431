import { connect } from 'react-redux';

import ImportChapter from './ImportChapter';
import { importChapter } from './import.action';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    importLoading: state.importReducer.importLoading,
  };
};
const mapDispatchToProps = {
  importChapter: (data) => (dispatch) => dispatch(importChapter(data)),
};
export default connect(mapStateToProps, mapDispatchToProps)(ImportChapter);
