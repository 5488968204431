import Agent from "../../agent/Agent";

const fetch = (data, config) => dispatch => {
  return Agent.ChapterRoutes.loadChapter(data, config)
}
const fetchPreview = (data, config) => dispatch => {
  return Agent.ChapterRoutes.previewChapter(data, config)
}
const fetchByPage = (page, data, config) => dispatch => {
  return Agent.ChapterRoutes.loadPageChapter(page, data, config)
}
const saveStyle = (data) => dispatch => {
  return Agent.ChapterRoutes.wordStyleSave(data)
}
const saveHindiWord = (data) => dispatch => {
  return Agent.ChapterRoutes.hindiWordSave(data)
}
const definitionSave = (data) => dispatch => {
  return Agent.ChapterRoutes.definitionSave(data)
}
const addWordOrder = (data) => dispatch => {
  return Agent.ChapterRoutes.wordIndexSave(data)
}
const addRecording = (data) => dispatch => {
  return Agent.ChapterMataRoutes.rocordingUpload(data)
}
const removeRecording = (data) => dispatch => {
  return Agent.ChapterRoutes.removeAudio(data)
}

const deleteWord = (data) => dispatch => {
  return Agent.ChapterMataRoutes.removeWord(data)
}
const addNewMedia = (data) => dispatch => {
  return Agent.ChapterMataRoutes.addNewWord(data)
}
const wordSave = (data) => dispatch => {
  return Agent.ChapterMataRoutes.editWord(data)
}
const activeLanguage = (data) => dispatch => {
  return Agent.ChapterMataRoutes.getActiveLanguage(data)
}
const chapterLanguage = (data) => dispatch => {
  return Agent.ChapterMataRoutes.getChapterLanguage(data)
}
const chapterLoadAction = {
  fetch, fetchPreview, fetchByPage, saveStyle, saveHindiWord, addWordOrder, deleteWord, wordSave, 
  addNewMedia, addRecording, removeRecording, chapterLanguage, activeLanguage,definitionSave
}
export default chapterLoadAction