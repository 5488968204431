import { Breadcrumb } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
export default function BreadcrumbBar({ pageTitle }) {
  const history = useHistory();
  const handleNavigate = (e) => {
    e?.preventDefault();
    history.push('/');
  };
  const handleNavigateToLink = (e, link) => {
    e?.preventDefault();
    link && history.push(link);
  };
  if (Array.isArray(pageTitle)) {
    return (
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="" onClick={handleNavigate}>
          Home
        </Breadcrumb.Item>
        {pageTitle?.map((title) => (
          <Breadcrumb.Item
            href={title.link ? '' : undefined}
            onClick={(e) => handleNavigateToLink(e, title.link)}
          >
            {title.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item href="" onClick={handleNavigate}>
        Home
      </Breadcrumb.Item>

      <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
    </Breadcrumb>
  );
}
