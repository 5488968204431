import { first } from 'lodash';
import Helper from '../../../../Helper';
import Agent from '../../../../agent/Agent';
import Actions from '../../../../redux/actions';

export const ADD_CHAPTER_LOADING_DROPDOWN_LOADING =
  'ADD_CHAPTER_LOADING_DROPDOWN_LOADING';
export const SET_ADD_CHAPTER_DROPDOWN_LIST = 'SET_ADD_CHAPTER_DROPDOWN_LIST';
export const ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING =
  'ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING';
const fetchDropDownData = () => (dispatch) => {
  dispatch({
    type: ADD_CHAPTER_LOADING_DROPDOWN_LOADING,
    payload: true,
  });
  console.log('ac');
  const order = ['course', 'board', 'class', 'language'];
  return Promise.all([
    dispatch(Actions.courseAction.fetch()),
    dispatch(Actions.boardAction.fetch()),
    dispatch(Actions.classAction.fetch()),
    dispatch(Actions.languageAction.fetch()),
  ]).then(
    (result) => {
      let dropdownData = {};
      result.map((res, key) => {
        if (res?.data?.status) {
          const formattedData = res?.data?.data?.map((o) => {
            return { label: o.name, value: o.id, code: o?.code || '' };
          });
          dropdownData[order[key]] = formattedData;
          dispatch({
            type: SET_ADD_CHAPTER_DROPDOWN_LIST,
            payload: dropdownData,
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      });
      dispatch({
        type: ADD_CHAPTER_LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    },
    (error) => {
      Helper.toastAlert(Helper.handleError(error), 'error');

      dispatch({
        type: ADD_CHAPTER_LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    }
  );
};
const handleFetchSubject = (data) => (dispatch, getState) => {
  const { class_id, language_id } = data;
  const dropDownData = getState().addChapterReducer.dropDownData;
  dispatch({
    type: ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING,
    payload: true,
  });
  return Agent.Subject.getClassSubject({
    class_id: class_id,
    language_id: language_id,
  })
    .then(
      (res) => {
        if (res?.data?.status) {
          const subjectDropDowns = res.data.data?.map((o) => ({
            value: o.id,
            label: o.name,
            code: o.code,
            languages: o.languages,
            version: o.version_codes,
          }));
          dispatch({
            type: SET_ADD_CHAPTER_DROPDOWN_LIST,
            payload: {
              ...dropDownData,
              ['subjects']: subjectDropDowns,
            },
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      },
      (error) => {
        Helper.toastAlert(Helper.handleError(error), 'error');
      }
    )
    .finally(() => {
      dispatch({
        type: ADD_CHAPTER_SUBJECT_DROPDOWN_LOADING,
        payload: false,
      });
    });
};
const handleSelectSubject = (value) => (dispatch, getState) => {
  const dropDownData = getState().addChapterReducer.dropDownData;
  const subjectRecord = first(
    dropDownData.subjects.filter((o) => o.value === Number(value))
  );
  if (subjectRecord) {
    const languages = dropDownData.language;
    const updatedLanguage = subjectRecord.languages?.map((o) => ({
      value: o.id,
      label: o.name,
      code: o.code,
    }));
    const versions = subjectRecord.version.split(',');
    const version = languages.filter((o) => versions.includes(o.value + ''));
    dispatch({
      type: SET_ADD_CHAPTER_DROPDOWN_LIST,
      payload: { ...dropDownData, language: updatedLanguage, version },
    });
  }
};
const fetchChapterById = (id) => () => {
  return Agent.ChapterRoutes.fetch(id).then(
    (res) => {
      if (res?.data?.status) {
        return res?.data?.data;
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
    },
    (error) => {
      Helper.toastAlert(Helper.handleError(error), 'error');
    }
  );
};

const add = (data) => () => {
  return Agent.ChapterRoutes.uploadChapter(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
        return res?.data?.data;
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (error) => {
      Helper.toastAlert(Helper.handleError(error), 'error');
    }
  );
};
const update = (data) => () => {
  return Agent.ChapterRoutes.updateChapter(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
        return res?.data?.data;
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (error) => {
      Helper.toastAlert(Helper.handleError(error), 'error');
    }
  );
};
const addChapterAction = {
  update,
  fetchDropDownData,
  add,
  handleFetchSubject,
  fetchChapterById,
  handleSelectSubject,
};
export default addChapterAction;
