/**
 * Action Types Constants for Newsletter Module
 */
export const ActionConstant = {

  /**
   * User Data Action CreatorType Constants
   */
  USER: {
    ADD_USER_DATA: "ADD_USER_DATA"
  },
  CHAPTER: {
    ADD_DATA: "ADD_DATA"
  }

}