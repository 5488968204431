import { map } from 'lodash';
import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';
export const UPDATE_STUDENT_LIST = 'UPDATE_STUDENT_LIST';
export const LOADING_STUDENT_LIST = 'LOADING_STUDENT_LIST';
export const LOADING_DROPDOWN_LOADING = 'LOADING_DROPDOWN_LOADING';
export const SET_DROPDOWN_LIST = 'SET_DROPDOWN_LIST';
export const SUBJECT_DROPDOWN_LOADING = 'SUBJECT_DROPDOWN_LOADING';
const fetchDropDownData = () => (dispatch) => {
  dispatch({
    type: LOADING_DROPDOWN_LOADING,
    payload: true,
  });
  const order = ['course', 'board', 'class', 'language'];
  return Promise.all([
    Agent.Course.getCourse(),
    Agent.Board.getBoard(),
    Agent.Class.getClass(),
    Agent.Language.getLanguage(),
  ]).then(
    (result) => {
      let dropdownData = {};
      result.map((res, key) => {
        if (res?.data?.status) {
          dropdownData[order[key]] = res?.data?.data;
          dispatch({
            type: SET_DROPDOWN_LIST,
            payload: dropdownData,
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      });
      dispatch({
        type: LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    },
    (err) => {
      Helper.toastAlert('Error while fetching students', 'error');
      dispatch({
        type: LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    }
  );
};
const handleFetchSubject =
  ({ class_id, language_id }) =>
  (dispatch, getState) => {
    const dropDownData = getState().studentReducer.dropDownData;
    dispatch({
      type: SUBJECT_DROPDOWN_LOADING,
      payload: true,
    });
    return Agent.Subject.getClassSubject({ class_id, language_id })
      .then(
        (res) => {
          if (res?.data?.status) {
            let availableLanguages = [];
            const subjectDropDowns = res.data.data?.map((o) => {
              availableLanguages = [...availableLanguages, ...o.languages];
              return {
                value: o.id,
                label: o.code ? o.code + '-' + o.name : o.name,
                languages: o.languages,
              };
            });
            const subjectLang = map(availableLanguages, 'id');

            const filteredLanguage = dropDownData?.language?.filter((o) =>
              subjectLang.includes(o.id)
            );
            dispatch({
              type: SET_DROPDOWN_LIST,
              payload: {
                ...dropDownData,
                subjects: subjectDropDowns,
                language: filteredLanguage,
              },
            });
            return subjectDropDowns;
          } else {
            Helper.toastAlert(res?.data?.message, 'error');
            return [];
          }
        },
        (err) => {
          Helper.toastAlert('Error while fetching students', 'error');
          dispatch({
            type: LOADING_STUDENT_LIST,
            payload: false,
          });
        }
      )
      .finally(() => {
        dispatch({
          type: SUBJECT_DROPDOWN_LOADING,
          payload: false,
        });
      });
  };
const fetch = (data) => (dispatch) => {
  dispatch({
    type: LOADING_STUDENT_LIST,
    payload: true,
  });
  return Agent.Students.getStudents(data).then(
    (res) => {
      if (res?.data?.status) {
        dispatch({
          type: UPDATE_STUDENT_LIST,
          payload: res.data.data,
        });
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      dispatch({
        type: LOADING_STUDENT_LIST,
        payload: false,
      });
    },
    (err) => {
      Helper.toastAlert('Error while fetching students', 'error');
      dispatch({
        type: LOADING_STUDENT_LIST,
        payload: false,
      });
    }
  );
};
const fetchStudentById = (data) => () => {
  return Agent.Students.getStudentById(data).then(
    (res) => {
      if (res?.data?.status) {
        return res?.data?.data;
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return null;
    },
    (err) => {
      Helper.toastAlert('Error while fetching students', 'error');
      return null;
    }
  );
};
const update = (id, data) => (dispatch) => {
  return Agent.Students.updateStudent(id, data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
    },
    (err) => {
      Helper.toastAlert('Error while updating student', 'error');
    }
  );
};
const updateStatus = (data) => (dispatch) => {
  return Agent.Students.updateStatus(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
    },
    (err) => {
      Helper.toastAlert('Error while updating student', 'error');
    }
  );
};
const add = (data) => (dispatch) => {
  return Agent.Students.addStudent(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
        dispatch(fetch());
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (err) => {
      Helper.toastAlert('Error while adding student', 'error');
    }
  );
};
const deleteStudent = (data) => (dispatch) => {
  return Agent.Students.deleteStudent({ id: data }).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (err) => {
      Helper.toastAlert('Error while adding student', 'error');
    }
  );
};
const studentAction = {
  fetch,
  update,
  fetchDropDownData,
  add,
  updateStatus,
  deleteStudent,
  handleFetchSubject,
  fetchStudentById,
};
export default studentAction;
