import { Col } from 'antd';
import React from 'react'
const { forwardRef, useRef, useState, useImperativeHandle } = React;



const RenderLink = forwardRef(({ style, id, onAudioEnded, name, link, hideAllToolTip, isHideToolTip, play, tooltipStyle, hindiWord, voiceClip }, ref) => {
  let time = null
  const [showToolTip, setState] = useState(false)
  const [showUnderLine, setUnderLine] = useState(false)
  useImperativeHandle(ref, () => ({
    showToolTip() {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        setState(true)
        setUnderLine(true)
      } else {
        setUnderLine(true)

      }
    },
    hideOnlyToolTip() {
      setState(false);
    },
    hideToolTip() {
      setState(false)
      setUnderLine(false)
    },
    hideUnderline() {

      setUnderLine(false);

    },
    play() {
      let timeInterVal = setTimeout(() => {
        clearInterval(timeInterVal);
        onAudioEnded()
      }, 5000)
    }
  }));
  const toggleToolTipWord = () => {

    if (!play) {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        hideAllToolTip(id)
        setState(!showToolTip)
      } else {
        hideAllToolTip(id)
        setUnderLine(!showUnderLine)
      }
    }
  }
  return (
    <>
      <Col span={8}></Col>
      <Col span={8} >
        <span style={{ paddingRight: '10px', fontSize: "3em" }} >
          <span className="popup " ><span style={style} className={(showUnderLine) ? `underline` : ''}>
            <a href={link} target="_blank" className="text-primary"><i>{name}</i></a></span>

            {(showToolTip == false) ?
              <span style={tooltipStyle} className="popup-text hindi-text">{hindiWord}</span>
              :
              <span style={tooltipStyle} className="popup-text hindi-text show-tooltip ">{hindiWord}</span>

            }

          </span>

        </span>
      </Col>
      <Col span={8}></Col>
    </>

  )
})
export default RenderLink