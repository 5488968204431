import React, { useCallback, useState } from 'react';
import { TwitterPicker } from 'react-color';
import reactCSS from 'reactcss';

export default function ColorPicker({ color = undefined, onChange }) {
  const [visible, setVisible] = useState(false);
  const styles = reactCSS({
    default: {
      color: {
        width: '100%',
        height: '20px',
        borderRadius: '2px',
        background: color
          ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
          : '#FFF',
      },

      swatch: {
        padding: '5px',
        width: '100%',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '9999',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });
  const handleClick = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  const handleChange = useCallback((data) => {
    onChange && onChange(data);
  }, []);
  return (
    <>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {visible ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <TwitterPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
}
