import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Table,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';

import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import BreadcrumbBar from '../../includes/BreadCrumb/BreadcrumbBar';

import SubjectFilter from './SubjectFilter';

import { map } from 'lodash';

const _ = require('lodash');

const mapDispatchToProps = {
  addSubject: (data) => (dispatch) => dispatch(Actions.subjectAction.add(data)),
  fetchClass: () => (dispatch) => dispatch(Actions.classAction.fetch()),
  fetchLanguage: () => (dispatch) => dispatch(Actions.languageAction.fetch()),
  updateSubject: (data) => (dispatch) =>
    dispatch(Actions.subjectAction.update(data)),
  deleteSubject: (data) => (dispatch) =>
    dispatch(Actions.subjectAction.deleteSubject(data)),
  fetch: (param) => (dispatch) => dispatch(Actions.subjectAction.fetch(param)),
};

const Subject = ({
  fetch,
  fetchClass,
  addSubject,
  updateSubject,
  deleteSubject,
  fetchLanguage,
}) => {
  const [subjectList, setSubjectList] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  const [id, setId] = useState(0);
  const [classList, setClassList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const classRef = useRef(null);
  const subjectRef = useRef(null);
  const formRef = useRef(null);
  const languageRef = useRef(null);

  const [form] = Form.useForm();

  const getSubject = useCallback(
    (filterData = null) => {
      const formData = {
        name: filterData?.name || '',
        class_id: filterData?.class_id || '',
        language_id: filterData?.language_id || '',
      };
      setTableLoading(true);
      fetch(formData)
        .then((res) => {
          if (res.data.status) {
            setSubjectList(res.data.data);
          }
        })
        .catch((error) => {
          Helper.toastAlert('Error while fetching subjects', 'error');
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    [fetch]
  );

  const getAllData = async () => {
    try {
      setPageLoading(true);
      const formData = {
        name: '',
        class_id: '',
        language_id: '',
      };
      const response = await Promise.all([
        fetch(formData),
        fetchLanguage(),
        fetchClass(),
      ]);
      response[0]?.data?.status && setSubjectList(response[0]?.data?.data);
      response[2]?.data?.data && setClassList(response[2]?.data.data);
      response[1]?.data?.status && setLanguageList(response[1]?.data?.data);
      setPageLoading(false);
    } catch (error) {
      console.log(error);
      Helper.toastAlert('Error while fetching subjects', 'error');
      setPageLoading(false);
    }
  };
  const handleFetchWithFilter = useCallback(() => {
    const filterValues = form.getFieldsValue();
    let formData = {};
    Object.keys(filterValues).map((key) => {
      if (filterValues[key]) {
        formData = {
          ...formData,
          [key]: filterValues[key],
        };
      }
    });
    getSubject(formData);
  }, [form, getSubject]);

  useEffect(() => {
    getAllData();
  }, []);

  const handleEdit = (row) => {
    const classes_ids = map(row?.classes, 'pivot.class_id');
    const language_ids = map(row?.languages, 'pivot.language_id');
    const IVL = map(row?.version_codes?.split(','), (o) => Number(o));
    formRef.current.setFieldsValue({
      name: row.name,
      class: classes_ids,
      code: row?.code,
      language: language_ids,
      IVL,
    });
    setId(row.id);
    subjectRef.current.focus();
  };

  const handleDelete = (id) => {
    deleteSubject({ id: id })
      .then((res) => {
        if (res.data.status == true) {
          handleFetchWithFilter();
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, 'success');
        } else {
          Helper.toastAlert(res.data.message, 'error');
        }
      })
      .catch((error) => {
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };

  const addSubjectHandler = (data) => {
    addSubject({
      subject_name: data.name,
      id: id,
      class_id: data.class,
      code: data.code,
      language_id: data.language,
      IVL: data.IVL,
    })
      .then((res) => {
        if (res.data.data) {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, 'success');
          formRef.current.resetFields();
          handleFetchWithFilter();
        } else {
          Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        }
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };
  const updateData = (data) => {
    updateSubject({
      subject_name: data.name,
      id: id,
      class_id: data.class,
      code: data.code,
      language_id: data.language,
      IVL: data.IVL,
    })
      .then((res) => {
        if (res.data.status) {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_UPDATE_1, 'success');
          formRef.current.resetFields();

          handleFetchWithFilter();
        } else {
          Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        }
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };
  const handleSubmit = useCallback(
    (data) => {
      setButtonLoading(true);
      if (id) {
        updateData(data, id);
      } else {
        addSubjectHandler(data);
      }
    },
    [updateData, addSubjectHandler]
  );

  const renderClasses = (code = '', classList = []) => {
    const classes = map(classList, (o) => `(${o.code}${code})${o.name}`);
    return classes.join(',');
  };
  const renderLanguage = (code = '', languageList = []) => {
    const classes = map(languageList, (o) => `(${o.code}${code})${o.name}`);
    return classes.join(',');
  };

  const renderTable = () => {
    const columns = [
      {
        title: 'Sr.No.',
        width: 100,
        dataIndex: 'id',
        key: 'count',
        render: (text, row, index) => ++index,
      },
      { title: 'Code', dataIndex: 'code', key: 'code' },
      {
        title: 'Class',
        width: 300,
        dataIndex: 'class',
        key: 'subject',
        render: (text, row) => renderClasses(row.code, row?.classes),
      },
      { title: 'Subject', dataIndex: 'name', key: 'class' },
      {
        title: 'Language',
        width: 300,
        dataIndex: 'class',
        key: 'subject',
        render: (text, row) => renderLanguage(row.code, row?.languages),
      },

      {
        title: 'Edit',
        width: 100,
        dataIndex: 'edit',
        key: 'edit',
        render: (text, row, index) => (
          <a className="text-primary" onClick={() => handleEdit(row)}>
            {' '}
            <EditOutlined />
          </a>
        ),
      },
      {
        title: 'Trash',
        dataIndex: '',
        width: 100,
        key: 'xa',
        render: (text, row, index) => (
          <Popconfirm
            title={'Are you sure to delete ' + row.name + '?'}
            onConfirm={() => handleDelete(row.id, row.name)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            placement="leftBottom"
          >
            <Button
              size="small"
              shape="circle"
              tabIndex="-1"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        ),
      },
    ];
    return (
      <Table
        rowKey={'id'}
        scroll={{ x: 'max-content', y: 'calc(100vh - 260px)' }}
        loading={tableLoading}
        pagination={false}
        columns={columns}
        dataSource={subjectList}
        size="large"
      />
    );
  };
  const classListOption = classList.map((val, key) => {
    return { value: val.id, label: val.name };
  });
  const languageListOption = languageList.map((item, key) => {
    return { value: item.id, label: item.name };
  });
  const filterForm = useMemo(() => {
    return (
      <SubjectFilter
        handleBlur={handleFetchWithFilter}
        classListOption={classListOption}
        languageListOption={languageListOption}
        form={form}
      />
    );
  }, [handleFetchWithFilter, classListOption, languageListOption, form]);
  return (
    <>
      <BreadcrumbBar pageTitle="Subjects" />
      <Spin spinning={pageLoading} tip="Loading" size="large">
        <div className="from-wrapper">
          {/* <Typography.Title type="secondary" style={{padding:"10px"}} level={5}>Please fill details to create chapter</Typography.Title> */}
          <Form
            style={{ maxWidth: 800 }}
            ref={formRef}
            name="class"
            onFinish={handleSubmit}
          >
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Form.Item
                  maxLength={15}
                  name={'code'}
                  help="Enter 5 digit number/chars"
                  label="Subject Code"
                  rules={[
                    { required: true, max: 5 },
                    {
                      pattern: /([a-zA-Z0-9]){5}$/,
                      message: 'Please enter valid code!',
                    },
                  ]}
                >
                  <Input
                    maxLength={5}
                    ref={subjectRef}
                    placeholder="Enter subject code"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={'name'}
                  label="Subject Name"
                  rules={[{ required: true }]}
                >
                  <Input ref={subjectRef} placeholder="Enter subject name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Form.Item
                  name={'class'}
                  label="Class Name"
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group ref={classRef} options={classListOption} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Form.Item
                  name={'language'}
                  label="Print Language"
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group
                    ref={languageRef}
                    options={languageListOption}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Form.Item
                  name={'IVL'}
                  label="IVL"
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group options={languageListOption} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button loading={buttonLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="from-wrapper">{filterForm}</div>
        <div className="from-wrapper">{renderTable()}</div>
      </Spin>
    </>
  );
};
export default connect(null, mapDispatchToProps)(Subject);
