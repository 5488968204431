import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Actions from '../../../redux/actions';
import Helper from '../../../Helper';
import { ActionConstant } from '../../../redux/actions/type';
import { ReactMic } from 'react-mic';

import { Button } from 'antd';
import { PlayCircleOutlined, DeleteOutlined } from '@ant-design/icons';
const _ = require('lodash')


export class VoiceRecordingRenderer extends Component {
  static propTypes = {
    value: PropTypes.string
  }
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
      blob: {},
      isRecored: false,
      record: false,
      isPlaying: false,
      loading: false,
    }
    this.audio = null
  }
  componentDidMount() {
    console.log(document.getElementById("start"))
    if (document.getElementById("start")) {
      document.getElementById("start").focus()
    }
  }
  startRecording = () => {
    this.setState({
      record: true,
    }, () => {
      if (document.getElementById("stop")) {
        document.getElementById("stop").focus()

      }

    });
  }
  resetRecording = () => {
    this.setState({
      record: false,
      blob: {},
      isPlaying: false
    });
  }
  stopRecording = () => {
    this.setState({
      record: false,
    }, () => {

    });
  }
  onPlay = () => {
    if (_.size(this.state.blob)) {
      this.setState({ isPlaying: true })

      this.audio = new Audio(this.state.blob.blobURL)
      this.audio.addEventListener('ended', (e) => this.onAudioStop());
      this.audio.pause();
      this.audio.load();
      this.audio.play();
    }
  }
  componentWillUnmount() {
    if (this.audio) {
      this.audio.removeEventListener('ended', () => { this.onAudioStop() })
      this.audio = null
    }
  }
  onAudioStop = () => {
    this.setState({ isPlaying: false })
    this.audio.pause();
    this.audio = null
  }
  saveRecording = () => {
    this.setState({ loading: true })
    var data = new FormData();
    data.append('blob', this.state.blob.blob);
    data.append('chapter_meta_id', this.props.currentRecordingRow.id)
    this.props.addRecording(data).then((res) => {
      this.setState({ loading: false })

      if (res.data) {
        Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "success")

        let newMath = _.map(this.props.chapterMeta, (o) => {
          if (o.id == res.data.id) {
            o['voiceClip'] = res.data.voice_clip
          }
          return o
        })
        this.props.addChapterLoadData(newMath)
        //hide model
        let nextButtonIndex = `btn-record-${this.props.currentRecordingIndex + 1}`
        if (document.getElementById(nextButtonIndex)) {
          document.getElementById(nextButtonIndex).focus()
          document.getElementById(nextButtonIndex).scrollIntoView({ behavior: "smooth", block: "center" });

        }
        this.props.hideRecorder()


      } else {
        this.setState({ loading: false })

        Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "error")
      }
    }).catch((err) => {
      console.log(err)
      Helper.toastAlert(Helper.handleError(err), "error")
    })
  }
  onStop = (recordedBlob) => {
    this.setState({ blob: recordedBlob, record: false }, () => {
      if (document.getElementById("save")) {
        document.getElementById("save").focus()
      }
    })
  }
  buttonControllRender = () => {
    if (_.size(this.state.blob) > 0) {
      return (
        <>
          <Button id="save" onClick={this.saveRecording} loading={this.state.loading} type="primary"><i className="fa fa-save fa-lg"></i></Button>
          {(this.state.isPlaying) ?
            <Button onClick={this.onAudioStop} type="primary" type="button"><i className="fa fa-square fa-lg" ></i></Button> :
            <Button onClick={this.onPlay} type="primary" type="button"><PlayCircleOutlined /></Button>

          }
          <Button onClick={this.resetRecording} type="default" type="button"><DeleteOutlined /></Button>
        </>
      )
    }
    return (
      <>
        {(this.state.record) ?
          <Button className="focusElement " id="end" onClick={this.stopRecording} type="secondary" type="button">
            <i className="fa fa-square fa-lg" ></i>
          </Button>
          :
          <Button className="focusElement " id="start" onClick={this.startRecording} type="danger" type="button">
            <i className="fa fa-circle fa-lg" ></i></Button>
        }
      </>
    )

  }

  render() {
    return (
      <>
        <ReactMic
          className={"text-center"}
          record={this.state.record}
          width={145}
          height={40}
          // className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#000000"
          backgroundColor="#FF4081" />
        <br />
        {this.buttonControllRender()}

      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  addRecording: (data) => dispatch => dispatch(Actions.chapterLoadAction.addRecording(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordingRenderer)



