import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import { customValidator } from '../../../function/customValidation';
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';

const _ = require('lodash')

const propTypes = {};

const defaultProps = {};
class WordEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      value: (this.props.isCapital) ? _.upperFirst(this.props.value) : this.props.value,
      loading: false
    };
    this.validator = customValidator()
  }
  componentDidMount(){
    
    if (typeof window?.MathJax !== 'undefined') {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }
  openEdit = () => {
    this.setState({ isEdit: true })
  }
  closeEdit = () => {
    this.setState({ isEdit: false }, () => {
      this.validator.hideMessages()
    })
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }
  wordSave = () => {

    if (this.validator.allValid()) {
      this.setState({ loading: true })
      this.props.wordSave({ value: this.state.value, id: this.props.currentId }).then((res) => {
        this.setState({ loading: false })
        if (res.data.status) {
          this.setState({ isEdit: false })
          let chapterData = _.map(this.props.chapterMeta, (o, k) => {
            return (o.id == this.props.currentId) ? Helper.chapterMetaFormat(res.data.data) : o
          })
          this.props.addChapterLoadData(chapterData)
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_UPDATE_1, "success")
        } else {
          this.setState({ value: this.props.value })
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_UPDATE_0, "error")
        }
      }).catch((err) => {
        console.log(err)
        Helper.toastAlert(Helper.handleError(err), "error")
        this.setState({ value: this.props.value, loading: false })
      }
      )

    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  render() {
    if (this.state.isEdit) {
      return (
        <>
          <Input onChange={this.handleChange} value={this.state.value} placeholder="Basic usage" />
          <Space direction="horizontal" size={'small'} style={{ marginTop: "5px" }}>
            <Button size='small' loading={this.state.loading} icon={<CheckOutlined />} onClick={this.wordSave} />

            <Button size='small' onClick={this.closeEdit} icon={<CloseOutlined />} />


            <span className="text-danger">
              {this.validator.message('value', this.state.value, 'required')}</span>
          </Space>
        </>
      )
    }

    return (
      <React.Fragment>
        <div style={{ cursor: "pointer" }} className="word" onDoubleClick={this.openEdit}>{(this.props.isCapital) ? _.upperFirst(this.props.value) : this.props.value}</div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  wordSave: (data) => dispatch => dispatch(Actions.chapterLoadAction.wordSave(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}

export default connect(mapStateToProps, mapDispatchToProps)(WordEdit)