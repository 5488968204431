import { connect } from 'react-redux';

import studentAction from '../student.action';
import AddStudent from './AddStudent';

const mapStateToProps = (state) => {
  return {
    loading: state.studentReducer.loading,
    dropDownLoading: state.studentReducer.dropDownLoading,
    dropDownData: state.studentReducer.dropDownData,
    subjectDropDownLoading: state.studentReducer.subjectDropDownLoading,
  };
};
const mapDispatchToProps = {
  updateStudent: (data) => (dispatch) => dispatch(studentAction.update(data)),
  addStudent: (data) => (dispatch) => dispatch(studentAction.add(data)),
  deleteStudent: (data) => (dispatch) =>
    dispatch(studentAction.deleteStudent(data)),
  editStudent: (id, data) => (dispatch) =>
    dispatch(studentAction.update(id, data)),

  fetchStudents: (data) => (dispatch) => dispatch(studentAction.fetch(data)),
  fetchStudentData: (id) => (dispatch) =>
    dispatch(studentAction.fetchStudentById(id)),
  fetchDropDownData: () => (dispatch) =>
    dispatch(studentAction.fetchDropDownData()),
  handleFetchSubject: (param) => (dispatch) =>
    dispatch(studentAction.handleFetchSubject(param)),
};
export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
