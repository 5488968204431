import { Button, Checkbox, Divider, Form, Input, Select, Spin } from 'antd';
import { first, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbBar from '../../../includes/BreadCrumb/BreadcrumbBar';
import BackgroundStyle from './BackgroundStyle';
import TooltipStyle from './TooltipStyle';
const defaultValue = {
  course_ids: [],
  board_ids: [],
  print_language_id: undefined,
  class_id: undefined,
  subject_id: undefined,
  chapter_name: '',
  chapter_no: '',
  unit_no: '',
  unit_name: '',
  version_codes: [],
  font: 'mangal',
  fontSize: 40,
  tooltipColor: { r: 0, g: 0, b: 0, a: 1 },
  tooltipBGcolor: {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },
  chapterBackground: {
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },
  fontStyle: {
    bold: false,
    italic: false,
    underline: false,
    strikeThrough: false,
  },
};
const AddChapter = ({
  dropDownLoading,
  dropDownData,
  fetchDropdownData,
  handleFetchSubject,
  subjectDropDownLoading,
  add,
  update,
  fetchChapterById,
  history,
  handleSelectSubject,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const param = useParams();

  useEffect(() => {
    fetchDropdownData();
    form.setFieldsValue(defaultValue);
  }, []);

  useEffect(async () => {
    if (param?.id) {
      setLoading(true);
      const data = await fetchChapterById(param.id);
      if (!data) {
        return;
      }
      await handleFetchSubject({
        class_id: data.class_id,
        language_id: data.print_language_id,
      });
      const version_codes = map(data.version_codes.split(','), (o) =>
        Number(o)
      );
      const board_ids = map(data.chapter_board, 'id');
      const course_ids = map(data.chapter_course, 'id');
      form.setFieldsValue({
        course_ids: course_ids,
        board_ids: board_ids,
        print_language_id: data.print_language_id,
        class_id: data.class_id,
        subject_id: data.subject_id,
        chapter_name: data.name,
        chapter_no: data.chapter_no,
        unit_no: data.unit_no,
        unit_name: data.unit_name,
        version_codes: version_codes,
        font: data.tooltip_style.font,
        fontSize: data.tooltip_style.fontSize,
        tooltipColor: data.tooltip_style.color,
        tooltipBGcolor: data.tooltip_style.backgroundColor,
        chapterBackground: data.background,
        fontStyle: {
          bold: data.tooltip_style.bold,
          italic: data.tooltip_style.italic,
          underline: data.tooltip_style.underline,
          strikeThrough: data.tooltip_style.strikeThrough,
        },
        uuid: data.uuid,
        chapter_code: data.code,
      });
      setLoading(false);
    }
  }, [param?.id]);

  const handleSubmit = useCallback(async (values) => {
    setButtonLoading(true);
    try {
      let formData = {
        course_ids: values.course_ids,
        board_ids: values.board_ids,
        print_language_id: values.print_language_id,
        class_id: values.class_id,
        subject_id: values.subject_id,
        chapter_name: values.chapter_name,
        chapter_no: values.chapter_no,
        unit_no: values.unit_no,
        unit_name: values.unit_name,
        version_codes: values.version_codes,
        font: values.font,
        font_size: values.fontSize,
        tooltip_color: JSON.stringify(values.tooltipColor),
        tooltip_bg_color: JSON.stringify(values.tooltipBGcolor),
        chapter_background: JSON.stringify(values.chapterBackground),
        bold: values.fontStyle.bold,
        italic: values.fontStyle.italic,
        underline: values.fontStyle.underline,
        strike_through: values.fontStyle.strikeThrough,
        uuid: values.uuid,
        code: values.chapter_code,
      };
      if (param?.id) {
        formData = { ...formData, id: param.id };
        const response = await update(formData);
        if (response) {
          history.push(`/chapter`);
        }
        setButtonLoading(false);
        return;
      }
      const response = await add(formData);
      console.log(response);
      setButtonLoading(false);
      if (response) {
        history.push(`/chapter`);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClassChange = useCallback((value) => {
    handleFetchSubject({
      class_id: value,
      language_id: form.getFieldValue('print_language_id'),
    });
    form.setFieldValue('subject_id', undefined);
  }, []);

  const handleSubjectChange = useCallback((value) => {
    if (value) {
      handleSelectSubject(value);
    }
  }, []);

  const hasClassSelected = Form.useWatch('class_id', form);
  const hasSubjectSelected = Form.useWatch('subject_id', form);
  const hasLanguageSelected = Form.useWatch('print_language_id', form);
  const hasCodeValue = Form.useWatch('chapter_code', form);
  const hasUnitCodeValue = Form.useWatch('unit_no', form);
  const tooltipBGcolor =
    Form.useWatch('tooltipBGcolor', form) || defaultValue.tooltipBGcolor;
  const tooltipColor =
    Form.useWatch('tooltipColor', form) || defaultValue.tooltipColor;
  const fontSize = Form.useWatch('fontSize', form) || defaultValue.fontSize;
  const font = Form.useWatch('font', form) || defaultValue.font;
  const fontStyle = Form.useWatch('fontStyle', form) || defaultValue.fontStyle;
  const chapterBackground =
    Form.useWatch('chapterBackground', form) || defaultValue.chapterBackground;

  const tooltipSetting = {
    tooltipBGcolor,
    tooltipColor,
    font,
    fontSize,
    fontStyle,
  };

  const courses = dropDownData?.course || [];
  const boards = dropDownData?.board || [];
  const languages = dropDownData?.language || [];
  const versions = dropDownData?.version || [];
  const classes = dropDownData?.class || [];
  const subjects = dropDownData?.subjects || [];
  useEffect(() => {
    const classValue = form.getFieldValue('class_id');
    const languageValue = form.getFieldValue('print_language_id');
    const subjectValue = form.getFieldValue('subject_id');
    const chapterCode = form.getFieldValue('chapter_code') || '';
    const unitCode = form.getFieldValue('unit_no') || '';

    const classCode =
      first(classes.filter((o) => o.value === classValue))?.code || '';
    const languageCode =
      first(languages.filter((o) => o.value === languageValue))?.code || '';
    const subjectCode =
      first(subjects.filter((o) => o.value === subjectValue))?.code || '';
    const uuid =
      classCode + subjectCode + languageCode + unitCode + chapterCode;
    form.setFieldValue('uuid', uuid);
  }, [
    hasClassSelected,
    hasSubjectSelected,
    hasLanguageSelected,
    hasCodeValue,
    hasUnitCodeValue,
  ]);
  return (
    <>
      <BreadcrumbBar pageTitle={param?.id ? 'Edit Chapter' : 'Add Chapter'} />
      <Spin spinning={dropDownLoading || loading}>
        <div className="from-wrapper">
          <Divider orientation="left">Chapter details</Divider>

          <Form
            initialValues={defaultValue}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            name="chapter"
            onFinish={handleSubmit}
          >
            <Form.Item
              name={'course_ids'}
              label="Course(s)"
              rules={[{ required: true }]}
            >
              <Checkbox.Group name={'course_ids'} options={courses} />
            </Form.Item>

            <Form.Item
              name={'board_ids'}
              label="Board(s)"
              rules={[{ required: true }]}
            >
              <Checkbox.Group name={'board_ids'} options={boards} />
            </Form.Item>

            <Form.Item
              name={'class_id'}
              label="Class"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Class" onChange={handleClassChange}>
                {classes.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>
                      {val.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={'subject_id'}
              label="Subject"
              rules={[{ required: true }]}
            >
              <Select
                onChange={handleSubjectChange}
                disabled={!hasClassSelected}
                loading={subjectDropDownLoading}
                placeholder="Select Subject"
              >
                {subjects.map((val, key) => {
                  return (
                    <Select.Option value={val.value} key={key}>
                      {val.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={'print_language_id'}
              label="Print Language"
              rules={[{ required: true }]}
            >
              <Select
                loading={subjectDropDownLoading}
                disabled={!hasSubjectSelected || subjectDropDownLoading}
                placeholder="Select Language"
              >
                {languages.map((val, key) => {
                  return (
                    <Select.Option value={Number(val.value)} key={key}>
                      {val.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={'chapter_code'}
              label="Chapter Code"
              help="Enter 3 digit number"
              rules={[
                {
                  required: true,
                  message: 'Please enter chapter code',
                },
                {
                  pattern: /^\d{3}$/,
                  message: 'Please enter valid code!',
                },
              ]}
            >
              <Input
                maxLength={3}
                minLength={3}
                placeholder="Enter chapter Code"
              />
            </Form.Item>
            <Form.Item
              name={'chapter_name'}
              label="Chapter Name"
              rules={[
                { required: true },
                { max: 250, message: 'Maximum 250 char allowed' },
              ]}
            >
              <Input maxLength={250} placeholder="Enter chapter name" />
            </Form.Item>
            <Form.Item
              name={'chapter_no'}
              label="Chapter No"
              rules={[
                { required: true },
                { max: 10, message: 'Maximum 10 char allowed' },
              ]}
            >
              <Input maxLength={10} placeholder="Enter chapter no" />
            </Form.Item>
            <Form.Item
              name={'unit_name'}
              label="Unit Name"
              rules={[
                { required: true },
                { max: 250, message: 'Maximum 250 char allowed' },
              ]}
            >
              <Input maxLength={250} placeholder="Enter unit name" />
            </Form.Item>
            <Form.Item
              name={'unit_no'}
              help="Enter 2 digit number"
              label="Unit No"
              rules={[
                { required: true, max: 2 },
                {
                  pattern: /([a-zA-Z0-9]){2}$/,
                  message: 'Please enter valid code!',
                },
              ]}
            >
              <Input maxLength={2} placeholder="Enter unit no" />
            </Form.Item>

            <Form.Item
              name={'version_codes'}
              label="Planned Language(s)"
              rules={[{ required: true }]}
            >
              <Checkbox.Group
                name={'version_codes'}
                disabled={!hasSubjectSelected || subjectDropDownLoading}
                options={versions}
              />
            </Form.Item>
            <Form.Item name={'uuid'} label="UUID">
              <Input maxLength={15} disabled={true} />
            </Form.Item>
            <Divider orientation="left">Tooltip</Divider>
            <TooltipStyle data={tooltipSetting} form={form} />
            <Divider orientation="left">Background</Divider>
            {<BackgroundStyle form={form} data={chapterBackground} />}

            <Form.Item wrapperCol={{ offset: 4 }}>
              <Button loading={buttonLoading} type="primary" htmlType="submit">
                {param?.id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </>
  );
};
export default AddChapter;
