import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import { first } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbBar from '../../includes/BreadCrumb/BreadcrumbBar';
import DictionaryFilter from './DictionaryFilter';
export default function Dictionary({
  loading,
  data,
  handleFetchDictionary,
  dropDownLoading,
  dropdownData,
  fetchDropDownData,
  saveLoading,
  handleFormSave,
}) {
  const [modelState, setModelState] = useState({ open: false, data: null });
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const getData = async () => {
    const getData = await fetchDropDownData();
    if (getData?.length > 0) {
      const data = first(getData);
      form.setFieldValue('version', data.id);
    }
    handleBlur();
  };
  useEffect(() => {
    getData();
  }, []);

  const handleBlur = () => {
    const filterValues = form.getFieldsValue();
    let formData = {};
    Object.keys(filterValues).map((key) => {
      if (filterValues[key]) {
        formData = {
          ...formData,
          [key]: filterValues[key],
        };
      }
    });
    handleFetchDictionary(formData);
  };
  const handleOpen = useCallback(
    (row) => {
      updateForm.setFieldValue('word', row.word);
      setModelState({
        open: true,
        data: row,
      });
    },
    [updateForm]
  );

  const handleSave = useCallback(async () => {
    const word = updateForm.getFieldValue('word');
    await handleFormSave({
      id: modelState.data.id,
      word,
    });
    updateForm.resetFields();
    getData();
    handleClose();
  }, [handleClose, modelState, handleSave, getData]);

  const handleClose = useCallback(() => {
    setModelState({
      open: false,
      data: null,
    });
  }, []);
  const columns = useMemo(
    () => [
      {
        title: 'Sr.No.',
        width: 100,
        dataIndex: '',
        key: 'id',
        render: (text, row, index) => ++index,
      },
      {
        title: 'Language',
        width: 100,
        dataIndex: 'language.id',
        key: 'language_id',
        render: (text, row) => row?.language?.name,
      },
      {
        title: 'Word',
        width: 100,
        dataIndex: 'word',
        key: 'first_name',
      },
      {
        title: 'Meaning',
        width: 100,
        dataIndex: 'middle_name',
        key: 'middle_name',
        render: (text, row) => {
          const data = row.meaning?.map((o, k) => {
            if (o.word) {
              let isLast = k === row?.meaning?.length - 1;
              return (
                <span key={o.word + o.id}>
                  {o.word}({o?.style?.name}) {!isLast ? ', ' : ''}
                </span>
              );
            }
          });
          return data?.filter(Boolean);
        },
      },

      {
        title: 'Edit',
        width: 80,
        dataIndex: '',
        key: 'edit',
        render: (text, row) => (
          <Button
            size="small"
            onClick={() => handleOpen(row)}
            className="text-info"
          >
            <EditOutlined />
          </Button>
        ),
      },
    ],
    []
  );
  return (
    <>
      <BreadcrumbBar pageTitle="Dictionary" />
      <Row align="middle" className="site-page-header ant-page-header">
        <Col span={24}>
          <DictionaryFilter
            form={form}
            handleBlur={handleBlur}
            dropDownData={dropdownData}
            dropDownLoading={dropDownLoading}
          />
        </Col>
      </Row>

      <Table
        className="build-table"
        scroll={{ x: '100vh' }}
        loading={loading}
        sticky
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data}
        size="large"
      />
      <Modal
        title="Update Dictionary"
        open={modelState.open}
        onCancel={handleClose}
        onOk={handleSave}
        name
        okText="Save"
        okButtonProps={{
          loading: saveLoading,
        }}
        style={{ top: 20 }}
      >
        <Form
          form={updateForm}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          name="updateWord"
        >
          <Form.Item name={'word'} label="Word" rules={[{ required: true }]}>
            <Input maxLength={100} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
