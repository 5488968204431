import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Table } from 'antd';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { customValidator } from '../../../function/customValidation';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
const _ = require('lodash')
const mapStateToProps = (state, ownProps) => {
    return {

    }
}
const mapDispatchToProps = {
    addCourse: (data) => dispatch => dispatch(Actions.courseAction.add(data)),
    //fetchClass: () => dispatch => dispatch(Actions.classAction.fetch()),
    updateCourse: (data) => dispatch => dispatch(Actions.courseAction.update(data)),
    deleteCourse: (data) => dispatch => dispatch(Actions.courseAction.deleteCourse(data)),
    fetch: () => dispatch => dispatch(Actions.courseAction.fetch()),
}

class Course extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseList: [],
            courseName: '',
            code: '',
            category: '',
            id: 0,
            isEdit: false,
            buttonLoading: false,
            pageLoading: false
        }
        this.validator = customValidator()
        //this.classRef = React.createRef()
        this.courseRef = React.createRef()
        this.formRef = React.createRef()
    }
    componentDidMount() {
        //this.getClasses()
        this.getCourse()
    }
    getCourse = () => {
        this.setState({ pageLoading: true })
        this.props.fetch().then((res) => {
            this.setState({ pageLoading: false })
            if (res.data.status) {
                this.setState({
                    courseList: res.data.data
                })
            }

        }).catch((error) => {
            this.setState({ pageLoading: false })
            Helper.toastAlert("Error while fething courses", "error")
        })
    }
    /*getClasses = () => {
        this.props.fetchClass().then((res) => {
            this.setState({
                classList: res.data.data
            })

        }).catch((error) => {
            Helper.toastAlert("Error while fething classes", "error")
        })
    }*/
    handleEdit = (id, category, courseName, code) => {
        this.formRef.current.setFieldsValue({
            name: courseName,
            code: code,
            category: category
        });
        this.setState({ id: id })
        this.courseRef.current.focus()


    }
    handleDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteCourse({ id: id }).then((res) => {
                            if (res.data.status == true) {
                                this.getCourse()
                                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, "success",)
                            } else {
                                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_0, "error")
                            }
                        }).catch((error) => {
                            Helper.toastAlert(Helper.handleError(error), "error")

                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                },
            ]
        });


    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    addCourse = (data) => {
        this.props.addCourse({ course_name: data.name, id: this.state.id, code: data.code, category: data.category }).then((res) => {
            if (res.data.data) {
                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "success")
                this.formRef.current.resetFields();
                this.getCourse();
            } else {
                Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, "error")
            }
            this.setState({ buttonLoading: false })
        }).catch((error) => {
            this.setState({ buttonLoading: false })
            Helper.toastAlert(Helper.handleError(error), "error")
        })
    }
    updateData = (data) => {
        this.props.updateCourse({ scourse_name: data.name, id: this.state.id, code: data.code, category: data.category }).then((res) => {
            if (res.data.status) {
                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, "success")
                this.formRef.current.resetFields();

                this.getCourse();
            } else {
                Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, "error")
            }
            this.setState({ buttonLoading: false })
        }).catch((error) => {
            this.setState({ buttonLoading: false })
            Helper.toastAlert(Helper.handleError(error), "error")
        })
    }
    handleSubmit = (data) => {
        this.setState({ buttonLoading: true })
        if (this.state.id) {
            this.updateData(data, this.state.id)
        } else {
            this.addCourse(data)
        }


    }

    renderTable = () => {
        const columns = [
            { title: 'Sr.No.', width: 100, dataIndex: '', key: 'count', render: (text, row, index) => ++index, },
            { title: 'Category', dataIndex: 'category', key: 'category', },
            { title: 'Course', dataIndex: 'name', key: 'course', },
            { title: 'Code', dataIndex: 'code', key: 'code', },
            {
                title: 'Edit', width: 100, dataIndex: '', key: 'edit',
                render: (text, row, index) => <a className="text-info" onClick={() => { this.handleEdit(row.id, row.category, row.name, row.code) }}> <EditOutlined /></a>,
            },
            {
                title: 'Trash',
                dataIndex: '',
                width: 100,
                key: 'x',
                render: (text, row, index) => <a className="text-danger" onClick={() => { this.handleDelete(row.id, row.name) }}> <DeleteOutlined /></a>,
            },
        ];
        return <Table scroll={{ x: "100vh", y: "350px" }} loading={this.state.pageLoading} pagination={false} columns={columns} dataSource={this.state.courseList} size="large" />
    }
    render() {
        return (
            <>

                <div className="from-wrapper">
                    <Form
                        ref={this.formRef}
                        layout="inline" name="class" onFinish={this.handleSubmit} >
                        <Form.Item name={'name'} label="Course Name" rules={[{ required: true }]}>
                            <Input ref={this.courseRef} placeholder="Enter course name" />
                        </Form.Item>
                        <Form.Item name={'category'} label="Category" rules={[{ required: true }]}>
                            <Select placeholder="Select category" >
                                <option value="CENTRAL">Central</option>
                                <option value="STATE">State</option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={'code'} label="Code" rules={[{ required: true }]}>
                            <Input placeholder="Enter course ISO2 code" />
                        </Form.Item>
                        <Form.Item >
                            <Button loading={this.state.buttonLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="from-wrapper">
                    {this.renderTable()}
                </div>
            </>
        )
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Course);
