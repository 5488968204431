import Agent from "../../agent/Agent";
const fetchByChapter = (data) => dispatch => {
  return Agent.Course.getChapterCourse(data)
}
const fetch = (data) => dispatch => {
  return Agent.Course.getCourse(data)
}
const add = (data) => dispatch => {
  return Agent.Course.addCourse(data)
}
const update = (data) => dispatch => {
  return Agent.Course.editCourse(data)
}
const deleteCourse = (data) => dispatch => {
  return Agent.Course.deleteCourse(data)
}
const activeCourse = (data) => dispatch => {
  return Agent.Course.getActiveCourse(data)
}
const chapterCourse = (data) => dispatch => {
  return Agent.Course.getChapterCourse(data)
}


const courseAction = {
  fetchByChapter, fetch, deleteCourse, add, update, chapterCourse, activeCourse
}
export default courseAction