import { Col, Form, Row, Select } from 'antd';
import React from 'react';
import { CATEGORY } from '../../constants';

export default function StudentFilter({
  form,
  handleBlur,
  dropDownLoading,
  dropDownData,
}) {
  return (
    <Form
      form={form}
      layout="inline"
      style={{ display: 'block' }}
      size="large"
      name="createStudent"
      initialValues={{
        modifier: 'public',
      }}
    >
      <Row>
        <Col span={6}>
          <Form.Item name="category" label="Select Category">
            <Select
              showSearch
              onChange={handleBlur}
              optionFilterProp="children"
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select"
              loading={dropDownLoading}
            >
              <Select.Option value={''}>Select</Select.Option>
              {(CATEGORY || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="board_id" label="Select Board">
            <Select
              showSearch
              onChange={handleBlur}
              optionFilterProp="children"
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select"
              loading={dropDownLoading}
            >
              <Select.Option value={''}>Select</Select.Option>
              {(dropDownData?.board || []).map((o) => (
                <Select.Option key={o.id} value={o.id}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="course_id" label="Select Course">
            <Select
              placeholder="Select"
              onChange={handleBlur}
              showSearch
              loading={dropDownLoading}
              optionFilterProp="children"
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              <Select.Option value={''}>Select</Select.Option>
              {(dropDownData?.course || []).map((o) => (
                <Select.Option key={o.id} value={o.id}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="class_id" label="Select Class">
            <Select
              optionFilterProp="children"
              onChange={handleBlur}
              showSearch
              size="middle"
              filterOption={(input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select"
              loading={dropDownLoading}
            >
              <Select.Option value={''}>Select</Select.Option>
              {(dropDownData?.class || []).map((o) => (
                <Select.Option key={o.id} value={o.id}>
                  {o.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
