import React, { Component } from 'react';
import reactCSS from 'reactcss'
import { TwitterPicker } from 'react-color'

import { Form, } from 'antd';

class BackgroundStyle extends Component {
    constructor (props) {
        super(props);
        this.state = {
            displayColorPicker1: false,
            color1: this.props.data,
            updatecolor:true,
        }
    }
    handleClick1 = () => {
        this.setState({ displayColorPicker1: !this.state.displayColorPicker1 })
    };

    handleClose1 = () => {
        this.setState({ displayColorPicker1: false })
    };
    handleChange1 = (color) => {
        this.setState({ color1: color.rgb,updatecolor:false })
    };
    componentDidMount() {
        console.log("Bagroundsytpe:",this.props.data)
        if (this.props.data) {
            this.setState({ color1: this.props.data })
        }
    }

    componentDidUpdate(nextProps,prevProps){
        
        if(this.state.updatecolor && nextProps.data!=prevProps.color1){
            this.setState({ color1: nextProps.data })
        }
    }

    handleSubmit = () => {
        return JSON.stringify(this.state.color1)
    }
    render() {
        const styles = reactCSS({
            'default': {
                color1: {
                    width: '100%',
                    height: '20px',
                    borderRadius: '2px',
                    background: `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})`,
                },
                swatch: {
                    padding: '5px',
                    width: '100%',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        var previewStyle = {
            'backgroundColor': `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})`,
            'borderColor': `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a}) transparent transparent transparent`,
            'fontSize': `${this.state.fontSize}px`
        }
        return (

            <div>
                <Form.Item wrapperCol={{ sm: { span: 3 }, md: { span: 3 } }} label="Background Color" >
                    <div style={styles.swatch} onClick={this.handleClick1}>
                        <div style={styles.color1} />
                    </div>
                    {this.state.displayColorPicker1 ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose1} />
                        <TwitterPicker
                            color={this.state.color1}
                            onChange={this.handleChange1} />
                    </div> : null}
                </Form.Item>
            </div>)

    }
}
export default BackgroundStyle
