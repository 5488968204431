import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Upload } from 'antd';
import React, { useCallback } from 'react';

import { useState } from 'react';

function VoiceImport({
  chapterId,
  versionId,
  importRecording,
  importLoading,
  onImport,
  chapterData,
}) {
  const uuid = chapterData?.uuid || '';
  const chapterName = chapterData?.name || '';
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const showImportPopup = useCallback(async () => {
    setOpen(true);
  }, []);
  const hideImportPopup = useCallback(async () => {
    setOpen(false);
    form.resetFields();
  }, []);

  const handleImport = useCallback(async () => {
    form.validateFields().then(async (values) => {
      const formData = new FormData();
      formData.append(
        'chapter_file',
        values.excelFile?.file?.originFileObj || ''
      );
      formData.append('voice_file', values.zipFile?.file?.originFileObj || '');
      formData.append('chapter_id', chapterId || '');
      formData.append('chapter_name', chapterName || '');
      formData.append('uuid', uuid);
      const res = await importRecording(formData);
      if (res?.data?.status) {
        onImport &&
          onImport({
            chapterId: chapterId,
            versionId: versionId,
          });
        hideImportPopup();
      }
    });
  }, [uuid, chapterName]);

  const customRequest = useCallback(async ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('Ok');
    }, 0);
  }, []);
  return (
    <>
      <Button onClick={showImportPopup} size="medium" type="primary">
        Import Recording
      </Button>

      <Modal
        title="Import Recording"
        centered
        open={open}
        onCancel={hideImportPopup}
        onOk={handleImport}
        okButtonProps={{
          loading: importLoading,
        }}
        style={{ top: 20 }}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          name="importRecording"
        >
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            name={'zipFile'}
            label="Import File"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || value.fileList.length === 0) {
                    return Promise.reject(
                      new Error('The Import Zip file is required')
                    );
                  } else if (value?.file?.name?.split('.')?.pop() !== 'zip') {
                    return Promise.reject(new Error('Only Zip file allowed'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Upload
              customRequest={customRequest}
              maxCount={1}
              openFileDialogOnClick={true}
              progress={false}
              name="file"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default VoiceImport;
