import { Col, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import Agent from "../../../agent/Agent";
import Helper from "../../../Helper";

// import 'tippy.js/dist/tippy.css';

const { forwardRef, useRef, useState, useImperativeHandle, useEffect, useMemo } = React;

const RenderWord = forwardRef(
  (
    {
      style,
      elementId,
      isCapital,
      word,
      hideAllToolTip,
      isHideToolTip,
      play,
      tooltipStyle,
      hindiWord,
      definitionWord = '',
      voiceClip,
      id,
      WordData,
      playByWord,
      previousWord,
      isBuild = false
    },
    ref
  ) => {
    let time = null;
    const [showToolTip, setState] = useState(false);
    const [eventTarget, setEventTarget] = useState(null);
    const [showUnderLine, setUnderLine] = useState(false);
    let fooRef = useRef(null);
    let audioRef = useRef(WordData.voice_clip ? new Audio(Agent.STORAGE_ROOT + WordData.voice_clip) : null);
    useEffect(() => {

      return () => {
        audioRef.current = null
      }
    }, [])
    const toolTipWord = useMemo(() => {
      return isBuild ? definitionWord : hindiWord
    }, [])
    useImperativeHandle(ref, () => ({
      showToolTip() {
        if (!(hindiWord == null || hindiWord.trim() == "") && !isHideToolTip) {
          document.getElementById(elementId) &&
            document.getElementById(elementId).scrollIntoView({ behavior: "smooth", inline: 'center', block: 'center' });
          setState(true);
          setUnderLine(true);
        } else {
          setUnderLine(true);
        }
      },
      hideToolTip() {
        setState(false);
        setUnderLine(false);
        audioRef?.current?.pause();
        audioRef?.current?.load()
      },
      hideOnlyToolTip() {
        setState(false);
      },
      hideUnderline() {
        setState(false);
        setUnderLine(false);
        hideAllToolTip()
      },
    }));
    const toggleToolTipWord = (event) => {
      if (!play) {
        if (!(hindiWord == null || hindiWord.trim() == "") && !isHideToolTip) {
          hideAllToolTip(id);
          setState(!showToolTip);
          setEventTarget(event.target);
          if (WordData) {
            audioRef?.current?.play()
          }
        } else {
          hideAllToolTip(id);
          audioRef?.current?.load()
          setUnderLine(!showUnderLine);
        }
      }
    };
    const holdTooltip = (event) => {
      if (event.ctrlKey) {
        console.log(toolTipWord)
        hideAllToolTip(id)
        setUnderLine(true);
        toolTipWord && setState(true);
      }
    };
    let updatedTitle = []
    if (word?.includes('\\n') || word?.includes('\\s')) {
      const spaceLength = word.split('\\s')
      if (spaceLength.length > 0) {
        const emptyColumn = spaceLength?.map((o) => (
          <Col><Title
            level={2}>{''}</Title></Col >
        ))
        return emptyColumn

      }
      return (<Col><Title
        level={2}>&nbsp;</Title></Col>)
    }
    if (previousWord?.includes('\\n') || word?.includes('\\s')) {
      const spaceLength = previousWord.split('\\s')
      if (spaceLength.length > 0) {
        updatedTitle = spaceLength?.map((o, k) => (
          <Col key={k}>
            <Title
              level={2}
              style={Helper.previewStyle(style)}

            >
              &nbsp;&nbsp;&nbsp;
            </Title>
          </Col>
        ))
      }
    }

    return (
      <>
        {updatedTitle}

        <Col onClick={isBuild ? holdTooltip : toggleToolTipWord}
          ref={fooRef}>
          <Tooltip overlayInnerStyle={Helper.toolTipStyleObject(tooltipStyle)} color={Helper.rgbaConvertor(tooltipStyle.backgroundColor)} visible={showToolTip} title={toolTipWord}>
            <Title
              level={2}
              style={Helper.previewStyle(style)}
              id={elementId}
              className={showUnderLine ? `underline curser-pointer egOne` : "curser-pointer egOne"}
            >
              {isCapital ? _.startCase(word) : word}
            </Title>
          </Tooltip>


        </Col>
      </>
    );
  }
);
export default RenderWord;
