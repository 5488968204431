import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import BreadcrumbBar from '../../includes/BreadCrumb/BreadcrumbBar';
const _ = require('lodash');

function Class({ updateClass, deleteClass, fetch, addClass, history }) {
  const [classList, setclassList] = useState([]);
  const [pageLoading, setpageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editId, setEditId] = useState(null);
  const classRef = useRef(null);
  const classCodeRef = useRef(null);
  const [form] = Form.useForm();

  const getClasses = () => {
    setpageLoading(true);
    fetch()
      .then((res) => {
        setpageLoading(false);
        if (res.data.status) {
          setclassList(res.data.data);
        } else {
          Helper.toastAlert(res.data.msg, 'error');
        }
      })
      .catch((error) => {
        setpageLoading(false);
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };
  const handleEdit = (row) => {
    form.setFieldsValue({
      name: row.name,
      code: row.code,
    });
    setEditId(row.id);
    classCodeRef.current.focus({
      cursor: 'end',
    });
    window.scrollTo({ top: 10, behavior: 'smooth' });
  };
  useEffect(() => {
    getClasses();
  }, []);
  const handleDelete = (id) => {
    deleteClass({ id: id })
      .then((res) => {
        if (res.data.status == true) {
          getClasses();
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, 'success');
        } else {
          Helper.toastAlert(res.data.message, 'error');
        }
      })
      .catch((error) => {
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };
  const renderTable = () => {
    const columns = [
      {
        title: 'Sr.No.',
        dataIndex: '',
        key: 'count',
        render: (text, row, index) => ++index,
      },
      { title: 'Code', dataIndex: 'code', key: 'code' },
      { title: 'Class', dataIndex: 'name', key: 'class' },

      {
        title: 'Edit',
        width: 100,
        dataIndex: '',
        key: 'edit',
        render: (text, row, index) => (
          <a
            className="text-primary"
            onClick={() => {
              handleEdit(row);
            }}
          >
            {' '}
            <EditOutlined />
          </a>
        ),
      },
      {
        title: 'Trash',
        dataIndex: '',
        width: 100,
        key: 'x',
        render: (text, row, index) => (
          <Popconfirm
            title={'Are you sure to delete ' + row.name + '?'}
            onConfirm={() => handleDelete(row.id, row.name)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            placement="leftBottom"
          >
            <Button
              size="small"
              shape="circle"
              tabIndex="-1"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        ),
      },
    ];
    return (
      <Table
        scroll={{ x: '100vh', y: '350px' }}
        loading={pageLoading}
        pagination={false}
        columns={columns}
        dataSource={classList}
        size="large"
      />
    );
  };
  const addData = (data, id) => {
    addClass({ class_name: data.name, code: data.code, id })
      .then((res) => {
        setButtonLoading(false);
        if (res.data.status) {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_1, 'success');

          form.resetFields();
          getClasses();
        } else {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_ADD_0, 'error');
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };
  const updateData = (className, code, id) => {
    updateClass({ class_name: className, code, id })
      .then((res) => {
        setButtonLoading(false);
        if (res.data.status) {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_UPDATE_1, 'success');
          getClasses();
          setEditId(null);
          form.resetFields();
        } else {
          Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_UPDATE_0, 'error');
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        Helper.toastAlert(Helper.handleError(error), 'error');
      });
  };

  const uploadDetail = (data) => {
    setButtonLoading(true);
    if (editId) {
      updateData(data.name, data.code, editId);
    } else {
      addData(data);
    }
  };
  return (
    <>
      <BreadcrumbBar pageTitle="Classes" />

      <div className="from-wrapper">
        {/* <Typography.Title type="secondary" style={{padding:"10px"}} level={5}>Please fill details to create chapter</Typography.Title> */}
        <Form form={form} layout="inline" name="class" onFinish={uploadDetail}>
          <Form.Item
            name={'code'}
            label="Class Code"
            help="Enter 3 digit number/chars"
            rules={[
              { required: true },
              {
                pattern: /([a-zA-Z0-9]){3}$/,
                message: 'Please enter valid code!',
              },
            ]}
          >
            <Input
              ref={classCodeRef}
              maxLength={3}
              placeholder="Enter class code"
            />
          </Form.Item>
          <Form.Item
            name={'name'}
            label="Class Name"
            rules={[{ required: true }]}
          >
            <Input ref={classRef} placeholder="Enter class name" />
          </Form.Item>

          <Form.Item>
            <Button loading={buttonLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="from-wrapper">{renderTable()}</div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  addClass: (data) => (dispatch) => dispatch(Actions.classAction.add(data)),
  updateClass: (data) => (dispatch) =>
    dispatch(Actions.classAction.update(data)),
  deleteClass: (data) => (dispatch) =>
    dispatch(Actions.classAction.deleteClass(data)),
  fetch: () => (dispatch) => dispatch(Actions.classAction.fetch()),
};

export default connect(mapStateToProps, mapDispatchToProps)(Class);
