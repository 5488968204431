import {
  BookOutlined,
  DiffOutlined,
  FileWordOutlined,
  FontColorsOutlined,
  LeftOutlined,
  LogoutOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  ProjectOutlined,
  RightOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { default as React } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Logo from '../../../assests/logo.jpg';
import './sidebar.css';
const { Content } = Layout;

function Sidebar({ collapsed, location }) {
  const logout = () => {
    localStorage.removeItem('_token');
    sessionStorage.removeItem('_role');
    window.location.href = '/';
  };
  return (
    <Sider collapsedWidth={40} collapsible trigger={null} collapsed={collapsed}>
      <div className="arrow" onClick={() => setCollapsed(!collapsed)}>
        {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
          className: 'trigger',
        })}
      </div>
      <div className={collapsed ? 'logo-collapsed' : 'logo'}>
        <a href="/">
          <img
            className="logo-img"
            src={Logo}
            width={collapsed ? 30 : 150}
            height={collapsed ? 30 : 150}
            alt="mcs"
          />
        </a>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location?.pathname]}
        inlineCollapsed={collapsed}
        defaultSelectedKeys={['1']}
      >
        <Menu.Item icon={<ProjectOutlined />} key="/chapter">
          <NavLink
            accessKey="c"
            activeClassName="active"
            className="nav-link "
            to="/chapter"
          >
            Chapter List
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={<FileWordOutlined />} key="/dictionary">
          <NavLink
            accessKey="c"
            activeClassName="active"
            className="nav-link "
            to="/dictionary"
          >
            Dictionary
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/class" icon={<PlusOutlined />}>
          <NavLink
            accessKey="cl"
            activeClassName="active"
            className="nav-link "
            to="/class"
          >
            Create Class
          </NavLink>
        </Menu.Item>
        <Menu.Item key="/subject" icon={<BookOutlined />}>
          <NavLink
            accessKey="cl"
            activeClassName="active"
            className="nav-link "
            to="/subject"
          >
            Create Subject
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={<FontColorsOutlined />} key="style">
          <NavLink
            accessKey="s"
            activeClassName="active"
            className="nav-link "
            to="/style"
          >
            Style Module
          </NavLink>
        </Menu.Item>

        {localStorage.getItem('_role') === 'super-admin' ? (
          <>
            <Menu.Item icon={<UserOutlined />} key="/student">
              <NavLink
                accessKey="st"
                activeClassName="active"
                className="nav-link "
                to="/student"
              >
                Student Module
              </NavLink>
            </Menu.Item>
            <Menu.Item icon={<TranslationOutlined />} key="/language">
              <NavLink
                accessKey="cl"
                activeClassName="active"
                className="nav-link "
                to="/language"
              >
                Create Language
              </NavLink>
            </Menu.Item>
            <Menu.Item icon={<DiffOutlined />} key="/board">
              <NavLink
                accessKey="cl"
                activeClassName="active"
                className="nav-link "
                to="/board"
              >
                Create Board
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/course" icon={<PlusSquareOutlined />}>
              <NavLink
                accessKey="cl"
                activeClassName="active"
                className="nav-link "
                to="/course"
              >
                Create Course
              </NavLink>
            </Menu.Item>
          </>
        ) : (
          <></>
        )}

        <Menu.Item key="5" icon={<LogoutOutlined />}>
          <a
            accessKey="l"
            className="nav-link "
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
            href="#"
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default withRouter(Sidebar);
