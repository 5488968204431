import { LoadingOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import Agent from '../../../agent/Agent';
import Helper from '../../../Helper';
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 < 200;
    if (!isLt2M) {
        message.error('Image must smaller than 200 KB!');
    }
    return isJpgOrPng && isLt2M;
};
const UploadProfile = ({
    name = '',
    url = '',
    id = 0
}) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        if (imageUrl !== url) {
            setImageUrl(url)
        }
    }, [url])

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {

        setPreviewImage(Agent.STORAGE_ROOT + '/users/' + imageUrl);
        setPreviewOpen(true);
        setPreviewTitle('Preview');
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const customRequest = ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
    }) => {
        // EXAMPLE: post form-data with 'axios'
        // eslint-disable-next-line no-undef
        const formData = new FormData();
        if (data) {
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
        }
        formData.append(filename, file);
        setLoading(true)
        axios.post(action, formData, {
            withCredentials,
            headers,
            onUploadProgress: ({ total, loaded }) => {
                onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
            },
        })
            .then(({ data: response }) => {
                if (response.status) {
                    setImageUrl('');
                    setImageUrl(response.data.image_name);
                    // onSuccess(response, response.data.image_name);
                    Helper.toastAlert(response.message, 'success');
                } else {
                    Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');

                }
            })
            .catch(onError).finally(() => setLoading(false));

        return {
            abort() {
                console.log('upload progress is aborted.');
            },
        };
    }
    const uploadInput = useMemo(() => {
        return (
            <Upload
                beforeUpload={beforeUpload}
                name="image"
                customRequest={customRequest}
                accept="image/png, image/jpg, image/jpeg"
                listType="picture-card"
                action={Agent.API_ROOT + 'update-profile'}
                data={{ studentId: id }}
                headers={{ "Content-Type": "multipart/form-data", 'Accept': 'Application/json', 'Authorization': 'Bearer ' + localStorage.getItem('_token') }}
                className="avatar-uploader"
                showUploadList={false}>

                {loading ? <LoadingOutlined /> : imageUrl ? <EditOutlined /> : <PlusOutlined />}

            </Upload>
        )
    }, [loading, id, customRequest, id])
    return (
        <>
            {!imageUrl ?
                uploadInput
                :
                <span className='preview-image'>
                    <img
                        onClick={handlePreview}
                        src={Agent.STORAGE_ROOT + '/users/' + imageUrl}
                        alt="avatar"
                        style={{
                            width: 40,
                            height: 40,
                            cursor: 'pointer'
                        }}
                    />
                    {uploadInput}
                </span>
            }

            <Modal Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    src={Agent.STORAGE_ROOT + '/users/' + imageUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />

            </Modal>
        </>

    );

};
export default UploadProfile