import {
    LOADING_DROPDOWN_LOADING,
    LOADING_STUDENT_LIST,
    SET_DROPDOWN_LIST,
    SUBJECT_DROPDOWN_LOADING,
    UPDATE_STUDENT_LIST,
} from './student.action';

const initialState = {
  studentList: [],
  loading: false,
  dropDownLoading: false,
  dropDownData: {},
  subjectDropDownLoading: false,
};
function studentReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STUDENT_LIST:
      state = {
        ...state,
        studentList: action.payload,
      };
      break;
    case LOADING_STUDENT_LIST:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    case LOADING_STUDENT_LIST:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    case LOADING_DROPDOWN_LOADING:
      state = {
        ...state,
        dropDownLoading: action.payload,
      };
      break;
    case SUBJECT_DROPDOWN_LOADING:
      state = {
        ...state,
        subjectDropDownLoading: action.payload,
      };
      break;
    case SET_DROPDOWN_LIST:
      state = {
        ...state,
        dropDownData: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
}
export default studentReducer;
