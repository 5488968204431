import {
  SET_DICTIONARY_DATA,
  SET_DICTIONARY_LOADING,
  SET_DROPDOWN_LIST,
  SET_DROPDOWN_LOADING,
  SET_SAVE_LOADING,
} from './dictionary.action';

const initialState = {
  data: [],
  loading: false,
  dropDownLoading: false,
  saveLoading: false,
  dropdownData: [],
};
function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DICTIONARY_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;
    case SET_DICTIONARY_DATA:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    case SET_DROPDOWN_LOADING:
      state = {
        ...state,
        dropDownLoading: action.payload,
      };
      break;
    case SET_DROPDOWN_LIST:
      state = {
        ...state,
        dropdownData: action.payload,
      };
      break;
    case SET_SAVE_LOADING:
      state = {
        ...state,
        saveLoading: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
}
export default dictionaryReducer;
