import axios from 'axios';

var querystring = require('querystring');

const BASE_ROOT = process.env.REACT_APP_BASE_ROOT_URL; //'https://sadafal.in';
// const BASE_ROOT = 'http://127.0.0.1:8000';
const ASSEST_URL = process.env.REACT_APP_ASSET_URL; //"https://mcsaws.s3.ap-south-1.amazonaws.com"
// const BASE_ROOT = HOSTNAME;
const TOKEN_ROOT = BASE_ROOT + '/';
const API_ROOT = BASE_ROOT + '/api/';
const STORAGE_ROOT = BASE_ROOT + '/storage/'; //"https://mcsaws.s3.ap-south-1.amazonaws.com/";
// const STORAGE_ROOT = process.env.REACT_APP_ASSET_URL + '/'; //"https://mcsaws.s3.ap-south-1.amazonaws.com/";
var BASE_URL =
  window.location.hostname == 'localhost'
    ? BASE_ROOT
    : window.location.protocol + '//' + window.location.host + '/';
if (window.location.protocol == '') {
  BASE_URL =
    window.location.hostname == 'localhost'
      ? BASE_ROOT
      : 'http://' + window.location.protocol + '/' + window.location.host + '/';
}
const requests = {
  loginRoute: (url, body) => axios.post(`${API_ROOT}${url}`, body),
  del: (url) =>
    axios.get(`${API_ROOT}${url}`, {
      headers: {
        'content-type': false,
        Accept: 'Application/json',
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
      },
    }),
  get: (url) =>
    axios.get(`${API_ROOT}${url}`, {
      headers: {
        'content-type': 'Application/json',
        Accept: 'Application/json',
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
      },
    }),
  put: (url, body) =>
    axios.put(`${API_ROOT}${url}`, body, {
      headers: {
        withCredentials: true,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
      },
    }),
  post: (url, body) =>
    axios.post(`${API_ROOT}${url}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
      },
    }),

  postWithFile: (url, body) =>
    axios.post(`${API_ROOT}${url}`, body, {
      headers: {
        Accept: 'Application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('_token'),
      },
      onUploadProgress: (p) => {},
    }),
};
const LoginRoutes = {
  login: (data) => requests.loginRoute('login', data),
};
const ChapterRoutes = {
  uploadChapter: (data, config) => requests.post('upload-chapter', data),
  fetch: (id) => requests.get('chapter/' + id),
  updateChapter: (data) => requests.post('update-chapter', data),
  loadChapter: (data) => requests.post('load-chapter', data),
  previewChapter: (data) => requests.post('preview-chapter', data),
  loadPageChapter: (page, data) =>
    requests.post('load-chapter?page=' + page, data),
  deleteChapter: (data) => requests.post('delete-chapter', data),
  hindiWordSave: (data) => requests.post('hindi-word-save', data),
  definitionSave: (data) => requests.post('definition-save', data),
  hindiWordSuggestionSave: (data) =>
    requests.post('save-hindi-suggestion', data),
  wordStyleSave: (data) => requests.post('word-style-save', data),
  getChapter: (data) => requests.post('chapter', data),
  getChapterById: (data) => requests.post('get-chapter-id', data),
  wordIndexSave: (data) => requests.post('save-word-index', data),
  removeAudio: (data) => requests.post('delete-recording', data),
  getActiveBoard: (data) => requests.post('chapter/get-active-board', data),
  getActiveClass: (data) => requests.post('chapter/get-active-class', data),
  getActiveSubject: (data) => requests.post('chapter/get-active-subject', data),
  getActivePrintLanguage: (data) =>
    requests.post('chapter/get-active-print-language', data),
  getActiveVersions: (data) =>
    requests.post('chapter/get-active-versions', data),
  getChapterVersions: (data) =>
    requests.post('chapter/get-chapter-versions', data),
  updatePublishStatus: (data) =>
    requests.post('chapter/update-chapter-publish', data),
  importData: (data) => requests.post('chapter/import', data),
  exportData: (data) => requests.post('chapter/export', data),
};
const StyleRoute = {
  saveStyle: (data) => requests.post('save-style', data),
  getStyleById: (id) => requests.get('get-style/' + id),
  getStyle: (id) => requests.get('get-style'),
  getTooltipStyle: (data) => requests.post('get-tooltip-style', data),
  updateTooltipStyle: (data) => requests.post('update-tooltip-style', data),
  deleteStyle: (data) => requests.post('delete-style', data),
  updateDefaultStyle: (data) => requests.post('update-default-style', data),
};
const Background = {
  getChapterBackground: (id) => requests.get('get-chapter-background/' + id),
  addChapterBackground: (data) => requests.post('add-chapter-background', data),
};
const ChapterMataRoutes = {
  rocordingUpload: (data) => requests.postWithFile('upload-recording', data),
  addNewWord: (data) => requests.postWithFile('add-new-word', data),
  removeWord: (data) => requests.post('delete-word', data),
  editWord: (data) => requests.post('edit-word', data),
  getHindiSuggestion: (data) => requests.post('get-hindi-suggestion', data),
  getActiveLanguage: (data) => requests.post('get-active-language', data),
  getChapterLanguage: (data) => requests.post('get-chapter-language', data),
};
const Class = {
  getClass: () => requests.get('get-class'),
  editClass: (data) => requests.post('edit-class', data),
  deleteClass: (data) => requests.post('delete-class', data),
  addClass: (data) => requests.post('add-class', data),
};
const Subject = {
  getSubject: (data) => requests.post('get-subject', data),
  editSubject: (data) => requests.post('edit-subject', data),
  deleteSubject: (data) => requests.post('delete-subject', data),
  addSubject: (data) => requests.post('add-subject', data),
  getClassSubject: (data) => requests.post('get-class-subject', data),
};
const Language = {
  getLanguage: () => requests.get('get-language'),
  editLanguage: (data) => requests.post('edit-language', data),
  deleteLanguage: (data) => requests.post('delete-language', data),
  addLanguage: (data) => requests.post('add-language', data),
};
const Board = {
  getBoard: () => requests.get('get-board'),
  editBoard: (data) => requests.post('edit-board', data),
  deleteBoard: (data) => requests.post('delete-board', data),
  addBoard: (data) => requests.post('add-board', data),
  getChapterBoard: (data) => requests.post('get-chapter-board', data),
  getActiveBoard: (data) => requests.post('get-active-board', data),
};
const Course = {
  getCourse: () => requests.get('get-course'),
  editCourse: (data) => requests.post('edit-course', data),
  deleteCourse: (data) => requests.post('delete-course', data),
  addCourse: (data) => requests.post('add-course', data),
  getChapterCourse: (data) => requests.post('get-chapter-course', data),
  getActiveCourse: (data) => requests.post('get-active-course', data),
};
const Students = {
  getStudents: (data) => requests.post('get-students', data),
  getStudentById: (data) => requests.post('get-student/' + data),
  updateStudent: (id, data) => requests.post('update-student/' + id, data),
  addStudent: (data) => requests.post('student', data),
  uploadProfile: (data) => requests.postWithFile('upload-profile', data),
  updateStatus: (data) => requests.post('update-status', data),
  deleteStudent: (data) => requests.post('delete-student', data),
};
const Dictionary = {
  getWord: (param) => requests.post('get-words', param),
  getDictionaryVersion: () => requests.get('get-dictionary-version'),
  getDictionaryLanguage: () => requests.get('get-dictionary-language'),
  updateWord: (param) => requests.post('update-word', param),
};
const Voices = {
  getData: (data) =>
    requests.post(
      data?.page ? `get-voice-data?page=${data.page}` : 'get-voice-data',
      data
    ),
  exportRecording: (data) => requests.post('export-recording', data),
  exportAudioRecording: (data) => requests.post('export-audio-recording', data),
  importRecording: (data) =>
    requests.postWithFile('chapter/voice-import', data),
};
export default {
  Background,
  StyleRoute,
  LoginRoutes,
  ChapterRoutes,
  ChapterMataRoutes,
  Class,
  Subject,
  Language,
  Board,
  Course,
  Students,
  STORAGE_ROOT,
  API_ROOT,
  BASE_ROOT,
  Voices,
  Dictionary,
};
