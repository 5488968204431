import { Button, Col, Modal } from 'antd';
import React, { useState } from 'react';
import 'video-react/dist/video-react.css';
import ChapterPreview from '../chapterPreview';
export default function ChapterPreviewModel({
  chapterId,
  versionId,
  chapterData,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Col span={12}>
        <Button onClick={() => setOpen(true)} size="medium" type="primary">
          Preview
        </Button>
      </Col>

      <Modal
        destroyOnClose
        title={'Preview  ' + chapterData?.name}
        onCancel={() => setOpen(false)}
        open={open}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Close
          </Button>,
        ]}
        centered
        width={'98%'}
        bodyStyle={{ height: 'calc(100vh - 150px)', overflow: 'auto' }}
      >
        {open && (
          <ChapterPreview
            match={{ params: { id: chapterId, version: versionId } }}
            previewMode={true}
          />
        )}
      </Modal>
    </>
  );
}
