import Agent from "../../agent/Agent";

const doLogin = (data) => dispatch => {

  // return new Promise((resolve, reject) => {

  //   if (data.email == 'vijay@gmail.com' && data.password == '123456') {
  //     let data = {
  //       id: 1,
  //       fname: 'Vijay',
  //       lname: 'Suthar',
  //       email: 'vijay@gmail.com',
  //       token: '00000'
  //     }
  //     return resolve({ status: true, data })
  //   } else {
  //     return reject({ status: false, msg: "Invalid credentials" })
  //   }

  // })



  return Agent.LoginRoutes.login(data)
}

const loginAction = {
  doLogin
}
export default loginAction