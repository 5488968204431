import Agent from '../../../agent/Agent';
import Helper from '../../../Helper';

export const SET_IMPORT_LOADING = 'SET_IMPORT_LOADING';

const importRecording = (data) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_LOADING,
    payload: true,
  });
  return Agent.Voices.importRecording(data)
    .then((res) => {
      console.log(res?.data);

      dispatch({
        type: SET_IMPORT_LOADING,
        payload: false,
      });
      Helper.toastAlert(
        res?.data?.msg,
        res?.data?.status ? 'success' : 'error'
      );
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SET_IMPORT_LOADING,
        payload: false,
      });
      Helper.toastAlert(
        error?.response?.data?.msg || 'Error while import data',
        'error'
      );
    });
};
const voiceAction = {
  importRecording,
};
export default voiceAction;
