import { map } from 'lodash';
import Helper from '../../../../Helper';
import Agent from '../../../../agent/Agent';
import Actions from '../../../../redux/actions';

export const SET_CHAPTER_LIST = 'SET_CHAPTER_LIST';
export const LOADING_CHAPTER_LIST = 'LOADING_CHAPTER_LIST';
export const LOADING_DROPDOWN_LOADING = 'LOADING_DROPDOWN_LOADING';
export const SET_DROPDOWN_LIST = 'SET_DROPDOWN_LIST';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SUBJECT_DROPDOWN_LOADING = 'SUBJECT_DROPDOWN_LOADING';
const fetchDropDownData = () => (dispatch) => {
  dispatch({
    type: LOADING_DROPDOWN_LOADING,
    payload: true,
  });
  const order = ['course', 'board', 'class', ''];
  return Promise.all([
    Actions.courseAction.activeCourse(),
    Actions.boardAction.activeBoard(),
    Actions.chapterAction.activePrintLanguage(),
    Actions.chapterAction.activeVersions(),
    Actions.chapterAction.activeClass(),
  ]).then(
    (result) => {
      let dropdownData = {};
      result.map((res, key) => {
        if (res?.data?.status) {
          dropdownData[order[key]] = res?.data?.data;
          dispatch({
            type: SET_DROPDOWN_LIST,
            payload: dropdownData,
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      });
      dispatch({
        type: LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    },
    () => {
      Helper.toastAlert('Error while fetching students', 'error');
      dispatch({
        type: LOADING_DROPDOWN_LOADING,
        payload: false,
      });
    }
  );
};
const handleFetchSubject = (classId) => (dispatch, getState) => {
  const dropDownData = getState().studentReducer.dropDownData;
  dispatch({
    type: SUBJECT_DROPDOWN_LOADING,
    payload: true,
  });
  return Agent.Subject.getClassSubject({ class_id: classId })
    .then(
      (res) => {
        if (res?.data?.status) {
          const subjectDropDowns = res.data.data?.map((o) => ({
            value: o.id,
            label: o.code ? o.code + '-' + o.name : o.name,
          }));
          dispatch({
            type: SET_DROPDOWN_LIST,
            payload: {
              ...dropDownData,
              ['subjects']: subjectDropDowns,
            },
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      },
      () => {
        Helper.toastAlert('Error while fetching students', 'error');
        dispatch({
          type: LOADING_CHAPTER_LIST,
          payload: false,
        });
      }
    )
    .finally(() => {
      dispatch({
        type: SUBJECT_DROPDOWN_LOADING,
        payload: false,
      });
    });
};
const fetchChapterList = () => (dispatch) => {
  dispatch({
    type: LOADING_CHAPTER_LIST,
    payload: true,
  });
  return Agent.ChapterRoutes.getChapter()
    .then(
      (res) => {
        if (res?.data?.status) {
          let chapterData = map(res.data.data, (o, i) => {
            return {
              name: o.name,
              class: o.chapter_class.name,
              subject: o.chapter_subject.name,
              print_language: o.chapter_print_language
                ? o.chapter_print_language.name
                : '',
              key: i,
              ...o,
            };
          });
          dispatch({
            type: UPDATE_CHAPTER_LIST,
            payload: chapterData || [],
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      },
      () => {
        Helper.toastAlert('Error while fetching students', 'error');
      }
    )
    .finally(() => {
      dispatch({
        type: LOADING_CHAPTER_LIST,
        payload: false,
      });
    });
};
const update = (id, data) => (dispatch) => {
  return Agent.Students.updateStudent(id, data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
    },
    (err) => {
      Helper.toastAlert('Error while updating student', 'error');
    }
  );
};
const updateStatus = (data) => (dispatch) => {
  return Agent.Students.updateStatus(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
    },
    (err) => {
      Helper.toastAlert('Error while updating student', 'error');
    }
  );
};
const add = (data) => (dispatch) => {
  return Agent.Students.addStudent(data).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
        dispatch(fetch());
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (err) => {
      Helper.toastAlert('Error while adding student', 'error');
    }
  );
};
const deleteStudent = (data) => (dispatch) => {
  return Agent.Students.deleteStudent({ id: data }).then(
    (res) => {
      if (res?.data?.status) {
        Helper.toastAlert(res?.data?.message, 'success');
      } else {
        Helper.toastAlert(res?.data?.message, 'error');
      }
      return res;
    },
    (err) => {
      Helper.toastAlert('Error while adding student', 'error');
    }
  );
};
const chapterAction = {
  fetchChapterList,
  update,
  fetchDropDownData,
  add,
  updateStatus,
  deleteStudent,
  handleFetchSubject,
};
export default chapterAction;
