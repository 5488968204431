import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "../reducers/rootReducer";

// import rootReducer from "../reducers/rootReducer";

import thunk from 'redux-thunk';

/**
 * Initial State of Application at the time of Store Initialization
 */
const initialState = {};

/**
 * Middleware Instantiation
 * 1. redux-thunk
 */
const middleware = [thunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
 
  middleware.push(logger);
}

/**
 * Creates a Redux store that holds the state tree.
 * 
 * @param {Function} reducer A function that returns the next state tree
 * @param {any} [preloadedState] The initial state. 
 * @param {Function} [enhancer] Store Enhancer
 * @returns {Store} A Redux store that lets you read the state, dispatch actions
 * and subscribe to changes.
 * 
 */

 /**
  * Compose Enhancer by including Redux Devtools
  * this also checks if browser support Redux Devtools or not
  */
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const storeEnhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
);

const store = createStore(
    rootReducer(),
    initialState,
    storeEnhancer
);


store.subscribe(() => {})

export {
  store
};

