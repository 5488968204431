import { Button, Popconfirm } from 'antd';
import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';
const _ = require('lodash')
export class RecordingAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAudio: false,
      deleteLoading:false
    }
    this.audio = new Audio(this.props.voiceClip ? this.props.voiceClip : null)
  }
  componentDidMount() {
    this.audio.addEventListener('ended', () => this.stopAudio());
  }
  componentWillUnmount() {
    this.audio && this.audio.pause()
    this.audio.removeEventListener('ended', (e) => this.stopAudio());
  }
  playAudio = () => {
    this.setState({ showAudio: true })
    this.audio?.play()
    console.log("playAudio")
  }
  stopAudio = () => {
    this.setState({ showAudio: false })
    this.audio?.pause()
    console.log("stopAudio")
  }
  removeAudio = () => {
    this.setState({deleteLoading:true})
  this.props.removeRecording({ 'chapter_meta_id': this.props.currentId }).then((res) => {
              if (res.data) {
                Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, 'success');
                let chapterMeta = _.map(this.props.chapterMeta, (o) => {
                  if (o.id == res.data.id) {
                    o.voiceClip = null
                  }
                  return o
                })

                this.props.addChapterLoadData(chapterMeta)
              } else {
                Helper.toastAlert(Helper.handleError(res.data.msg), 'error');
              }

            }).catch((err) => {
              Helper.toastAlert(Helper.handleError(err), 'error');
   }).finally(()=>{
    this.setState({deleteLoading:false})
   })
   
  }
  render() {
    return (
      <div className="btn-group">
        {(this.state.showAudio) ?
          <Button type="default" shape="circle" size="small" disabled={this.props.disabled} tabIndex={this.props.tabIndex} onClick={this.stopAudio} >
            <i className="fa fa-pause fa-2x" ></i>
          </Button>
          :
          <Button type="primary" shape="circle" size="small" disabled={this.props.disabled} tabIndex={this.props.tabIndex} className="btn btn-sm btn-success btn-link" onClick={this.playAudio} >
            <i className="fa fa-play fa-2x" ></i>
          </Button>
        }
        <Popconfirm
           title="Are you sure to delete this recording?"
           disabled={this.props.disabled}
           okButtonProps={{loading:this.state.deleteLoading}}
        onConfirm={this.removeAudio }
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
        placement="leftBottom"
      >
         <Button type="ghost" shape="circle" size="small" disabled={this.props.disabled} tabIndex={this.props.tabIndex} >
          <i className="fa fa-remove fa-2x" ></i>
        </Button>
      </Popconfirm>
       
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  removeRecording: (data) => dispatch => dispatch(Actions.chapterLoadAction.removeRecording(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordingAction)
