import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Select, Upload } from 'antd';
import React, { useCallback } from 'react';

import { useState } from 'react';

function ImportChapter({
  chapterId,
  importChapter,
  versionList,
  onImport,
  chapterData,
  importLoading,
}) {
  const printLanguageId = chapterData?.print_language_id;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleImport = useCallback(async () => {
    form.validateFields().then(async (values) => {
      const formData = new FormData();
      formData.append(
        'chapter_file',
        values.excelFile?.file?.originFileObj || ''
      );
      formData.append('media_file', values.zipFile?.file?.originFileObj || '');
      formData.append('chapter_id', chapterId);
      formData.append('version', values.version);
      formData.append('print_language_id', printLanguageId);

      const res = await importChapter(formData);
      if (res?.status) {
        onImport &&
          onImport({
            chapterId,
            versionId: values.version,
          });
        setOpen(false);
        form.resetFields();
      }
    });
  }, [printLanguageId]);

  const customRequest = useCallback(async ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('Ok');
    }, 0);
  }, []);

  return (
    <>
      <Col span={12}>
        <Button onClick={() => setOpen(true)} size="medium" type="primary">
          Import Data
        </Button>
      </Col>

      <Modal
        title="Import Chapter Data"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleImport}
        name
        okText="Import"
        okButtonProps={{
          loading: importLoading,
        }}
        style={{ top: 20 }}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          name="importData"
        >
          <Form.Item
            name={'version'}
            label="Select IVL"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 150 }}
              placeholder="Select IVL"
              options={versionList}
            />
          </Form.Item>
          <Form.Item
            name={'excelFile'}
            label="Choose excel File"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || value.fileList.length === 0) {
                    return Promise.reject(
                      new Error('The Import Excel file is required')
                    );
                  } else if (
                    !['xlsx', 'xls'].includes(
                      value?.file?.name?.split('.')?.pop()
                    )
                  ) {
                    return Promise.reject(
                      new Error('Only xlsx, slx files allowed')
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Upload
              customRequest={customRequest}
              maxCount={1}
              openFileDialogOnClick={true}
              progress={false}
              name="file"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={'zipFile'}
            label="Choose media file"
            rules={[
              () => ({
                validator(_, value) {
                  if (value?.file?.name?.split('.')?.pop() !== 'zip') {
                    return Promise.reject(new Error('Only Zip file allowed'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Upload
              customRequest={customRequest}
              maxCount={1}
              openFileDialogOnClick={true}
              progress={false}
              name="file"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ImportChapter;
