import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';

export const SET_DICTIONARY_LOADING = 'SET_DICTIONARY_LOADING';
export const SET_DICTIONARY_DATA = 'SET_DICTIONARY_DATA';
export const SET_DROPDOWN_LOADING = 'SET_DROPDOWN_LOADING';
export const SET_DROPDOWN_LIST = 'SET_DROPDOWN_LIST';
export const SET_SAVE_LOADING = 'SET_SAVE_LOADING';

export const fetchDropDownData = () => (dispatch) => {
  dispatch({
    type: SET_DROPDOWN_LOADING,
    payload: true,
  });
  const order = ['version', 'language'];
  return Promise.all([
    Agent.Dictionary.getDictionaryVersion(),
    Agent.Dictionary.getDictionaryLanguage(),
  ]).then(
    (result) => {
      let dropdownData = {};
      result.map((res, key) => {
        if (res?.data?.status) {
          dropdownData[order[key]] = res?.data?.data;
          dispatch({
            type: SET_DROPDOWN_LIST,
            payload: dropdownData,
          });
        } else {
          Helper.toastAlert(res?.data?.message, 'error');
        }
      });
      dispatch({
        type: SET_DROPDOWN_LOADING,
        payload: false,
      });
    },
    () => {
      Helper.toastAlert('Error while fetching language and version', 'error');
      dispatch({
        type: SET_DROPDOWN_LOADING,
        payload: false,
      });
    }
  );
};

export const handleFetchDictionary = (param) => (dispatch) => {
  dispatch({
    type: SET_DICTIONARY_LOADING,
    payload: true,
  });
  Agent.Dictionary.getWord(param)
    .then(
      (result) => {
        dispatch({
          type: SET_DICTIONARY_DATA,
          payload: result.data.data,
        });
      },
      () => {
        Helper.toastAlert('Error while fetching students', 'error');
      }
    )
    .finally(() => {
      dispatch({
        type: SET_DICTIONARY_LOADING,
        payload: false,
      });
    });
};
export const handleFormSave = (param) => (dispatch) => {
  dispatch({
    type: SET_SAVE_LOADING,
    payload: true,
  });
  Agent.Dictionary.updateWord(param)
    .then(
      (result) => {
        if (result?.data?.status) {
          return result?.data?.data;
        } else {
          Helper.toastAlert(result?.data?.message, 'error');
        }
        return null;
      },
      () => {
        Helper.toastAlert('Error while saving record', 'error');
      }
    )
    .finally(() => {
      dispatch({
        type: SET_SAVE_LOADING,
        payload: false,
      });
    });
};
