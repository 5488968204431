import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';

export const SET_IMPORT_LOADING = 'SET_IMPORT_LOADING';

export const importChapter = (data) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_LOADING,
    payload: true,
  });
  return Agent.ChapterRoutes.importData(data).then(
    (res) => {
      dispatch({
        type: SET_IMPORT_LOADING,
        payload: false,
      });
      return res;
    },
    (err) => {
      dispatch({
        type: SET_IMPORT_LOADING,
        payload: false,
      });
      console.log(err?.response?.data?.msg);
      Helper.toastAlert('System Encountered Error', 'error');
    }
  );
};
