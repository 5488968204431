import SimpleReactValidator from "simple-react-validator";

var emailRegex = /^$|^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const _ = require('lodash');
const Validations = {

  alpha_names_space: {
    message: "The :attribute may only contain letters, numbers.",
    rule: function (val, options) {
      return /^[0-9a-zA-ZÀ-ÿ _]*$/i.test(val);
    }
  },
  opt_email: {
    message: "The :attribute must be valid email address",
    rule: function (val, options) {
      return emailRegex.test(val);
    }
  },
  videoValidation: {
    message: "The :attribute is Invalid, it must be a Valid type like mp4,mpeg",
    rule: function (val, options) {
      if (val.name != undefined) {
        return val.name.match(/\.(mp4|wmv|mpeg)$/) ? true : false
      } else {
        return false
      }
    }
  },
  imageValidation: {
    message: "The :attribute is Invalid, it must be a Valid image type",
    rule: function (val, options) {
      if (val.name != undefined) {
        var ext = val.name.split('.').pop();
        let extCheck = ['jpg', 'jpeg', 'gif', 'png']

        return extCheck.includes(_.toLower(ext))
      } else {
        return false
      }
    }
  },
  textValidation: {
    message: "The :attribute is Invalid, it must be a Valid image type",
    rule: function (val, options) {
      if (val.name != undefined) {
        var ext = val.name.split('.').pop();
        let extCheck = ['txt']

        return extCheck.includes(_.toLower(ext))
      } else {
        return false
      }
    }
  },
  audioValidation: {
    message: "The :attribute is Invalid, it must be a Valid audio like mp3,wav type",
    rule: function (val, options) {
      if (val.name != undefined) {
        var ext = val.name.split('.').pop();
        let extCheck = ['mp3', 'wav', 'wma']

        return extCheck.includes(_.toLower(ext))
      } else {
        return false
      }
    }
  },
  linkValidation: {
    message: "The :attribute is Invalid, it must be a Valid url like https://www.google.com/",
    rule: function (val, options) {

      try {
        new URL(val);
      } catch (err) {
        return false;
      }

      return true;

    }
  }


}

/**
 * This Function In-Herits SimpleReactValidator to expand its Functionality
 */
function customValidator() {
  var validator = new SimpleReactValidator({
    validators: {
      
      alpha_names_space: Validations.alpha_names_space,
      opt_email: Validations.opt_email,
      imageValidation: Validations.imageValidation,
      textValidation: Validations.textValidation,
      videoValidation: Validations.videoValidation,
      audioValidation: Validations.audioValidation,
      linkValidation: Validations.linkValidation,
    }
  });
  return validator;
}

export {
  customValidator,

}