import { Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';
const _ = require('lodash')
const { Option } = Select;
class StyleSelector extends Component {
  state = {
    value: (this.props.value) ? this.props.value : '',
  }
  onChangeHandler = (value) => this.setState({ value }, () => this.handleSave())


  handleSave = () => {
    let { metaId, chapterMeta, wordId } = this.props
    let data = {
      chapter_meta_id: metaId, style_id: this.state.value, word_id: wordId
    }
    const nextId = `style-select-${this.props.index + 1}`
    document.getElementById(nextId) && document.getElementById(nextId).focus()
      && document.getElementById(nextId).scrollIntoView({ behavior: "smooth", block: "center" });

    this.props.addStyle(data).then((res) => {
      if (res.data.status) {
        //update Store
        let updateData = _.map(chapterMeta, (o, k) => {
          return (o.id == metaId) ? Helper.chapterMetaFormat(res.data.data) : o
        })


        this.props.addChapterLoadData(updateData)
      } else {
        // Set Default value when api fail
        Helper.toastAlert("Error while saving style", 'error');
        this.setState({ value: this.props.value })
      }
    }).catch((err) => {
      // handle Error
      this.setState({ value: this.props.value })
      Helper.toastAlert(Helper.handleError(err), 'error');
    })
  }

  render() {
    return (
      <Select showSearch style={{ width: 170 }} id={`style-select-${this.props.index}`} disabled={this.props.disabled} value={this.state.value} onChange={this.onChangeHandler} disabled={this.props.disabled} filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
        <Select.Option value="">Select Style</Select.Option>
        {this.props.data.map((val) => <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>)}

      </Select>
    )

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta
  }
}
const mapDispatchToProps = {
  addStyle: (data) => dispatch => dispatch(Actions.chapterLoadAction.saveStyle(data)),
  addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}
export default connect(mapStateToProps, mapDispatchToProps)(StyleSelector)
