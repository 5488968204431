import Agent from '../../agent/Agent';

const add = (data, config) => () => {
  return Agent.ChapterRoutes.uploadChapter(data, config);
};
const update = (data, config) => () => {
  return Agent.ChapterRoutes.updateChapter(data, config);
};
const edit = (data) => () => {
  return Agent.ChapterRoutes.uploadChapter(data);
};
const fetch = (data) => () => {
  return Agent.ChapterRoutes.fetch(data);
};
const list = (data, config) => () => {
  return Agent.ChapterRoutes.getChapter(data, config);
};
const activeBoard = (data) => () => {
  return Agent.ChapterRoutes.getActiveBoard(data);
};
const activeClass = (data) => () => {
  return Agent.ChapterRoutes.getActiveClass(data);
};
const activeSubject = (data) => () => {
  return Agent.ChapterRoutes.getActiveSubject(data);
};
const activePrintLanguage = (data) => () => {
  return Agent.ChapterRoutes.getActivePrintLanguage(data);
};
const activeVersions = (data) => () => {
  return Agent.ChapterRoutes.getActiveVersions(data);
};
const importChapter = (data) => () => {
  return Agent.ChapterRoutes.importData(data);
};
const handleExport = (data) => () => {
  return Agent.ChapterRoutes.exportData(data);
};

const chapterAction = {
  add,
  edit,
  list,
  fetch,
  update,
  activePrintLanguage,
  activeBoard,
  activeClass,
  activeSubject,
  activeVersions,
  importChapter,
  handleExport,
};
export default chapterAction;
