import { PageHeader, Select, Space, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../Helper';
import Actions from '../../../redux/actions';
import { ActionConstant } from '../../../redux/actions/type';
import TableRender from './TableRender';

import Agent from '../../../agent/Agent';
import ExportChapter from '../ExportChapter';
import ImportChapter from '../ImportChapter';
import VoiceImport from '../VoiceImport';
import ChapterPreviewModel from './ChapterPreviewModel';
const mapStateToProps = (state, ownProps) => {

    return {
        chapterMeta: state.MainReducer.chapterMeta
    }
}
const mapDispatchToProps = {
    fetchData: (page, data, config) => dispatch => dispatch(Actions.chapterLoadAction.fetch(page, data, config)),
    fetchByPage: (page, data, config) => dispatch => dispatch(Actions.chapterLoadAction.fetchByPage(page, data, config)),
    fetchStyle: (data, config) => dispatch => dispatch(Actions.styleAction.fetch(data, config)),
    addChapterLoadData: (data) => dispatch => dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data })
}
class ChapterLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chapterId: this.props.match.params.id,
            version: this.props.match?.params?.version || '',
            chapter: null,
            versionList: [],
            data: [],
            styles: [],
            pagination: {
                current: 1,
                pageSize: 100,
                total: 0,
            },

            loading: true
        }
        this.formRef = React.createRef()
    }
    componentDidMount() {
        this.props.fetchStyle().then((res) => {
            if (res.data.status) {

                this.setState({ styles: res.data.data, })
            } else {
                Helper.toastAlert("Error while fetching style", 'error')
            }
            Agent.ChapterRoutes.getChapterVersions({ 'chapter_id': this.state.chapterId ? this.state.chapterId : 0 }).then((response) => {
                this.setState({
                    versionList: (response.data.data || []).map(item => ({ value: item.id, label: item.name })),
                    version: this.props.match.params.version
                })
                this.loadChapter();
            })
        }).catch((err) => {
            console.log(err)
            Helper.toastAlert("Error while fetching style", 'error')
        })

    }
    loadChapter = () => {
        this.setState({ loading: true });
        let data = { chapter_id: this.props.match.params.id, version: this.state.version, pageSize: this.state.perPage }
        this.props.fetchData(data, (p) => { console.log(p) }).then((res) => {
            const chapter = res.data.chapter
            const chapterDetail = res.data.chapter_detail
            let tableData = _.map(chapterDetail.data, (o) => Helper.chapterMetaFormat(o))
            this.setState({
                loading: false,
                data: tableData,
                pagination: {
                    current: chapterDetail.current_page,
                    pageSize: chapterDetail.per_page,
                    total: chapterDetail.total,
                },
                chapter: chapter


            })
            this.props.addChapterLoadData(tableData)
        }).catch((err) => {
            console.log(err)
            this.setState({ loading: false })
            Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        })
    }
    onPageChange = (pagination) => {
        let page = pagination.current
        let sizePerPage = pagination.pageSize
        this.setState({ loading: true, page, sizePerPage });
        let data = { chapter_id: this.props.match.params.id, version: this.state.version, sizePerPage }
        this.props.fetchByPage(page, data, (p) => { console.log(p) }).then((res) => {
            console.log(res.data)
            let tableData = _.map(res.data.data, (o) => Helper.chapterMetaFormat(o))
            this.setState({
                loading: false, data: tableData,

                pagination: {
                    current: res.data.current_page,
                    pageSize: res.data.per_page,
                    total: res.data.total,
                },


            })
            this.props.addChapterLoadData(tableData)
        }).catch((err) => {
            this.setState({ loading: false })
            Helper.toastAlert(Helper.alertMsg.SOMETHING_WORNG, 'error');
        })
    }

    handleVersionChange = (value) => {
        if (value) {
            this.setState({ version: value }, () => {
                this.loadChapter();
            });
            this.props.history.push("/chapter/" + this.state.chapterId + '/' + value + "/load");
        }
    }
    handleImport = (data) => {

        this.setState({ version: data.versionId }, () => {
            this.loadChapter();
        });
        this.props.history.push("/chapter/" + data.chapterId + '/' + data.versionId + "/load");

    }
    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header"
                        title={this.state.chapter?.name ? `Edit (${this.state.chapter?.name})` : 'Edit'}
                        ghost={false}
                        onBack={() => this.props.history.push('/chapter')}
                        // subTitle="This is a subtitle"
                        extra={[

                            <Space>
                                <ChapterPreviewModel chapterId={this.state.chapterId} versionId={this.state.version} chapterData={this.state.chapter}/>
                                <VoiceImport onImport={this.handleImport} versionList={this.state.versionList} chapterId={this.state.chapterId} versionId={this.state.version} chapterData={this.state.chapter} />
                                <ImportChapter onImport={this.handleImport} versionList={this.state.versionList} chapterId={this.state.chapterId} chapterData={this.state.chapter} />
                                <ExportChapter onImport={this.handleImport} versionList={this.state.versionList} chapterId={this.state.chapterId} chapterData={this.state.chapter} />
                                <Select options={this.state.versionList} placeholder="Select Version" style={{ width: 150 }} value={Number(this.state.version)} onChange={this.handleVersionChange} />
                            </Space>
                        ]}
                    />


                    <TableRender
                        data={this.props.chapterMeta}
                        styles={this.state.styles}

                        fetchApi={this.loadChapter}
                        page={this.state.page}
                        sizePerPage={this.state.perPage}
                        // page={this.state.page}
                        pagination={this.state.pagination}
                        total={this.state.totalSize}
                        onPageChange={this.onPageChange}
                    />


                </Spin >
            </>
        )

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterLoad)