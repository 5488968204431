import Agent from "../../agent/Agent";
const fetchByChapter = (data) => dispatch => {
  return Agent.Board.getChapterBoard(data)
}
const fetch = (data) => dispatch => {
  return Agent.Board.getBoard(data)
}
const add = (data) => dispatch => {
  return Agent.Board.addBoard(data)
}
const update = (data) => dispatch => {
  return Agent.Board.editBoard(data)
}
const deleteBoard = (data) => dispatch => {
  return Agent.Board.deleteBoard(data)
}
const activeBoard = (data) => dispatch => {
  return Agent.Board.getActiveBoard(data)
}
const chapterBoard = (data) => dispatch => {
  return Agent.Board.getChapterBoard(data)
}


const boardAction = {
  fetchByChapter, fetch, deleteBoard, add, update, chapterBoard, activeBoard
}
export default boardAction