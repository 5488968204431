import { Button, Col, Form, Modal, Select } from 'antd';
import React, { useCallback } from 'react';

import { useState } from 'react';

function ExportChapter({ chapterId, exportChapter, versionList, chapterData }) {
  const uuid = chapterData?.uuid || '';
  const chapterName = chapterData?.name || '';
  const [open, setOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const handleExport = useCallback(async () => {
    try {
      form.validateFields().then(async (values) => {
        setButtonLoading(true);
        const data = {
          version: values.version,
          chapter_id: chapterId,
          chapter_name: chapterName,
          uuid: uuid,
        };
        try {
          const res = await exportChapter(data);
          setButtonLoading(false);
          if (res?.status) {
            setOpen(false);
            form.resetFields();
          }
        } catch (error) {
          setButtonLoading(false);
        }
      });
    } catch {
      setButtonLoading(false);
    }
  }, [chapterId, chapterName]);

  return (
    <>
      <Col span={12}>
        <Button onClick={() => setOpen(true)} size="medium" type="primary">
          Export
        </Button>
      </Col>

      <Modal
        title={'Export Chapter ' + chapterName}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleExport}
        okText="Export"
        okButtonProps={{
          loading: buttonLoading,
        }}
        style={{ top: 20 }}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          name="importData"
        >
          <Form.Item
            name={'version'}
            label="Select IVL"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 150 }}
              placeholder="Select IVL"
              options={versionList}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ExportChapter;
