import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Spin, Switch, Typography } from 'antd';
import _ from "lodash";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import Helper from "../../../Helper";
import Agent from "../../../agent/Agent";
import BreadcrumbBar from "../../includes/BreadCrumb/BreadcrumbBar";
class AddStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSize: 40,
      displayColorPicker1: false,
      color1: {
        r: 74,
        g: 144,
        b: 226,
        a: 1,
      },
      colorHax1: "",
      displayColorPicker2: false,
      displayColorPicker3: false,
      color2: {
        r: 226,
        g: 74,
        b: 124,
        a: 1,
      },
      color3: {
        r: 74,
        g: 144,
        b: 226,
        a: 1,
      },
      color4: {
        r: 74,
        g: 144,
        b: 226,
        a: 1,
      },
      colorHax2: "",
      colorHax3: "",
      blur: 5,
      xValue: 1,
      yValue: 2,
      styleName: "style",
      outlineWidth: 0.5,
      style_id: 0,
      exists_style: [],
      bold: false,
      italic: false,
      underline: false,
      strikeThrough: false,
      chapterList: [],
      chapter_id: 0,
      chapterBackground: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      font: "times-new-roman",
      isDataFetch: false,
      pageLoading: false,
      isDefault:false
    };
    this.formRef = React.createRef()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id != undefined) {
      this.setState({ style_id: nextProps.match.params.id });
    }
  }
  fetchChapters = () => {
    Agent.ChapterRoutes.getChapter()
      .then((response) => {
        if (response.data.status == true)
          this.setState({ chapterList: response.data.data });
        else Helper.toastAlert("Error while fetching data", "error");
      })
      .catch((err) => {
        console.log(err);
        Helper.toastAlert("Error while fetching data", "error");
      });
  };
  styleFetch = () => {
    Agent.StyleRoute.getStyle()
      .then((res) => {
        if (res.data.status) {
          this.setState({ exists_style: res.data.data, isDataFetch: true });
        } else {
          Helper.toastAlert("Error while fetching style", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        Helper.toastAlert("Error while fetching style", "error");
      });
  };
  componentDidMount() {
    this.fetchChapters();
    this.styleFetch();
    if (this.props.match.params.id != undefined) {
      this.setState({ style_id: this.props.match.params.id, pageLoading: true });

      Agent.StyleRoute.getStyleById(this.props.match.params.id)
        .then((res) => {
          if (res.data.status) {
            let style = res.data.data.style;
            this.setState({
              font: style.fontFamily,
              color1: style.color,
              color2: style.shadowColor,
              blur: style.blur,
              xValue: style.xValue,
              yValue: style.yValue,
              outlineWidth: style.outlineWidth,
              styleName: res.data.data.name,
              isDefault: res.data.data.is_default,
              color3: style.outlineColor,
              bold: style.bold,
              italic: style.italic,
              underline: style.underline,
              strikeThrough: style.strikeThrough,
              fontSize: style.fontSize,
              isDataFetch: true,
              pageLoading: false
            });
            this.formRef && this.formRef.current.setFieldsValue({
              font: style.fontFamily,
              color1: style.color,
              color2: style.shadowColor,
              blur: style.blur,
              xValue: style.xValue,
              yValue: style.yValue,
              outline_width: style.outlineWidth,
              styleName: res.data.data.name,
              color3: style.outlineColor,
              bold: style.bold,
              italic: style.italic,
              underline: style.underline,
              strikeThrough: style.strikeThrough,
              fontSize: style.fontSize,
              isDefault: res.data.data.is_default,
            })
          } else {
            Helper.toastAlert("Error while fetching data", "error");
            this.setState({ isDataFetch: true, pageLoading: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isDataFetch: true, pageLoading: false });
          Helper.toastAlert("Error while fetching data", "error");
        });
    }
  }
  handleClick1 = () => {
    this.setState({ displayColorPicker1: !this.state.displayColorPicker1 });
  };

  handleClose1 = () => {
    this.setState({ displayColorPicker1: false });
  };

  handleChange1 = (color) => {
    this.setState({ color1: color.rgb });
  };
  handleClick2 = () => {
    this.setState({ displayColorPicker2: !this.state.displayColorPicker2 });
  };

  handleClose2 = () => {
    this.setState({ displayColorPicker2: false });
  };

  handleChange2 = (color) => {
    this.setState({ color2: color.rgb });
  };
  handleClick3 = () => {
    this.setState({ displayColorPicker3: !this.state.displayColorPicker3 });
  };

  handleClose3 = () => {
    this.setState({ displayColorPicker3: false });
  };

  handleChange3 = (color) => {
    this.setState({ color3: color.rgb });
  };
  handleChange = (value, name) => {
    console.log(value, name)
    this.setState({ [name]: value });
  };
  handleSubmit = (o) => {
    var submitValue = {
      style_id: this.state.style_id,
      name: this.state.styleName,
      color: this.state.color1,
      shadowColor: this.state.color2,
      outlineColor: this.state.color3,
      outlineWidth: this.state.outlineWidth,
      xValue: this.state.xValue,
      yValue: this.state.yValue,
      blur: this.state.blur,
      font: this.state.font,
      fontSize: this.state.fontSize,
      bold: this.state.bold ? 1 : 0,
      italic: this.state.italic ? 1 : 0,
      underline: this.state.underline ? 1 : 0,
      strikeThrough: this.state.strikeThrough ? 1 : 0,
      isDefault:o.isDefault?1:0
    };
    Agent.StyleRoute.saveStyle(submitValue)
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          this.props.history.push("/style");
        } else {
          Helper.toastAlert("Error while saving data", "error");
        }
      })
      .catch((err) => {
        console.log(err);
        Helper.handleError(err, "error");
      });
  };
  styleChange = (value) => {
    let id = value;
    let seletedStyle = _.first(
      _.filter(this.state.exists_style, (o) => o.id == id)
    );

    if (seletedStyle) {
      let style = seletedStyle.style;
      this.setState({
        font: style.fontFamily,
        color1: style.color,
        color2: style.shadowColor,
        blur: style.blur,
        xValue: style.xValue,
        yValue: style.yValue,
        outline_width: style.outlineWidth,
        color3: style.outlineColor,
        bold: style.bold,
        italic: style.italic,
        underline: style.underline,
        strikeThrough: style.strikeThrough,
        fontSize: style.fontSize,
      });
      this.formRef && this.formRef.current.setFieldsValue({
        font: style.fontFamily,
        color1: style.color,
        color2: style.shadowColor,
        blur: style.blur,
        xValue: style.xValue,
        yValue: style.yValue,
        outline_width: style.outlineWidth,
        color3: style.outlineColor,
        bold: style.bold,
        italic: style.italic,
        underline: style.underline,
        strikeThrough: style.strikeThrough,
        fontSize: style.fontSize,
      })
    }
  };
  setStyle = (type) => {
    this.setState(
      {
        [type]: !this.state[type],
      },
      () => {
        console.log(type, !this.state[type], this.state.strikeThrough);
      }
    );
  };
  chapterChange = (event) => {
    if (event.target.value > 0) {
      let chapterData = _.first(
        _.filter(this.state.chapterList, (o) => o.id == event.target.value)
      );
      this.setState({
        chapter_id: event.target.value,
        chapterBackground: JSON.parse(chapterData.background).color,
      });
    } else {
      this.setState({
        chapter_id: 0,
        chapterBackground: { r: 255, g: 255, b: 255, a: 1 },
      });
    }
  };
  componentDidCatch(error, info) {
    console.log(error, info);
  }
  render() {
    const styles = reactCSS({
      default: {
        color1: {
          width: "50px",

          borderRadius: "2px",
          height: "16px",

          borderRadius: "2px",
          background: `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})`,
        },
        color2: {
          height: "16px",
          width: "50px",
          borderRadius: "2px",
          background: `rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color2.a})`,
        },
        color3: {
          height: "16px",
          width: "50px",
          borderRadius: "2px",
          background: `rgba(${this.state.color3.r}, ${this.state.color3.g}, ${this.state.color3.b}, ${this.state.color3.a})`,
        },
        swatch: {
          padding: "5px",
          width: "-webkit-fill-available",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    let textDecoration = "none";
    if (this.state.underline && this.state.strikeThrough) {
      textDecoration = `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a}) underline line-through`;
    } else if (this.state.underline && !this.state.strikeThrough) {
      textDecoration = `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a}) underline`;
    } else if (!this.state.underline && this.state.strikeThrough) {
      textDecoration = `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a}) line-through`;
    }
    var previewStyle = {
      color: `rgba(${this.state.color1.r}, ${this.state.color1.g}, ${this.state.color1.b}, ${this.state.color1.a})`,
      textShadow: `${this.state.xValue}px ${this.state.yValue}px ${this.state.blur}px rgba(${this.state.color2.r}, ${this.state.color2.g}, ${this.state.color2.b}, ${this.state.color1.a})`,
      WebkitTextStroke: `${this.state.outlineWidth}px rgba(${this.state.color3.r}, ${this.state.color3.g}, ${this.state.color3.b}, ${this.state.color3.a})`,
      fontWeight: this.state.bold ? "bold" : "normal",
      fontStyle: this.state.italic ? "italic" : "normal",
      fontSize: `${this.state.fontSize}px`,
      fontFamily: `${this.state.font}`,
      textDecoration: textDecoration,
    };
    var chapterList = this.state.chapterList.map((val, key) => {
      return (
        <option key={key} data-order={key} value={val.id}>
          {val.name}
        </option>
      );
    });
    var styleList = this.state.exists_style.map((val, key) => {
      return (
        <Select.Option key={key} value={val.id}>
          {val.name}
        </Select.Option>
      );
    });
    var background = {
      backgroundColor: `rgba(${this.state.chapterBackground.r}, ${this.state.chapterBackground.g}, ${this.state.chapterBackground.b}, ${this.state.chapterBackground.a})`,
    };
    return (
      <>

        <BreadcrumbBar pageTitle={[{ link: "/style", name: "Styles" }, { link: '', name: "Add Style" }]} />
        <div className="from-wrapper">
          <Spin spinning={this.state.pageLoading}>
            <Form
              ref={this.formRef}
              defaultValue={{
                fontSize: this.state.fontSize,
                font: this.state.font
              }}
              layout="inline" name="tooltip" onFinish={this.handleSubmit} >
              <Row gutter={[24, 24]} >
                <Col span={{ lg: 8, sm: 12 }} >
                  <Form.Item name={'styleName'} label="Name" rules={[{ required: true }]}>
                    <Input placeholder="Enter Style name" value={this.state.styleName} onChange={(e) => this.handleChange(e.target.value, 'styleName')} />
                  </Form.Item>
                  
                </Col>
                <Col span={{ lg: 8, sm: 12 }} >
                  <Form.Item name={'based_style'} label="Based on" >
                    <Select placeholder="Select Style" onChange={this.styleChange}>
                      <Select.Option value="">Select</Select.Option>
                      {styleList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }} >

                  <Form.Item label="Color" >
                    <div style={styles.swatch} onClick={this.handleClick1}>
                      <div style={styles.color1} />
                    </div>
                    {this.state.displayColorPicker1 ? (
                      <div style={styles.popover}>
                        <div
                          style={styles.cover}
                          onClick={this.handleClose1}
                        />
                        <SketchPicker
                          color={this.state.color1}
                          onChange={this.handleChange1}
                        />
                      </div>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }} >
                  <Form.Item label="Shadow Color" >
                    <div style={styles.swatch} onClick={this.handleClick2}>
                      <div style={styles.color2} />
                    </div>
                    {this.state.displayColorPicker2 ? (
                      <div style={styles.popover}>
                        <div
                          style={styles.cover}
                          onClick={this.handleClose2}
                        />
                        <SketchPicker
                          color={this.state.color2}
                          onChange={this.handleChange2}
                        />
                      </div>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={'xValue'} label="X value" >
                    <InputNumber defaultValue={this.state.xValue} onChange={(value) => this.handleChange(value, 'xValue')} max={80} />
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>

                  <Form.Item name={'yValue'} label="Y value" >
                    <InputNumber defaultValue={this.state.yValue} onChange={(value) => this.handleChange(value, 'yValue')} max={80} />
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>

                  <Form.Item name={'blur'} label="Blur" >
                    <InputNumber defaultValue={this.state.blur} onChange={(value) => this.handleChange(value, 'blur')} max={80} />
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={'outline'} label="Outline" >
                    <div style={styles.swatch} onClick={this.handleClick3}>
                      <div style={styles.color3} />
                    </div>
                    {this.state.displayColorPicker3 ? (
                      <div style={styles.popover}>
                        <div
                          style={styles.cover}
                          onClick={this.handleClose3}
                        />
                        <SketchPicker
                          color={this.state.color3}
                          onChange={this.handleChange3}
                        />
                      </div>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={'outline_width'} label="Outline Width" >
                    <InputNumber step={0.1} stringMode defaultValue={this.state.outlineWidth} onChange={(value) => this.handleChange(value, 'outlineWidth')} max={80} />
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={['font']} label="Font" >
                    <Select value={this.state.font} onChange={(value) => this.handleChange(value, 'font')} placeholder="Select font">
                      <Select.Option value="mangal">Mangal</Select.Option>
                      <Select.Option value="sakal-bharati">Sakal bharati</Select.Option>
                      <Select.Option value="wingdings">Wingdings</Select.Option>
                      <Select.Option value="guru">Guru</Select.Option>
                      <Select.Option value="tempas-sans">Tempas sans</Select.Option>
                      <Select.Option value="courier-new">Courier new</Select.Option>
                      <Select.Option value="comic-sans-msc">Comic sans ms</Select.Option>
                      <Select.Option value="eras-contour">Eras Contour</Select.Option>
                      <Select.Option value="eras-demi-itc">Eras Demi Itc</Select.Option>
                      <Select.Option value="eurostile-black">
                        Eurostile-Black
                      </Select.Option>
                      <Select.Option value="flora-bold">Flora-Bold</Select.Option>
                      <Select.Option value="imerium-bold">Imperium Bold</Select.Option>
                      <Select.Option value="imerium-hollow">
                        Imperium Hollow
                      </Select.Option>
                      <Select.Option value="rage-italic">Rage Italic</Select.Option>
                      <Select.Option value="times-new-roman">
                        Times New Roman
                      </Select.Option>

                    </Select>
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={'font_size'} label="Font size" >
                    <InputNumber defaultValue={this.state.fontSize} onChange={(value) => this.handleChange(value, 'fontSize')} max={80} />
                  </Form.Item>
                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item name={['fontStyle']} label="Font Style" >
                    <Button type={(this.state.bold) ? 'primary' : ''} onClick={() => this.setStyle('bold')} icon={<BoldOutlined />} />
                    <Button type={(this.state.italic) ? 'primary' : ''} onClick={() => this.setStyle('italic')} icon={<ItalicOutlined />} />
                    <Button type={(this.state.underline) ? 'primary' : ''} onClick={() => this.setStyle('underline')} icon={<UnderlineOutlined />} />
                    <Button type={(this.state.strikeThrough) ? 'primary' : ''} onClick={() => this.setStyle('strikeThrough')} icon={<StrikethroughOutlined />} />
                  </Form.Item>

                </Col>
                <Col span={{ lg: 8, sm: 12 }}>
                <Form.Item name={'isDefault'} label="Is Default" valuePropName="checked" >
                    <Switch disabled />
                  </Form.Item>

                </Col>

                <Col span={{ lg: 8, sm: 12 }}>
                  <Form.Item >
                    <Button loading={this.state.buttonLoading} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        <div className="from-wrapper">
          <Typography.Title style={previewStyle}>
            A Quick brown fox jumps over the lazy dog .
          </Typography.Title>
        </div>
      </>
    )
  }
}
export default AddStyle;
