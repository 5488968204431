import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Switch, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useEffect, useMemo, useState } from 'react';

import 'react-confirm-alert/src/react-confirm-alert.css';
import UploadProfile from '../../UI/UploadProfile/UploadProfile';
import BreadcrumbBar from '../../includes/BreadCrumb/BreadcrumbBar';

import StudentFilter from './StudentFilter';

const _ = require('lodash');

function Students({
  fetchAllSubject,
  studentList,
  fetchStudents,
  loading,
  subjectDropDownLoading,
  handleFetchSubject,
  fetchDropDownData,
  dropDownData,
  addStudent,
  deleteStudent,
  editStudent,
  updateStatus,
  dropDownLoading,
  history,
}) {
  const [model, setModel] = useState({ visibility: false, row: null });
  const [form] = Form.useForm();

  useEffect(() => {
    fetchStudents(null);
  }, []);
  const addNewStudent = () => {
    history.push('student/new');
  };
  const hidePopup = () => {
    setModel({ visibility: false, id: 0 });
  };

  const handleFetchWithFilter = () => {
    const filterValues = form.getFieldsValue();
    let formData = {};
    Object.keys(filterValues).map((key) => {
      if (filterValues[key]) {
        formData = {
          ...formData,
          [key]: filterValues[key],
        };
      }
    });
    fetchStudents(formData);
  };
  const handleBlur = async (e, value) => {
    handleFetchWithFilter();
  };
  const onChange = async (row) => {
    await updateStatus({ id: row.id, status: row.status ? 0 : 1 });
    handleFetchWithFilter();
  };
  const showDeleteConfirm = (row) => {
    confirm({
      title: `Are you sure delete ${row.first_name + ' ' + row.last_name}?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteStudent(row.id);
        return handleFetchWithFilter();
      },
    });
  };
  const viewSubject = (row) => {
    const subjects = row?.with_subject?.map((sub) =>
      sub?.subject?.code ? (
        <div style={{ marginBottom: 10 }} key={sub?.subject?.id}>
          {sub?.subject?.code + '-' + sub?.subject?.name}
        </div>
      ) : (
        <div style={{ marginBottom: 10 }} key={sub?.subject?.id}>
          {sub?.subject?.name}
        </div>
      )
    );
    Modal.info({
      title: 'Subject list',
      content: <>{subjects}</>,
    });
  };

  const columns = useMemo(
    () => [
      {
        title: 'Sr.No.',
        width: 100,
        dataIndex: '',
        key: 'id',
        render: (text, row, index) => ++index,
      },
      {
        title: 'First Name',
        width: 100,
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: (a, b) => a.first_name - b.first_name,
      },
      {
        title: 'Middle Name',
        width: 100,
        dataIndex: 'middle_name',
        key: 'middle_name',
        render: (text, row, index) => <span>{row?.middle_name || '-'}</span>,
        sorter: (a, b) => a.middle_name - b.middle_name,
      },
      {
        title: 'Last Name',
        width: 100,
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: (a, b) => a.last_name - b.last_name,
      },
      {
        title: 'DOB',
        width: 100,
        dataIndex: 'dob',
        key: 'dob',
        sorter: (a, b) => a.dob - b.dob,
      },
      {
        title: 'Parents Name',
        width: 100,
        dataIndex: 'parents_name',
        key: 'parents_name',
        sorter: (a, b) => a.parents_name - b.parents_name,
      },
      { title: 'Email', width: 150, dataIndex: 'email', key: 'email' },
      { title: 'Category', width: 150, dataIndex: 'category', key: 'category' },
      {
        title: 'Session',
        width: 150,
        dataIndex: 'session_start',
        key: 'session_start',
        render: (text, row, index) => (
          <span>{`(${row?.session_start}) - (${row?.session_end})`}</span>
        ),
      },
      {
        title: 'Class',
        width: 100,
        dataIndex: 'class_id',
        key: 'class_id',
        render: (text, row, index) => <span>{row?.class?.name || ''}</span>,
      },
      {
        title: 'Subjects',
        width: 120,
        dataIndex: 'subject',
        key: 'subject',
        render: (text, row, index) => (
          <Button size="small" type="link" onClick={() => viewSubject(row)}>
            View Subjects
          </Button>
        ),
      },
      {
        title: 'Board',
        width: 100,
        dataIndex: 'board_id',
        key: 'board_id',
        render: (text, row, index) => <span>{row?.board?.name || ''}</span>,
      },
      {
        title: 'Course',
        width: 100,
        dataIndex: 'course_id',
        key: 'course_id',
        render: (text, row, index) => <span>{row?.course?.name || ''}</span>,
      },
      {
        title: 'Address',
        width: 200,
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => a.address - b.address,
      },
      {
        title: 'City',
        width: 100,
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => a.city - b.city,
      },
      {
        title: 'State',
        width: 100,
        dataIndex: 'state',
        key: 'state',
        render: (text, row, index) => <span>{row?.board?.name || ''}</span>,
      },
      { title: 'Country', width: 100, dataIndex: 'country', key: 'country' },
      { title: 'ZipCode', width: 100, dataIndex: 'zip_code', key: 'zip_code' },
      {
        title: 'Profile Photo',
        width: 100,
        dataIndex: '',
        key: 'profile_pic',
        render: (text, row, index) => (
          <UploadProfile url={row.profile_url} id={row.id} name={row.name} />
        ),
      },
      {
        title: 'Status',
        width: 80,
        dataIndex: '',
        key: 'status',
        render: (text, row, index) => (
          <Switch
            size="small"
            checked={!!row.status}
            onChange={() => onChange(row)}
          />
        ),
      },
      {
        title: 'Edit',
        width: 80,
        dataIndex: '',
        key: 'edit',
        render: (text, row, index) => (
          <Button
            size="small"
            onClick={() => history.push('/student/' + row.id + '/edit')}
            className="text-info"
          >
            <EditOutlined />
          </Button>
        ),
      },
      {
        title: 'Delete',
        width: 80,
        dataIndex: 'id',
        key: 'Delete',
        render: (text, row, index) => (
          <Button
            danger
            size="small"
            onClick={() => showDeleteConfirm(row)}
            className="text-info"
          >
            <DeleteOutlined />
          </Button>
        ),
      },
    ],
    []
  );
  const filterForm = useMemo(() => {
    return (
      <StudentFilter
        handleBlur={handleBlur}
        form={form}
        dropDownLoading={dropDownLoading}
        dropDownData={dropDownData}
      />
    );
  }, [dropDownLoading, dropDownData, form]);
  return (
    <>
      <BreadcrumbBar pageTitle="Students" />
      <Row align="middle" className="site-page-header ant-page-header">
        <Col span={24} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Button size="medium" onClick={addNewStudent} key="1" type="primary">
            Add new Student
          </Button>
        </Col>
        <Col span={24}>{filterForm}</Col>
      </Row>

      <Table
        className="build-table"
        scroll={{ x: '100vh' }}
        loading={loading}
        sticky
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={studentList}
        size="large"
      />
    </>
  );
}

export default Students;
