import React, { Component } from "react";
// import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import "react-bootstrap-table/css/react-bootstrap-table.css";
import { AudioOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Image, Modal, Table } from 'antd';
import { connect } from "react-redux";
import Agent from "../../../agent/Agent";
import AddNewFrom from "./AddNewFrom";
import DefinitionRenderer from "./DefinitionRenderer";
import HindiWordRenderer from "./HindiWordRenderer";
import PreviewModel from "./PreviewModel";
import RecordingAction from "./RecordingAction";
import StyleSelector from "./StyleSelector";
import TableAction from "./TableAction";
import VoiceRecordingRenderer from "./VoiceRecordingRenderer";
import WordEdit from "./WordEdit";
import WordOrderSave from "./WordOrderSave";
import './table.css';

const _ = require("lodash");
const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta,
  };
};


const mapDispatchToProps = {};
const EditableCell = (props) => {

  return (
    <td {...props}>
      {props.children}

    </td>
  );
};
class TableRender extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showRecorder: false,
      currentRecordingRow: "",
      showModel: false,
      modelPath: null,
      modelType: "image",
      columnCheckBox: {
        word: true,
        style: false,
        meaning: false,
        wordOrder: false
      },
      currentRecordingIndex: 0

    };
    this.tableRef = React.createRef()
  }

  openModel = (path, type) =>
    this.setState({ showModel: true, modelType: type, modelPath: path });
  closeModel = () =>
    this.setState({ showModel: false, modelType: "image", modelPath: null });
  renderElement = (cell, row) => {
    if (row.media_id) {
      let path = Agent.STORAGE_ROOT + row.media.path;
      if (row.media.type === "image") {
        return <Image
          width={100}
          src={path}
        />
      } else if (row.media.type === "video") {
        return (

          <div
            style={{ cursor: "pointer" }}
            onClick={() => this.openModel(path, "video")}
            className="btn btn-link  waves-effect waves-light"
          >
            <i
              style={{ fontSize: "48px", color: "Tomato" }}
              className=" fa  fa-file-video-o "
            ></i>
          </div>
        );
      } else if (row.media.type === "link") {
        return (
          <a
            href={row.media.path}
            target="_blank"

          >
            {row.media.link_name}
          </a>
        );
      } else {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => this.openModel(path, "audio")}
            className="btn btn-link  waves-effect waves-light"
          >
            <i
              style={{ fontSize: "48px", color: "gray" }}
              className=" fa fa-file-audio-o"
            ></i>
          </div>
        );
      }
    }
    return <WordEdit currentId={row.id} isCapital={row.is_capital} value={row.word} />;
  };
  expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
    let content = "";
    if (isExpandableRow) {
      content = isExpanded ? "(-)" : "(+)";
    } else {
      content = " ";
    }
    return <div> {content} </div>;
  };
  isExpandableRow = (cell, row) => {
    return true;
  };
  expandComponent = (row) => {
    return <AddNewFrom metaId={row.id} fetchApi={this.props.fetchApi} />;
  };
  renderStyle = (cell, row, index, columnCheckBox) => {
    let value = row.styleId;

    return <StyleSelector index={index} disabled={columnCheckBox['style']} data={this.props.styles} value={value} chapterId={row.chapterId} metaId={row.id} wordId={row.word_id} />;
  };
  /**
   * @description renderHindi word input box
   *
   * @memberof ChapterLoad
   */
  renderHindiWord = (cell, row, index, columnCheckBox) => {
    let value = row.hindiWord;
    return (
      <HindiWordRenderer
        value={value}
        definitionWord={row?.definitionWord || ''}
        index={index}
        styleId={row.styleId}
        wordId={row.word_id}
        id={row.hindi_word_id}
        metaId={row.id}
        disable={row.media_id || columnCheckBox['meaning'] ? true : false}
      />
    );
  };
  /**
   * @description renderDefinition word input box
   *
   * @memberof ChapterLoad
   */
  renderDefinition = (cell, row, index, columnCheckBox) => {
    let value = row.definitionWord;
    return (
      <DefinitionRenderer
        value={value}
        index={index}
        styleId={row.styleId}
        wordId={row.word_id}
        id={row.definition_word_id}
        metaId={row.id}
        disable={row.media_id || columnCheckBox['definition'] ? true : false}
      />
    );
  };
  /**
   * @description render word order input box
   *
   * @memberof ChapterLoad
   */
  renderWordOrder = (cell, row, index, columnCheckBox) => {
    let value = row.wordOrder;

    return (
      <WordOrderSave disableWordOrder={this.disableWordOrder} index={index} disabled={columnCheckBox['wordOrder']} value={value} chapterId={row.chapterId} metaId={row.id} />
    );
  };

  showVoiceRecoder = (currentRecordingRow, index) => {
    this.setState({ showRecorder: true, currentRecordingRow, currentRecordingIndex: index });
  };
  hideRecorder = () => {
    this.setState({ showRecorder: false, currentRecordingId: "" });
  };

  voiceRecordingRenderer = (cell, row, index, columnCheckBox) => {
    let value = row.voiceClip ? row.voiceClip : null;
    if (value) {
      return (
        <RecordingAction
          tabIndex={columnCheckBox['sound'] ? '-1' : ""}
          currentId={row.id}
          disabled={columnCheckBox['sound']}
          voiceClip={Agent.STORAGE_ROOT + row.voiceClip}
        />
      );
    }
    return (
      <Button
        icon={< AudioOutlined />}
        tabIndex={columnCheckBox['sound'] ? '-1' : ""}
        disabled={columnCheckBox['sound']}
        type="primary"
        id={`btn-record-${index}`}
        className={`focusElement`}
        onClick={() => this.showVoiceRecoder(row, index)}
      />


    );
    // <button onClick={this.startRecording} className="btn btn-link" type="button"></button>
  };
  actionRender = (cell, row) => {
    return <TableAction metaId={row.id} row={row} />;
  };
  /*
   * render total record count
   */
  renderShowsTotal = () => {
    return <span>Total {this.props.total}</span>;
  };
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(state => {
      const rows = state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    });
  };
  renderColumnHeader = (title, key) => {
    return <Checkbox name={key} checked={this.state.columnCheckBox[key]} onChange={this.handleCheckboxChange}>{title}</Checkbox>;
  }
  handleCheckboxChange = (e) => {
    this.setState({
      columnCheckBox: {
        ...this.state.columnCheckBox,
        [e.target.name]: e.target.checked
      }
    })
  }
  disableWordOrder = (status) => {
    this.setState({
      columnCheckBox: {
        ...this.state.columnCheckBox,
        ['wordOrder']: status
      }
    })
  }
  render() {
    const columns = [
      {
        title: 'Sr.No.', width: 80, dataIndex: 'srNo', key: 'count', render: (text, row, index) => {
          return ++index
        },
      },
      { title: "Word", dataIndex: 'word', key: 'word', width: 130, render: this.renderElement, onCell: (record, rowKey) => ({ ...record, rowKey: rowKey }) },

      { title: () => this.renderColumnHeader('Style', 'style'), width: 200, dataIndex: 'style', key: 'style', render: (cell, row, index) => this.renderStyle(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowkey: rowKey }) },
      { title: () => this.renderColumnHeader('Meaning', 'meaning'), dataIndex: 'meaning', key: 'meaning', render: (cell, row, index) => this.renderHindiWord(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowkey: rowKey }) },
      { title: () => this.renderColumnHeader('Definition', 'definition'), dataIndex: 'definition', key: 'definition', render: (cell, row, index) => this.renderDefinition(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowkey: rowKey }) },
      {
        title: () => this.renderColumnHeader('Sound', 'sound'), width: 80, dataIndex: 'voiceClip', key: 'voiceClip', render: (cell, row, index) => this.voiceRecordingRenderer(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowkey: rowKey })
      },
      { title: () => this.renderColumnHeader('Word Order', 'wordOrder'), width: 130, dataIndex: 'wordOrder', key: 'wordOrder', render: (cell, row, index) => this.renderWordOrder(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowkey: rowKey }) },
      {
        title: 'Action', dataIndex: 'background', key: 'background', width: 100, render: (cell, row, index) => this.actionRender(cell, row, index, this.state.columnCheckBox), onCell: (record, rowKey) => ({ ...record, rowKey: rowKey })
      },


    ];
    // const columns = [
    //   { key: "id", name: "ID", editable: true,editor:this.renderStyle },
    //   { key: "title", name: "Title", editable: true },
    //   { key: "complete", name: "Complete", editable: true }
    // ];


    return (
      <>
        <Table
          className="build-table"
          expandable={{
            expandedRowRender: this.expandComponent,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleTwoTone tabIndex="-1" onClick={e => onExpand(record, e)} />
              ) : (
                <PlusCircleTwoTone tabIndex="-1" onClick={e => onExpand(record, e)} />
              )
          }}

          // scroll={{ x: 1500 }}
          scroll={{}}
          pagination={this.props.pagination}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          ref={this.tableRef}
          scroll={{ x: 1100, y: 500 }}
          bordered
          size="small"
          dataSource={this.props.data}
          onChange={this.props.onPageChange}
          columns={columns} />

        <Modal
          title={`${this.state.currentRecordingRow && this.state.currentRecordingRow.word}`}
          style={{ left: 300, top: 100 }}
          destroyOnClose
          centered={false}
          onCancel={this.hideRecorder}
          visible={this.state.showRecorder}
          footer={null}
          mask={false}
          width={195}
        >
          <VoiceRecordingRenderer
            currentRecordingRow={this.state.currentRecordingRow}
            currentRecordingIndex={this.state.currentRecordingIndex}
            hideRecorder={this.hideRecorder}
          />
        </Modal>
        <PreviewModel showModel={this.state.showModel} onClose={this.closeModel} type={this.state.modelType} path={this.state.modelPath} />
      </>
    )

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TableRender);
