import { connect } from 'react-redux';

import voiceRecordingAction from './voice.action';
import VoiceImport from './VoiceImport';

const mapStateToProps = (state) => {
  return {
    importLoading: state.voiceReducer.importLoading,
  };
};
const mapDispatchToProps = {
  importRecording: (data) => (dispatch) =>
    dispatch(voiceRecordingAction.importRecording(data)),
};
export default connect(mapStateToProps, mapDispatchToProps)(VoiceImport);
