import { connect } from 'react-redux';

import ExportChapter from './ExportChapter';
import exportAction from './export.action';

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  exportChapter: (data) => (dispatch) =>
    dispatch(exportAction.exportChapter(data)),
};
export default connect(mapStateToProps, mapDispatchToProps)(ExportChapter);
