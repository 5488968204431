import { Input } from "antd";
import { trim } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Helper from "../../../Helper";
import Actions from "../../../redux/actions";
import { ActionConstant } from "../../../redux/actions/type";
const _ = require("lodash");
function DefinitionRenderer({
  value,
  disable,
  styleId,
  metaId,
  wordId,
  chapterMeta,
  addChapterLoadData,
  addDefinition,
  index
}) {

  const [inputValue, setValue] = useState(value);
  useEffect(() => {
    if (value != inputValue) {
      setValue(value)
    }

  }, [value])
  const handleBlur = () => {
    if (styleId ) {
      let data = {
        definition_text: trim(inputValue),
        chapter_meta_id: metaId,
        word_id: wordId,
        style_id: styleId,
      };
      if (trim(value) != trim(inputValue)) {
        addDefinition(data)
          .then((res) => {
            if (res.data.status) {
              //update Store
              let newMeta = _.map(chapterMeta, (o, k) => {
                return o.id == metaId
                  ? Helper.chapterMetaFormat(res.data.data)
                  : o;
              });

              addChapterLoadData(newMeta);
            } else {
              setValue(value);
              Helper.toastAlert("Error while saving word", "error");
            }
          })
          .catch((err) => {
            setValue(value);
            Helper.toastAlert(Helper.handleError(err), "error");
          });
      }
    } else {
      if (inputValue)
        Helper.toastAlert("Please select style first", "info");
    }
  };
  const navigateBottom = () => {
    const nextId = `hindi-word-text${index + 1}`
    document.getElementById(nextId) && document.getElementById(nextId).focus() &&
      document.getElementById(nextId).scrollIntoView({ behavior: "smooth", block: "center" });
  }
  return (
    <Input className="form-control hindi-text"
      disabled={disable}
      id={`hindi-word-text${index}`}
      onPressEnter={navigateBottom}
      onBlur={handleBlur}
      onChange={(e) => setValue(e.target.value)}
      value={inputValue} />
  )

}

const mapStateToProps = (state, ownProps) => {
  return {
    chapterMeta: state.MainReducer.chapterMeta,
  };
};
const mapDispatchToProps = {
  addDefinition: (data) => (dispatch) =>
    dispatch(Actions.chapterLoadAction.definitionSave(data)),
  addChapterLoadData: (data) => (dispatch) =>
    dispatch({ type: ActionConstant.CHAPTER.ADD_DATA, payload: data }),
};
export default connect(mapStateToProps, mapDispatchToProps)(DefinitionRenderer);
