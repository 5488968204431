import studentAction from '../../view/componant/students/student.action'
import backgroundAction from './backgroundAction'
import boardAction from './boardAction'
import chapterAction from './chapterAction'
import chapterLoadAction from './chapterLoadAction'
import classAction from './classAction'
import courseAction from './courseAction'
import languageAction from './languageAction'
import loginAction from './loginAction'
import styleAction from './styleAction'
import subjectAction from './subjectAction'
const Actions = {
  loginAction,
  chapterAction,
  classAction,
  subjectAction,
  styleAction,
  chapterLoadAction,
  backgroundAction,
  boardAction,
  courseAction,
  languageAction,
  studentAction
}
export default Actions