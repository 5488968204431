import Agent from "../../agent/Agent";
const fetch = (id) => dispatch => {
  return Agent.Background.getChapterBackground(id)
}
const add = (data) => dispatch => {
  return Agent.Background.addChapterBackground(data)
}

const backgroundAction = {
  fetch, add
}
export default backgroundAction