import { Form } from 'antd';
import React, { useCallback } from 'react';
import ColorPicker from '../../../UI/ColorPicker/ColorPicker';

export default function BackgroundStyle({ form, data }) {
  const handleColorChange = useCallback((color, name) => {
    form.setFieldValue(name, color.rgb);
  }, []);
  return (
    <div>
      <Form.Item
        wrapperCol={{ sm: { span: 2 }, md: { span: 2 } }}
        name={'chapterBackground'}
        label="Background Color"
      >
        <ColorPicker
          onChange={(color) => handleColorChange(color, 'chapterBackground')}
          color={data}
        />
      </Form.Item>
    </div>
  );
}
