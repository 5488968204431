import React from 'react'
import Agent from '../../../agent/Agent';
import { BigPlayButton, LoadingSpinner, Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { Col } from 'antd';
const { forwardRef, useRef, useState, useImperativeHandle } = React;



const RenderVideo = forwardRef(({ style, id, path, onVideoEnded, elementId, hideAllToolTip, isHideToolTip, play, tooltipStyle, hindiWord, voiceClip }, ref) => {
  let time = null
  let videoRef = useRef(null);
  const [showToolTip, setState] = useState(false)
  const [showUnderLine, setUnderLine] = useState(false)
  useImperativeHandle(ref, () => ({
    showToolTip() {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        setState(true)
        setUnderLine(true)
      } else {
        setUnderLine(true)

      }
    },
    hideToolTip() {
      setState(false)
      setUnderLine(false)
    },
    hideOnlyToolTip() {
      setState(false);
    },
    hideUnderline() {

      setUnderLine(false);

    },
    play() {

      videoRef.subscribeToStateChange((state, prevState) => {
        if (state.ended != prevState.ended) {
          videoRef && videoRef.pause();
          onVideoEnded()
        }
      })
      document.getElementById(elementId) &&
        document.getElementById(elementId).scrollIntoView({ behavior: "smooth", inline: 'center', block: 'center' });

      videoRef.play()
    }
  }));
  const toggleToolTipWord = () => {

    if (!play) {
      if (!(hindiWord == null || hindiWord.trim() == '') && !isHideToolTip) {
        hideAllToolTip(id)
        setState(!showToolTip)
      } else {
        hideAllToolTip(id)
        setUnderLine(!showUnderLine)
      }
    }
  }
  path = Agent.STORAGE_ROOT + path
  return (
    <>
      <Col id={elementId} span={16} style={{ display: "contents" }}>
        <Player ref={(player => { videoRef = player })} className={(showUnderLine ? "img-thumbnail" : "")} >
          <BigPlayButton position="center" />
          <LoadingSpinner />
          <source src={path} />
        </Player>
      </Col>
    </>


  )
})
export default RenderVideo