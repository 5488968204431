import { combineReducers } from 'redux';

import importReducer from '../../view/componant/ImportChapter/import.reducer';
import voiceReducer from '../../view/componant/VoiceImport/voice.reducer';
import addChapterReducer from '../../view/componant/chapter/Add/addChapter.reducer';
import chapterReducer from '../../view/componant/chapter/List/chapter.reducer';
import dictionaryReducer from '../../view/componant/dictionary/dictionary.reducer';
import studentReducer from '../../view/componant/students/student.reducer';
import { ActionConstant as ACTION } from '../actions/type';

/**
 * initialState of Newsletter Templates
 * @namespace initialState
 * @property {object}  error         - Error Object
 * @property {List}  templatesList   - List Of Templates
 * @property {List}  template        - Template Object
 */
const initialState = {
  user: {},
  chapterMeta: [],
};

/**
 * Reducer Function for Newsletter > Menu > Future Newsletter
 * @param {object} state State Constant of Future Newsletter with Initial State is {initialState}
 * @param {object} action Action performed in Reducer
 */
function MainReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.USER.ADD_USER_DATA:
      state = {
        ...state,
        user: action.payload,
      };
      break;
    case ACTION.CHAPTER.ADD_DATA:
      state = {
        ...state,
        chapterMeta: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}

const rootReducer = () => {
  return combineReducers({
    MainReducer,
    studentReducer,
    dictionaryReducer,
    chapterReducer,
    voiceReducer,
    addChapterReducer,
    importReducer,
  });
};

export default rootReducer;
