import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, PageHeader, Switch, Table } from 'antd';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';
import Helper from '../../../Helper';
import Agent from '../../../agent/Agent';
import BreadcrumbBar from '../../includes/BreadCrumb/BreadcrumbBar';
class StyleModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: [],
            isDataFetch: false
        }
    }
    componentDidMount() {
        this.styleFetch()
    }
    styleFetch = () => {
        this.setState({ isDataFetch: false })
        Agent.StyleRoute.getStyle().then((res) => {
            if (res.data.status) {
                // console.log(res.data.data)
                this.setState({ style: res.data.data, isDataFetch: true })
            } else {
                Helper.toastAlert("Error while fetching style", 'error')
            }
        }).catch((err) => {
            console.log(err)
            Helper.toastAlert("Error while fetching style", 'error')
        })

    }
    handleChange=(checked,id)=>{
        if(!checked){
            Helper.toastAlert("Please choose another style as default", 'error')
            return
        }
        const data={
            id,status:checked?1:0
        }
        Agent.StyleRoute.updateDefaultStyle(data).then((res) => {
            if (res.data.status) {
                this.styleFetch();
                Helper.toastAlert(res.data.msg, 'successee')
            } else {
                Helper.toastAlert("Error while updating style", 'error')
            }
        }).catch((err) => {
            console.log(err)
            Helper.handleError(err, 'error')
        })
    }
    handleEdit = (id) => {
        var id = id
        this.props.history.push('/style/edit/' + parseInt(id))
    }
    handleDelete = (id) => {

        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to delete this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        Agent.StyleRoute.deleteStyle({ 'id': id }).then((res) => {
                            this.styleFetch()
                            Helper.toastAlert(Helper.alertMsg.FLASH_MSG_REC_DELETE_1, 'success', () => {
                            });

                        }).catch((err) => {
                            Helper.toastAlert(Helper.handleError(err), 'error')
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                },
            ]
        });

    }
    renderTable = () => {
        const columns = [
            { title: 'Sr.No.', width: 100, dataIndex: '', key: 'count', render: (text, row, index) => ++index, },
            { title: 'Name', dataIndex: 'name', key: 'name', },
            { title: 'Is Default', dataIndex: 'is_default', key: 'is_default',render: (text, row, index) =><Switch onChange={(checked)=>this.handleChange(checked,row.id)} checked={row.is_default?true:false}/> },
            {
                title: 'preview', dataIndex: '', key: 'preview', render: (text, row, index) => {
                    let style = Helper.previewStyle(row.style)
                    return <span style={style}> Testing Style</span>
                }
            },

            {
                title: '', dataIndex: '', width: 100, key: 'edit', render: (text, row, index) =>
                    <NavLink className="text-info" to={"/style/edit/" + row.id} >
                        <EditOutlined />
                    </NavLink >

            },
            {
                title: '',
                dataIndex: '',
                width: 100,
                key: 'x',
                render: (text, row, index) => <a className="text-danger" onClick={() => { this.handleDelete(row.id, row.name) }}> <DeleteOutlined /></a>,
            },


        ];
        return <Table scroll={{ x: "100vh", y: "calc(100vh - 190px)" }} loading={!this.state.isDataFetch} pagination={false} columns={columns} dataSource={this.state.style} size="large" />
    }
    moveToChapterAdd = () => this.props.history.push('/style/add')
    render() {
        return (
            <>
                <BreadcrumbBar pageTitle={[{ link: "/style", name: "Styles" }]} />
                <PageHeader
                    className="site-page-header"
                    title="Styles"
                    ghost={false}
                    // onBack={() => window.history.back()}
                    // subTitle="This is a subtitle"
                    extra={[

                        <Button onClick={this.moveToChapterAdd} key="1" type="primary">
                            Create new Style
                        </Button>,
                    ]}
                />
                {this.renderTable()}

            </>
        )
    }
}
export default StyleModule;