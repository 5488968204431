import {
  LOADING_DROPDOWN_LOADING,
  LOADING_CHAPTER_LIST,
  SET_DROPDOWN_LIST,
  SUBJECT_DROPDOWN_LOADING,
  SET_CHAPTER_LIST,
  SET_FILTER_DATA,
} from './chapter.action';

const initialState = {
  chapterList: [],
  loading: false,
  dropDownLoading: false,
  dropDownData: {},
  filterData: {
    languageId: '',
    versionId: '',
    courseId: '',
    boardId: '',
    classId: '',
    subjectId: '',
  },
  subjectDropDownLoading: false,
};
function chapterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHAPTER_LIST:
      state = {
        ...state,
        studentList: action.payload,
      };
      break;
    case LOADING_CHAPTER_LIST:
      state = {
        ...state,
        loading: action.payload,
      };
      break;

    case LOADING_DROPDOWN_LOADING:
      state = {
        ...state,
        dropDownLoading: action.payload,
      };
      break;
    case SUBJECT_DROPDOWN_LOADING:
      state = {
        ...state,
        subjectDropDownLoading: action.payload,
      };
      break;
    case SET_DROPDOWN_LIST:
      state = {
        ...state,
        dropDownData: action.payload,
      };
      break;
    case SET_FILTER_DATA:
      state = {
        ...state,
        filterData: action.value,
      };
      break;

    default:
      break;
  }
  return state;
}
export default chapterReducer;
