import {
    BoldOutlined,
    ItalicOutlined,
    StrikethroughOutlined,
    UnderlineOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Select, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import ColorPicker from '../../../UI/ColorPicker/ColorPicker';

export default function TooltipStyle({ form, data }) {
  const { tooltipColor, tooltipBGcolor, font, fontSize, fontStyle } = data;
  const {
    bold = false,
    italic = false,
    underline = false,
    strikeThrough = false,
  } = fontStyle;
  let textDecoration = 'none';
  if (underline && strikeThrough) {
    textDecoration = `rgba(${tooltipColor.r}, ${tooltipColor.g}, ${tooltipColor.b}, ${tooltipColor.a}) underline line-through`;
  } else if (underline && !strikeThrough) {
    textDecoration = `rgba(${tooltipColor.r}, ${tooltipColor.g}, ${tooltipColor.b}, ${tooltipColor.a}) underline`;
  } else if (!underline && strikeThrough) {
    textDecoration = `rgba(${tooltipColor.r}, ${tooltipColor.g}, ${tooltipColor.b}, ${tooltipColor.a}) line-through`;
  }

  const previewStyle = {
    color: `rgba(${tooltipColor.r}, ${tooltipColor.g}, ${tooltipColor.b}, ${tooltipColor.a})`,
    backgroundColor: `rgba(${tooltipBGcolor.r}, ${tooltipBGcolor.g}, ${tooltipBGcolor.b}, ${tooltipBGcolor.a})`,
    borderColor: `rgba(${tooltipBGcolor.r}, ${tooltipBGcolor.g}, ${tooltipBGcolor.b}, ${tooltipBGcolor.a}) transparent transparent transparent`,
    fontSize: `${fontSize}px`,
    fontFamily: font,
    fontWeight: bold ? 'bold' : 'normal',
    fontStyle: italic ? 'italic' : 'normal',
    textDecoration: textDecoration,
  };
  const handleColorChange = useCallback((color, name) => {
    form.setFieldValue(name, color.rgb);
  }, []);
  const handleFontStyle = useCallback(
    (name, value) => {
      const { bold, italic, underline, strikeThrough } = fontStyle;
      const updatedValue = {
        bold,
        italic,
        underline,
        strikeThrough,
        [name]: value,
      };
      form.setFieldValue('fontStyle', updatedValue);
    },
    [fontStyle]
  );
  return (
    <>
      <Form.Item
        wrapperCol={{ sm: { span: 2 }, md: { span: 2 } }}
        name={'tooltipBGcolor'}
        label="Background Color"
      >
        <ColorPicker
          onChange={(color) => handleColorChange(color, 'tooltipBGcolor')}
          color={tooltipBGcolor}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{ sm: { span: 2 }, md: { span: 2 } }}
        name={'tooltipColor'}
        label="Text Color"
      >
        <ColorPicker
          onChange={(color) => handleColorChange(color, 'tooltipColor')}
          color={tooltipColor}
        />
      </Form.Item>

      <Form.Item name={'fontSize'} label="Font size">
        <InputNumber
          min={10}
          max={80}
          onChange={(value) => handleFontStyle('fontSize', value)}
        />
      </Form.Item>
      <Form.Item name={'font'} label="Font">
        <Select
          placeholder="Select font"
          onChange={(value) => handleFontStyle('font', value)}
        >
          <Select.Option value="guru">Guru</Select.Option>
          <Select.Option value="mangal">Mangal</Select.Option>
          <Select.Option value="sakal_bharti">Sakal Bharti</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={'fontStyle'} label="Font Style">
        <Button
          type={bold ? 'primary' : ''}
          onClick={() => handleFontStyle('bold', !bold)}
          icon={<BoldOutlined />}
        />
        <Button
          type={italic ? 'primary' : ''}
          onClick={() => handleFontStyle('italic', !italic)}
          icon={<ItalicOutlined />}
        />
        <Button
          type={underline ? 'primary' : ''}
          onClick={() => handleFontStyle('underline', !underline)}
          icon={<UnderlineOutlined />}
        />
        <Button
          type={strikeThrough ? 'primary' : ''}
          onClick={() => handleFontStyle('strikeThrough', !strikeThrough)}
          icon={<StrikethroughOutlined />}
        />
      </Form.Item>

      <Row justify="center">
        <Col>
          <Tooltip
            visible={true}
            overlayInnerStyle={previewStyle}
            color={previewStyle.backgroundColor}
            title="prompt text"
          >
            <Button>Test</Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}
